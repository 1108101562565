import { getActivityPrice } from './utils';

export const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_STATE':
      return {
        ...state,
        states: action.payload.states,
        cities: action.payload.cities,
      };
    case 'CHANGE_CITY':
      return { ...state, cities: action.payload };
    // case 'CHANGE_HOTEL_DAYS':
    //   return { ...state, hotel: action.payload };
    case 'CHANGE_DAYS':
      return { ...state, days: action.payload };
    case 'ADD_ACTIVITY':
      state.days[action.payload.index].push(action.payload.data);
      console.log('inserted');
      return { ...state };
    // case 'ADD_HOTEL':
    //   state.hotel[action.payload.index].push(action.payload.data);
    //   return { ...state };
    case 'REMOVE_ACTIVITY':
      state.days[action.payload.index].pop();
      return { ...state };
    // case 'REMOVE_HOTEL':
    //   state.hotels[action.payload.index].pop();
    //   return { ...state };
    case 'CHANGE_SINGLE_DAY':
      console.log(action.payload);
      const activity = action.payload.activityData?.data?.find(
        (x) => x._id.toString() === action.payload.id.toString(),
      );
      state.days[action.payload.index][action.payload.idx].activity = activity;
      return { ...state };
    // case 'CHANGE_SINGLE_HOTEL':
    //   const hotel = action.payload.hotelData?.data?.find(
    //     (x) => x._id.toString() === action.payload.id.toString(),
    //   );
    //   console.log(state);
    //   state.hotel[action.payload.index][action.payload.idx].hotel = hotel;
    //   return { ...state };

    // case 'CHANGE_SINGLE_ROOM':
    // const room = action.payload.room?.data?.find(
    //   (x) => x._id.toString() === action.payload.id.toString(),
    // );
    // state.hotel[action.payload.index][action.payload.idx].room =
    //   action.payload.room;
    // return { ...state };
    case 'CHANGE_TRANSFER':
      const { index, idx, transportType } = action.payload;
      console.log('transport calculation for', state.days[index]);
      const activityData = state.days[index][idx].activity;
      const adultCount = state.days[index][idx].numberOfAdults;
      const childCount = state.days[index][idx].numberOfChildren;
      const infantCount = state.days[index][idx].numberOfInfants;
      const totalPrice = getActivityPrice(
        activityData,
        transportType,
        adultCount,
        childCount,
      );
      state.days[index][idx].transportType = transportType;
      state.days[index][idx].TotalCost = totalPrice;
      if (transportType === 'PT') {
        const perPersonCost =
          totalPrice / (adultCount + childCount + infantCount);
        state.days[index][idx].AdultCost = getActivityPrice(
          activityData,
          transportType,
          adultCount,
          0,
        );
        // perPersonCost * adultCount;

        state.days[index][idx].ChildCost = getActivityPrice(
          activityData,
          transportType,
          0,
          childCount,
        );
        // perPersonCost * childCount;

        state.days[index][idx].InfantCost = perPersonCost * infantCount;
      } else {
        state.days[index][idx].AdultCost = getActivityPrice(
          activityData,
          transportType,
          adultCount,
          0,
        );
        state.days[index][idx].ChildCost = getActivityPrice(
          activityData,
          transportType,
          0,
          childCount,
        );
        state.days[index][idx].InfantCost = 0;
      }
      return { ...state };
    // case 'CHANGE_ROOM_QTY':
    //   const { index: roomIndex, idx: roomIdx, qty } = action.payload;
    //   const totalRoomPrice = state.hotel[roomIndex][roomIdx].room.price * qty;
    //   state.hotel[roomIndex][roomIdx].quantity = qty;
    //   console.log(state.hotel[roomIndex][roomIdx]);
    //   state.hotel[roomIndex][roomIdx].totalRoomPrice = totalRoomPrice;
    //   return { ...state };
    case 'UPDATE_PRICES':
      //acticity
      state.days.forEach((day) => {
        day.forEach((activity) => {
          const countAdult = action.payload.adult;
          const countChild = action.payload.child;
          const countInfant = action.payload.infant;
          activity.numberOfAdults = countAdult;
          activity.numberOfChildren = countChild;
          activity.numberOfInfants = countInfant;
          const modeOfTransport =
            activity.transportType || activity.activity.transportType;
          const totalPrice = getActivityPrice(
            activity.activity,
            modeOfTransport,
            countAdult,
            countChild,
          );
          const totalPeople = countAdult + countChild + countInfant;
          activity.TotalCost = totalPrice;
          if (modeOfTransport === 'PT') {
            const eachPersonCost = totalPrice / totalPeople;
            activity.AdultCost = getActivityPrice(
              activity.activity,
              modeOfTransport,
              countAdult,
              0,
            );
            // eachPersonCost * countAdult;

            activity.ChildCost = getActivityPrice(
              activity.activity,
              modeOfTransport,
              0,
              countChild,
            );
            // eachPersonCost * countChild;

            activity.InfantCost = eachPersonCost * countInfant;
          } else {
            activity.AdultCost = getActivityPrice(
              activity.activity,
              modeOfTransport,
              countAdult,
              0,
            );
            activity.ChildCost = getActivityPrice(
              activity.activity,
              modeOfTransport,
              0,
              countChild,
            );
            activity.InfantCost = 0;
          }
        });
      });
      return { ...state };
    default:
      return state;
  }
};
