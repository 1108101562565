import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function LeadGenrationHeader() {
  const navbarStyles = {
    background: '#fff',
    color: '#423f3f',
    boxShadow: 'none',
  };
  return (
    <div>
      <AppBar position="static" sx={navbarStyles}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: 'flex', 
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
                backgroundImage: "url('https://ik.imagekit.io/freelance/Skyhigh%20Tours%20&%20Travels.jpg?updatedAt=1709327685439')",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                width: { xs: '100px', md: '150px' },
                height: { xs: '60px', md: '100px' }, 
              }}
            ></Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
