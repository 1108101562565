import React, { useMemo } from 'react';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from '../optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

const VisaTable = ({ data }) => {
  const navigate = useNavigate();

  const handleRedirect = (visa) => {
    const viewUrl = `view/${visa._id}`;
    navigate(viewUrl, { state: visa });
  };
  const handleEdit = (visa) => {
    const editUrl = `edit/${visa._id}`;
    navigate(editUrl, { state: visa });
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row, index) => index + 1, {
      header: '#',
      id: 'serialNumber',
    }),
    columnHelper.accessor('country', { header: 'country' }),
    columnHelper.accessor((row) => row.price.adult_price, {
      header: 'Adult Price',
    }),
    columnHelper.accessor((row) => row.price.child_price, {
      header: 'Child Price',
    }),
    columnHelper.accessor((row) => row.price.infant_price, {
      header: 'Infant Price',
    }),
    columnHelper.accessor((row) => row, {
      header: 'Options',
      cell: (info) => {
        const optionsArr = [
          {
            name: 'view',
            onClickHandler: () => handleRedirect(info.getValue()),
          },
          {
            name: 'edit',
            onClickHandler: () => handleEdit(info.getValue()),
          },
          // {
          //   name: 'delete',
          //   onClickHandler: () => handleDelete(info.getValue().toString()),
          // },
        ];
        return (
          <OptionsDropdown actions={optionsArr}>
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];
  const COLUMNS = useMemo(() => columns, []);
  return (
    <>
      <BaseTable
        data={data.data}
        total_count={data.data.count}
        columns={COLUMNS}
      />
    </>
  );
};

export default VisaTable;
