import React from "react";
import { useParams } from "react-router-dom";
import { useGetItineraryByIdQuery } from "../../apis/itineraryApis/itineraryApi";
import { Typography } from "@mui/material";
import Itinerary from "../../components/itinerary/Itinerary";

const ItineraryPage = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useGetItineraryByIdQuery(id);
  if (isLoading || isFetching) {
    return <Typography>Loading...</Typography>;
  }
  return <>{data && <Itinerary data={data} />}</>;
};

export default ItineraryPage;
