import { apiSlice } from '../../app/api/baseApi';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //for sign in api
    login: builder.mutation({
      query: () => ({
        url: '/login',
        method: 'POST',
      }),
    }),
    //for sign up api
    register: builder.mutation({
      query: (userData) => ({
        url: '/register',
        method: 'POST',
        body: { ...userData },
      }),
    }),
    getMe: builder.query({
      query: () => {
        return {
          url: '/me',
          method: 'GET',
        };
      },
      providesTags: ['Me'],
    }),
    updateUserProfile: builder.mutation({
      query: (userData) => {
        return {
          url: '/me',
          method: 'PUT',
          body: { ...userData },
        };
      },
      invalidatesTags: ['Me'],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useGetMeQuery,
  useUpdateUserProfileMutation,
} = authApi;
