import { apiSlice } from '../../app/api/baseApi';

export const walletApi = apiSlice.injectEndpoints({
  tagTypes: ['Wallet'],
  endpoints: (builder) => ({
    creditWallet: builder.mutation({
      query: (data) => ({
        url: '/create-order-wallet',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Wallet'],
    }),
    creditWalletRecharge: builder.mutation({
      query: (data) => ({
        url: '/online-wallet-update',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Wallet'],
    }),

    updateWallet: builder.mutation({
      query: (data) => ({
        url: '/update-transaction-status-admin',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Wallet'],
    }),
    getWalletTransactionByAgentId: builder.query({
      query: () => `/wallet-transaction-by-id`,
      providesTags: ['Wallet'],
    }),

    getWalletTransactionById: builder.query({
      query: (transactionId) => `/wallet-transaction/${transactionId}`,
      providesTags: ['Wallet'],
    }),

    getAllWalletTransactions: builder.query({
      query: () => '/all-wallet-pending-request',
      providesTags: ['Wallet'],
    }),
  }),
});

export const {
  useCreditWalletMutation,
  useCreditWalletRechargeMutation,
  useUpdateWalletMutation,
  useGetWalletTransactionByAgentIdQuery,
  useGetWalletTransactionByIdQuery,
  useGetAllWalletTransactionsQuery,
} = walletApi;
