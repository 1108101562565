import React from 'react';

export default function Footer() {
  return (
    <div>
      <footer className="m-4 bg-white rounded-lg shadow dark:bg-gray-900">
        <div className="w-full max-w-screen-xl p-4 mx-auto md:py-8">
          <div className="sm:flex sm:items-center sm:justify-center">
            <ul className="flex flex-wrap items-center justify-center mb-6 text-sm font-medium text-gray-900 sm:mb-0 dark:text-gray-900">
              <li>
                <a href="/privacy-policy" className="mr-2 hover:underline me-4 md:me-6">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/termsandcondition" className="mr-2 hover:underline me-4 md:me-6">
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a href="/contactus" className="mr-2 hover:underline me-4 md:me-6">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <span className="block text-sm text-center text-gray-900 dark:text-gray-900">
            © Copyright 2024 SkyHigh Tours and Travel. All Rights
          </span>
        </div>
      </footer>
    </div>
  );
}
