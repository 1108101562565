import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useCreateLeadsMutation } from '../../apis/leadApis/LeadApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TOAST_CONFIG } from '../../pages/dashboard/itineraries/createItineraries/utils';
import 'react-phone-input-2/lib/material.css';

const LeadBookingModal = ({ open, onClose }) => {
  const [formValues, setFormValues] = useState({
    name: '',
    mobile: '',
    email: '',
    adultNo: '',
    childNo: '',
    checkInDate: '',
    nights: '',
    hotelCategory: '',
  });

  const [createLeads, { isLoading, isError, isSuccess }] =
    useCreateLeadsMutation(); // Mutation hook

  const handleInputChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (phone) => {
    setFormValues({
      ...formValues,
      mobile: phone,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const leadData = {
        name: formValues.name,
        mobile: formValues.mobile,
        email: formValues.email,
        adultNo: formValues.adultNo,
        childNo: formValues.childNo,
        checkInDate: formValues.checkInDate,
        nights: formValues.nights,
        hotelCategory: formValues.hotelCategory,
      };
      await createLeads(leadData).unwrap();
      onClose();
      toast.success('query successfully submitted!', TOAST_CONFIG);
    } catch (error) {
      console.error('Error creating query:', error);
      toast.error('Failed to submit query. Try again!', TOAST_CONFIG);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle className="flex items-center justify-between">
          <Typography variant="h6">Let's Plan A Trip</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            margin="normal"
            required
          />

          <PhoneInput
            country={'in'}
            value={formValues.mobile}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'mobile',
              required: true,
              autoFocus: false,
            }}
            containerStyle={{
              marginTop: '12px',
              marginBottom: '5px',
              width: '100%',
            }}
            inputStyle={{
              width: '100%',
              height: '56px',
            }}
          />

          <TextField
            fullWidth
            label="Email"
            type="email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="No of Adults"
            name="adultNo"
            type="number"
            value={formValues.adultNo}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="No of Children"
            name="childNo"
            type="number"
            value={formValues.childNo}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Check-in Date"
            type="date"
            name="checkInDate"
            value={formValues.checkInDate}
            onChange={handleInputChange}
            margin="normal"
            required
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="No of Nights"
            name="nights"
            type="number"
            value={formValues.nights}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Hotel Category</InputLabel>
            <Select
              name="hotelCategory"
              value={formValues.hotelCategory}
              onChange={handleInputChange}
            >
              <MenuItem value="3">3 Star</MenuItem>
              <MenuItem value="4">4 Star</MenuItem>
              <MenuItem value="5">5 Star</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="flex mx-32 mb-10">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            disabled={isLoading}
            // className="mx-10"
          >
            {isLoading ? 'Submitting...' : 'Plan My Trip'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LeadBookingModal;
