import { useParams } from 'react-router-dom';
import { useGetItineraryByIdQuery } from '../../../apis/itineraryApis/itineraryApi';
import { Typography } from '@mui/material';
import EditItinerary from './Edit';
import { useGetAllActivitiesQuery } from '../../../apis/activityApis/activityApi';
import { selectCurrentUser } from '../../../app/store/reducers/authSlice';
import { useSelector } from 'react-redux';

const EditAdmin = () => {
  const { id } = useParams();
  const currentUser = useSelector(selectCurrentUser);

  const { data, isLoading, isError, isFetching } = useGetItineraryByIdQuery(id);
  const {
    data: activityData,
    isLoading: isActivityDataLoading,
    isFetching: isActivityDataFetching,
  } = useGetAllActivitiesQuery({ keyword: '' });

  // console.log(data);
  if (
    isLoading ||
    isActivityDataLoading ||
    isFetching ||
    isActivityDataFetching
  ) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error fetching activity</Typography>;
  }
  return (
    <>
      <EditItinerary
        data={data}
        activityData={activityData}
        currentUser={currentUser}
        itineraryId={id}
      />
    </>
  );
};

export default EditAdmin;
