import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useCreateLocationMutation } from '../../../../apis/locationsApi/locationsApi';
import MenuItem from '@mui/material/MenuItem';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { uploadImages } from '../../../../helpers/imageUploadHelper';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const CreateLocations = () => {
  const formRef = React.useRef();
  const [createLocation] = useCreateLocationMutation();
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [filteredCurrencies, setFilteredCurrencies] = useState([]);

  const handleLocationImageUpload = (e, setFieldValue) => {
    const fileList = e.target.files;
    setFieldValue('images', [...fileList]);
  };

  const handleLocationChange = (selectedLocation, setFieldValue) => {
    const filtered = country.filter((c) => c.location === selectedLocation);
    setFilteredCountries(filtered);
    setFieldValue('country', '');
    setFilteredCurrencies([]);
  };

  const handleCountryChange = (selectedCountry, setFieldValue) => {
    const filteredCountry = currencyMapping[selectedCountry] || [];
    setFilteredCurrencies(filteredCountry);
    setFieldValue('currencies[0].currencyCode', '');
    setFieldValue('currencies[0].currencyName', '');
  };

  async function replaceImgWithUrls(images) {
    const locationImageUrlArr = await uploadImages(images, 'locations');
    return locationImageUrlArr;
  }

  // this location and country will comes from db sides . This code will update. now its temporary

  const locations = [
    { value: 'Dubai', label: 'Dubai' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Bali', label: 'Bali' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Baku', label: 'Baku' },
  ];

  const country = [
    {
      value: 'United Arab Emirates',
      label: 'United Arab Emirates',
      location: 'Dubai',
    },
    {
      value: 'Kingdom of Thailand',
      label: 'Kingdom of Thailand',
      location: 'Thailand',
    },
    {
      value: 'Republic of Singapore',
      label: 'Republic of Singapore',
      location: 'Singapore',
    },
    { value: 'Indonesia', label: 'Indonesia', location: 'Bali' },
    { value: 'Vietnam', label: 'Vietnam', location: 'Vietnam' },
    { value: 'Azerbaijan', label: 'Azerbaijan', location: 'Baku' },
  ];

  const currencyMapping = {
    'United Arab Emirates': [{ code: 'AED', name: 'Dirham' }],
    'Kingdom of Thailand': [{ code: 'THB', name: 'Baht' }],
    'Republic of Singapore': [{ code: 'SGD', name: 'Dollar' }],
    'Indonesia': [{ code: 'IDR', name: 'Rupiah' }],
    'Vietnam': [{ code: 'USD', name: 'United States Dollar' }],
    'Azerbaijan': [{ code: 'USD', name: 'United States Dollar' }],
  };

  const INITIAL_VALUES = {
    locationName: '',
    country: '',
    status: '',
    images: [],
    currencyCode: '',
    currencyName: '',
    exclusion: '',
    document_required: '',
    terms_n_conditions: '',
    important_note: '',
  };

  return (
    <Paper elevation={6} sx={{ padding: '1rem' }}>
      <Typography>Create Location</Typography>
      <Divider />
      <br />
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, actions) => {
          const exclusion = values.exclusion
            .trim()
            .replace(/(\r\n|\n|\r)/gm, '')
            .split(',');
          const document_required = values.document_required
            .trim()
            .replace(/(\r\n|\n|\r)/gm, '')
            .split(',');
          const terms_n_conditions = values.terms_n_conditions
            .trim()
            .replace(/(\r\n|\n|\r)/gm, '')
            .split(',');
          const important_note = values.important_note
            .trim()
            .replace(/(\r\n|\n|\r)/gm, '')
            .split(',');

          const imageUrls = await replaceImgWithUrls(values.images);

          const reqBody = {
            locationName: values.locationName,
            country: values.country,
            status: values.status,
            images: imageUrls,
            currencyCode: values.currencyCode,
            currencyName: values.currencyName,
            exclusion,
            document_required,
            terms_n_conditions,
            important_note,
          };

          createLocation(reqBody)
            .unwrap()
            .then((res) => {
              toast.success(
                `Location: ${values.locationName} Created Successfully`,
                TOAST_CONFIG,
              );
              actions.resetForm();
            })
            .catch((err) => {
              if (
                err.status === 400 &&
                err.data.message === 'Location already exists'
              ) {
                toast.error(
                  `Location: ${values.locationName} already exists`,
                  TOAST_CONFIG,
                );
              } else {
                toast.error(`Error Occurred`, TOAST_CONFIG);
              }
              console.error(err);
            });
        }}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Location Name"
                  name="locationName"
                  type="text"
                  fullWidth
                  className="mb-3"
                  onChange={(e) => {
                    setFieldValue('locationName', e.target.value);
                    handleLocationChange(e.target.value, setFieldValue);
                  }}
                  required
                >
                  {locations.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  as={TextField}
                  select
                  label="Country Name"
                  name="country"
                  type="text"
                  fullWidth
                  className="mb-3"
                  onChange={(e) => {
                    setFieldValue('country', e.target.value);
                    handleCountryChange(e.target.value, setFieldValue);
                  }}
                  required
                >
                  {filteredCountries.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  as={TextField}
                  select
                  label="Status"
                  name="status"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                >
                  <MenuItem value={0}>Active</MenuItem>
                  <MenuItem value={1}>Inactive</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  as={TextField}
                  select
                  label="Currency Code"
                  name="currencyCode"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                >
                  {filteredCurrencies.map((currency) => (
                    <MenuItem key={currency.code} value={currency.code}>
                      {currency.code}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  as={TextField}
                  select
                  label="Currency Name"
                  name="currencyName"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                >
                  {filteredCurrencies.map((currency) => (
                    <MenuItem key={currency.name} value={currency.name}>
                      {currency.name}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  as={TextField}
                  label="Exclusions (Comma Separated)"
                  name="exclusion"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                  multiline
                  rows={9}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  as={TextField}
                  label="Document Required (Comma Separated)"
                  name="document_required"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                  multiline
                  rows={9}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  as={TextField}
                  label="Terms and Conditions (Comma Separated)"
                  name="terms_n_conditions"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                  multiline
                  rows={9}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  as={TextField}
                  label="Important Notes (Comma Separated)"
                  name="important_note"
                  type="text"
                  fullWidth
                  className="mb-3"
                  required
                  multiline
                  rows={9}
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={4} mt={2}>
              <Button
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={{ marginRight: '1rem' }}
              >
                Upload Image
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleLocationImageUpload(e, setFieldValue)}
                />
              </Button>
            </Grid>
            <Grid item sm={12}>
              <Box display={'flex'} justifyContent={'space-between'}>
                {values.images.length > 0 &&
                  values.images.map((img, idx) => (
                    <Box key={idx} width={'20rem'}>
                      <img
                        src={
                          typeof img === 'string'
                            ? img
                            : URL.createObjectURL(img)
                        }
                        alt={img.name}
                        width={'100%'}
                        style={{ borderRadius: '10px' }}
                      />
                    </Box>
                  ))}
              </Box>
            </Grid>
            <Divider sx={{ marginY: '0.7rem' }} />
            <Button type="submit" variant="contained" disableElevation>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default CreateLocations;
