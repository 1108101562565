import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

export default function ContactUs() {
  return (
    <>
      <div>
        <Header />
        <div className="bg-white border border-gray-200 rounded-lg shadow contact-us dark:bg-gray-200 dark:border-gray-200">
          <h1 className="mb-4 text-3xl font-bold">Contact Us</h1>
          <p>
            your contact details and some information about your query, and
            we'll do our best to get back to you as soon as we can.
          </p>

          <h2 className="mt-6 mb-2 text-2xl font-semibold">Address</h2>
          <p>
            07, Narsinghpura Road, Mandsaur, Mandsaur,
            <br /> Madhya Pradesh, 458001
          </p>

          <h2 className="mt-6 mb-2 text-2xl font-semibold">Email</h2>
          <p>info@skyhighmds.com</p>

          <h2 className="mt-6 mb-2 text-2xl font-semibold">Phone</h2>
          <p>91 88896 19888</p>

          <h2 className="mt-6 mb-2 text-2xl font-semibold">
            Escalation Contact
          </h2>
          <p>
            For any escalation, please contact us at{' '}
            <a
              href="mailto:info@skyhighmds.com"
              className="text-blue-500 underline"
            >
              info@skyhighmds.com
            </a>
          </p>

          <h2 className="mt-6 mb-2 text-2xl font-semibold">Our Offices</h2>
          <p>
            07, Narsinghpura Road, Mandsaur, Mandsaur, Madhya Pradesh, 458001
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
