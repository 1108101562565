import React, { useMemo } from 'react';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from '../optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Rating from '@mui/material/Rating';
import { useDeleteHotelMutation } from '../../apis/hotelApis/hotelApi';
import { useNavigate } from 'react-router-dom';

const HotelTable = ({ data, pageSize, pageIndex, setPagination }) => {
  const navigate = useNavigate();

  const handleRedirect = (hotelId) => {
    const viewUrl = `view/${hotelId}`;
    navigate(viewUrl);
  };

  const [deleteHotel] = useDeleteHotelMutation();
  const handleDelete = async (Id) => {
    // console.log(Id);
    // Call the deleteHotel mutation to delete the hotel
    await deleteHotel(Id)
      .unwrap()
      .then(() => {
        console.log(`Hotel with ID ${Id} deleted successfully.`);
      })
      .catch((error) => {
        console.error(`Failed to delete hotel with ID ${Id}:`, error);
      });
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('hotelName', { header: 'Hotel Name' }),
    columnHelper.accessor('location', { header: 'Location' }),
    columnHelper.accessor('rating', {
      header: 'Rating',
      cell: (info) => {
        return (
          <>
            <Rating
              name="simple-controlled"
              value={info.getValue()}
              precision={0.5}
              readOnly
            />
          </>
        );
      },
    }),
    columnHelper.accessor('_id', {
      header: 'Options',
      cell: (info) => {
        const optionsArr = [
          {
            name: 'view',
            onClickHandler: () => handleRedirect(info.getValue().toString()),
          },
          {
            name: 'delete',
            onClickHandler: () => handleDelete(info.getValue().toString()),
          },
        ];
        return (
          <OptionsDropdown actions={optionsArr}>
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];

  const COLUMNS = useMemo(() => columns, []);
  return (
    <>
      <BaseTable
        data={data.data}
        total_count={data.count}
        columns={COLUMNS}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        noPaginate={false}
      />
    </>
  );
};

export default HotelTable;
