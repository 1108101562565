import React from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';

const WalletPaymentModal = ({ open, amount, currency, onClose, onConfirm }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minWidth: 400,
    minHeight: 200,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" gutterBottom>
          Confirm Wallet Payment
        </Typography>
        <Typography variant="body1" gutterBottom>
          Amount: <strong>{currency} {parseFloat(amount).toFixed(2)}</strong>
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default WalletPaymentModal;
