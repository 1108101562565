import { useLocation } from 'react-router-dom';
import { Paper } from '@mui/material';
import React from 'react';
const ViewVisa = () => {
  const { state } = useLocation();
  const { country, price, note, checklist } = state;
  return (
    <Paper
      className="p-4 bg-white rounded-lg 0"
      id="about"
      role="tabpanel"
      aria-labelledby="about-tab"
    >
      <h2 className="mb-3 text-3xl font-bold tracking-tight text-gray-900">
        Visa for {country}
      </h2>
      <h2 className="mb-3 text-xl font-semibold tracking-tight text-gray-500">
        Adult Price {price.adult_price}
      </h2>
      <h2 className="mb-3 text-xl font-semibold tracking-tight text-gray-500">
        Child Price {price.child_price}
      </h2>
      <h2 className="mb-3 text-xl font-semibold tracking-tight text-gray-500">
        Infant Price {price.infant_price}
      </h2>

      <h2 class="text-lg font-semibold text-gray-900 dark:text-white">
        Checklist:
      </h2>
      <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400 p-4">
        {checklist.map((list) => (
          <React.Fragment key={list}>
            {list.length > 0 && <li>{list}</li>}
          </React.Fragment>
        ))}
      </ul>
      <h2 class="text-lg font-semibold text-gray-900 dark:text-white">Note:</h2>
      <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400 p-4">
        {note.map((list) => (
          <React.Fragment key={list}>
            {list.length > 0 && <li>{list}</li>}
          </React.Fragment>
        ))}
      </ul>
    </Paper>
  );
};

export default ViewVisa;
