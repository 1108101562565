import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { useVerifyAgentMutation } from '../../apis/userApis/userApi';
import { useParams } from 'react-router-dom';
import { Box, Chip, Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';

const User = ({ data }) => {
  const { id } = useParams();
  const [verifyAgent, { loading, error }] = useVerifyAgentMutation();
  const [isVerified, setIsVerified] = useState(data.verified);

  const TOAST_CONFIG = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  };

  const userId = id.toString();
  const handleVerify = () => {
    if (!userId) return;
    if (data.isVerified) {
      alert(
        'Agent account has been verified. Confirmation email has been sent.',
      );
      return;
    }
    verifyAgent(userId)
      .then((response) => {
        console.log('Agent verified successfully');
        setIsVerified(true);
      })
      .catch((error) => {
        console.error('Error verifying agent:', error);
        TOAST_CONFIG.autoClose = 5000;
        toast.error(`Error Occured: ${error.data?.message}`, TOAST_CONFIG);
      });
  };

  return (
    <>
      <Box display={'flex'} gap={3} alignItems={'center'} marginBottom={5}>
        <Typography variant="h3" fontWeight={'bold'}>
          Agent in this applications
        </Typography>
        {isVerified ? (
          <Chip label="Verified" color="success" size="medium" />
        ) : (
          <Chip label="Not Verified" color="error" size="medium" />
        )}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="outlined"
            value={data.first_name}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="outlined"
            value={data.last_name}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="phonenumber"
            name="phonenumber"
            label="Phone No"
            fullWidth
            type="tel"
            autoComplete="family-name"
            variant="outlined"
            value={data.phonenumber}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="whatsapp_number"
            name="whatsapp_number"
            label="WhatsApp"
            fullWidth
            type="tel"
            autoComplete="family-name"
            variant="outlined"
            value={data.whatsapp_number}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
            variant="outlined"
            value={data.address}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="website"
            name="website"
            label="website"
            fullWidth
            type="tel"
            autoComplete="family-name"
            variant="outlined"
            value={data.website}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            variant="outlined"
            value={data.city}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="state"
            name="state"
            label="state"
            fullWidth
            autoComplete="shipping address-level2"
            variant="outlined"
            value={data.state}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="pan_card_no"
            name="pan_card_no"
            label="PAN"
            fullWidth
            variant="outlined"
            value={data.pan_card_no}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="gst_no"
            name="gst_no"
            label="GST No."
            fullWidth
            variant="outlined"
            value={data.gst_no}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="companyName"
            name="companyName"
            label="companyName"
            fullWidth
            variant="outlined"
            value={data.company_name}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="bussiness_type"
            name="bussiness_type"
            label="Bussiness Type"
            fullWidth
            variant="outlined"
            value={data.business_type}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      {!isVerified && (
        <Box display="flex" justifyContent="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerify}
            disabled={loading}
          >
            {loading ? 'Verifying...' : 'Verify Agent'}
          </Button>
        </Box>
      )}
      {error && <Typography color="error">{error.message}</Typography>}
    </>
  );
};

export default User;
