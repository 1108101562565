import * as React from "react";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SearchFilter({ state, setState }) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleCheckChange = (event) => {};

  return (
    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6" fontWeight={400} sx={{ m: 1 }}>
          Duration
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup sx={{ m: 2 }}>
          {state.length > 0 &&
            state.map((duration, index) => {
              let time = parseInt(Object.keys(duration)[0]);
              return <FormControlLabel control={<Checkbox />} label={`${time} Nights ${time + 1} Days`} key={index} />;
            })}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
}
