import { apiSlice } from '../../app/api/baseApi';

export const hotelApi = apiSlice.injectEndpoints({
  tagTypes: ['Hotel'],
  endpoints: (builder) => ({
    //for create hotel
    createHotel: builder.mutation({
      query: (body) => ({
        url: '/hotels',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Hotel'],
    }),
    // to get hotel by ID
    getHotelById: builder.query({
      query: (hotelId) => `/hotels/${hotelId}`,
    }),
    //to get all hotels
    getAllHotels: builder.query({
      query: (params) => {
        return {
          url: `/hotels`,
          params: { ...params },
        };
      },
      providesTags: (result, error, hotelId) => [
        {
          type: 'Hotel',
          id: `${hotelId}`,
        },
      ],
    }),
    //to get all rooms of a hotel
    getAllRoomsByHotels: builder.query({
      query: (hotelId) => `/hotelsbyrooms?hotelId=${hotelId}`,
      providesTags: ['Hotel'],
    }),
    // to delete hotel by id
    deleteHotel: builder.mutation({
      query: (hotelId) => ({
        url: `/hotels/${hotelId}`,
        method: 'DELETE',
      }),
      // You can invalidate the cache when a hotel is deleted
      invalidatesTags: ['Hotel'],
    }),
  }),
});

export const {
  useCreateHotelMutation,
  useGetHotelByIdQuery,
  useGetAllHotelsQuery,
  useGetAllRoomsByHotelsQuery,
  useDeleteHotelMutation,
} = hotelApi;
