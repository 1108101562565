import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllUsersQuery } from '../../../apis/userApis/userApi';
import { Box, Button, Divider, Typography } from '@mui/material';
import UserTable from '../../../components/userTable/UserTable';

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams(); 
  const [keyword, setKeyword] = useState(''); 
  const { data, isLoading, isFetching, refetch } = useGetAllUsersQuery({ 
    keyword: searchParams.get('keyword') || '', 
  });
  return (
    <>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
        }}
      > 
        <Box display={'flex'}> 
          <Typography variant="h5" fontWeight={700}> 
            All Users 
          </Typography> 
            <button type="submit"
              className=" p-1 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
              onClick={() => refetch()}
            >Refresh
            </button>
        </Box> 
      </Box> 
      <Divider role="presentation" sx={{ marginTop: '0.3rem' }} /> 
      {!isLoading && !isFetching && data?.data.length > 0 && ( 
        <UserTable data={data} /> 
      )} 
    </>
  );
};

export default Users;
