import React from 'react';
import { Field } from 'formik';
import { Grid, TextField, MenuItem, Typography, Checkbox } from '@mui/material';

export const FormFields = ({
  values,
  handleChange,
  setFieldValue,
  allCountries,
  allLocations,
  allVisas,
  currencies,
  dispatch,
}) => (
  <>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="Itinerary name"
        name="itineraryName"
        type="text"
        fullWidth
        className="mb-3"
        required
      />
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="Country"
        name="country"
        type="text"
        fullWidth
        select={true}
        className="mb-3"
        required
        onChange={(e) => {
          setFieldValue('country', e.target.value);
          setFieldValue('visaStatus', false);
        }}
      >
        {allCountries?.data?.map((country) => (
          <MenuItem key={country} value={country}>
            {country}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="Destination"
        name="location"
        type="text"
        fullWidth
        className="mb-3"
        select={true}
        required
      >
        {allLocations?.data?.map(
          (location) =>
            location.country === values.country && (
              <MenuItem key={location._id} value={location.locationName || ''}>
                {location.locationName}
              </MenuItem>
            ),
        )}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="No. of Adults"
        name="numberOfAdults"
        id="numberOfAdults"
        type="number"
        fullWidth
        select={true}
        defaultValue={1}
        className="mb-3"
        required
        onChange={(e) => {
          setFieldValue('numberOfAdults', e.target.value);
          dispatch({
            type: 'UPDATE_PRICES',
            payload: {
              adult: e.target.value,
              child: values.numberOfChildren,
              infant: values.numberOfInfants,
            },
          });
        }}
      >
        {Array.from({ length: 30 }, (_, index) => (
          <MenuItem key={index} value={index}>
            {index}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="No. of childs"
        name="numberOfChildren"
        type="number"
        fullWidth
        select={true}
        className="mb-3"
        required
        onChange={(e) => {
          setFieldValue('numberOfChildren', e.target.value);
          dispatch({
            type: 'UPDATE_PRICES',
            payload: {
              adult: values.numberOfAdults,
              child: e.target.value,
              infant: values.numberOfInfants,
            },
          });
        }}
      >
        {Array.from({ length: 25 }, (_, index) => (
          <MenuItem key={index} value={index}>
            {index}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="No. of infant"
        name="numberOfInfants"
        type="number"
        fullWidth
        select={true}
        className="mb-3"
        required
        onChange={(e) => {
          setFieldValue('numberOfInfants', e.target.value);
          dispatch({
            type: 'UPDATE_PRICES',
            payload: {
              adult: values.numberOfAdults,
              child: values.numberOfChildren,
              infant: e.target.value,
            },
          });
        }}
      >
        {Array.from({ length: 10 }, (_, index) => (
          <MenuItem key={index} value={index}>
            {index}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        name="tour_start_date"
        type="date"
        fullWidth
        className="mb-3"
        label="Check In"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="Duration in Nights"
        name="NoOfNights"
        type="text"
        fullWidth
        className="mb-3"
        onKeyUp={handleChange}
      />
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        disabled
        value={values.tour_end_date}
        name="tour_end_date"
        type="date"
        fullWidth
        className="mb-3"
        label="Check Out"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        name="markup_amount"
        fullWidth
        className="mb-3"
        label={`Markup (${currencies[values?.country]?.[0]?.code || ''})`}
      />
    </Grid>
    <Grid item sm={12}>
      <div className="flex items-center">
        <Typography variant="h6" fontWeight={700}>
          Opt Visa
        </Typography>
        <Checkbox
          disabled={
            !values.country ||
            !allVisas.data.find((x) => x.country === values.country)
          }
          name="visaStatus"
          onChange={(e) => setFieldValue('visaStatus', e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        {values.country &&
          allVisas.data.find((x) => x.country === values.country) && (
            <Typography variant="body1">
              Adult Price:{' '}
              {
                allVisas.data.find((x) => x.country === values.country).price
                  .adult_price
              }
              , Child Price:{' '}
              {
                allVisas.data.find((x) => x.country === values.country).price
                  .child_price
              }
              , Infant Price:{' '}
              {
                allVisas.data.find((x) => x.country === values.country).price
                  .infant_price
              }{' '}
              (Prices in -&nbsp;{currencies[values.country][0].code})
            </Typography>
          )}
      </div>
    </Grid>
  </>
);
