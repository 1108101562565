import { Box, Button, Divider, Typography } from '@mui/material';

import { useGetAllVisasQuery } from '../../../apis/locationsApi/locationsApi';

import { useLocation, useNavigate } from 'react-router-dom';
import VisaTable from '../../../components/visaTable/VisaTable';
import { useSelector } from 'react-redux';
import {
  selectCurrentUser,
  selectCurrentUserType,
} from '../../../app/store/reducers/authSlice';

const Visa = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userType } = useSelector(selectCurrentUser);

  const { data, isLoading, isFetching, error, refetch } = useGetAllVisasQuery();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'}>
          <Typography variant="h5" fontWeight={700}>
            Visa
          </Typography>
            <button type="submit"
              className=" p-1 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
              onClick={() => refetch()}
            >
              Refresh
            </button>
        </Box>
        {userType === 'Admin' && (
          <button
          className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
          onClick={() => navigate(pathname + "/create")}
          >
          Create
        </button>
        )}
      </Box>
      <Divider role="presentation" sx={{ marginTop: '0.3' }} />
      <br />

      {error && <p>Error Fetching Visa Details</p>}
      {!isLoading && !isFetching && data?.data.length > 0 && (
        <VisaTable data={data} />
      )}
    </>
  );
};

export default Visa;
