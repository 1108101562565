import React from 'react'
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

export default function PrivacyPolicy() {
    return (
        <>
        <div >
          <Header />
          <div className="bg-white border border-gray-200 rounded-lg shadow privacy-policy dark:bg-gray-200 dark:border-gray-200">
            <h1 className="mb-4 text-3xl font-bold">PRIVACY POLICY OF SKYHIGH TOURS AND TRAVELS</h1>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">1. Introduction</h2>
            <p>This privacy policy outlines how we collect, use, disclose, and protect your personal information when you use our website and services.</p>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">2. Information We Collect</h2>
            <p><strong>Personal Information:</strong> We may collect personal information, including but not limited to names, contact details, payment information, and identification documents.</p>
            <p><strong>Non-Personal Information:</strong> We may collect non-personal information, such as IP addresses, browser details, and website usage statistics.</p>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">3. How We Collect Information</h2>
            <p><strong>Directly from You:</strong> We collect information when you provide it to us during the booking process, account creation, or when contacting customer support.</p>
            <p><strong>Automatically:</strong> We may collect information automatically through cookies and similar technologies when you use our website.</p>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">4. How We Use Your Information</h2>
            <p>We use your information to:</p>
            <ul className="ml-6 list-disc">
              <li>Facilitate and process bookings and payments.</li>
              <li>Provide personalized services and recommendations.</li>
              <li>Communicate with you about your bookings and updates.</li>
              <li>Improve our website and services.</li>
            </ul>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">5. Sharing of Information</h2>
            <p>We may share your information with third parties, including:</p>
            <ul className="ml-6 list-disc">
              <li>Service providers and partners involved in delivering our services.</li>
              <li>Legal and regulatory authorities when required by law.</li>
            </ul>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">6. Your Choices</h2>
            <p>You have the right to:</p>
            <ul className="ml-6 list-disc">
              <li>Access, correct, or delete your personal information.</li>
              <li>Opt-out of marketing communications.</li>
              <li>Disable cookies through your browser settings.</li>
            </ul>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">7. Security</h2>
            <p>We implement reasonable security measures to protect your information from unauthorized access and disclosure.</p>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">8. Links to Other Websites</h2>
            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites.</p>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">9. Children's Privacy</h2>
            <p>Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children.</p>
            
            <h2 className="mt-6 mb-2 text-2xl font-semibold">10. Changes to this Privacy Policy</h2>
            <p>We may update this privacy policy from time to time. Any changes will be posted on this page with the effective date.</p>
          </div>
        </div>
        <Footer/>
        </>
      );
}
