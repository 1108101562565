import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useGetAllLocationsQuery } from "../../../apis/locationsApi/locationsApi";
import LocationTable from '../../../components/locationTable/LocationTable';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';

const Locations = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data, isLoading, isFetching, error, refetch } = useGetAllLocationsQuery({
    keyword: searchParams.get("keyword") || "",
    country: searchParams.get("country") || "",
    status: searchParams.get("status") || "",
  });

  if (error) {
    console.error("Error fetching locations:", error);
    // Handle error state here
  }

  const handleSearch = () => {
    const params = {
      keyword: keyword.toString(),
    };
    if (country) {
      params.country = country.toString();
    }
    if (status !== "") {
      params.status = status.toString();
    }
    setSearchParams(params);
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display={"flex"}>
          <Typography variant="h5" fontWeight={700}>
            Locations Table
          </Typography>
          <button
            type="submit"
            className="h-full p-1 text-sm font-medium text-white bg-blue-700 border border-blue-700  rounded-e-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            onClick={() => refetch()}
          >
            Refresh
          </button>
        </Box>
        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          onClick={() => navigate(pathname + "/create")}
        >
          Create
        </button>
      </Box>
      <Divider role="presentation" sx={{ marginTop: "0.3" }} />
      <br />
      <div className="flex mb-2">
        <input
          type="search"
          id="Search-dropdown"
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Location Name"
          defaultValue={keyword}
          onChange={handleKeywordChange}
        />
        <input
          type="text"
          placeholder="Country"
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          value={country}
          onChange={handleCountryChange}
        />
        <select
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          value={status}
          onChange={handleStatusChange}
        >
          <option value="">All Statuses</option>
          <option value="0">Active</option>
          <option value="1">Inactive</option>
        </select>
        <button
          type="submit"
          className="p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
          onClick={handleSearch}
        >
          Search
          <span className="sr-only">Search</span>
        </button>
      </div>
      {!isLoading && !isFetching && data?.data.length > 0 && (
        <LocationTable data={data} />
      )}
    </>
  );
};

export default Locations;
