import { registerSchema } from '../../app/schema/authSchema';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MenuItem, Select } from '@mui/material';
import {
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
} from 'firebase/auth';
import { auth } from '../../app/firebase/firebase.config';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  TextField,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './SignUp.css';
import { setCredentials, setToken } from '../../app/store/reducers/authSlice';
import { useRegisterMutation } from '../../apis/authApis/loginApi';
import data from '../data.json';
import { uploadImages } from '../../helpers/imageUploadHelper';
import Header from "../../components/header/Header"
import Footer from '../../components/footer/Footer';

const SignUp = () => {
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [register] = useRegisterMutation();

  const TOAST_CONFIG = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  };

  async function replaceImgWithUrls(formObj) {
    const logo = await uploadImages(formObj.company_logo, 'user');
    formObj.company_logo = logo[0];
    const pan = await uploadImages(formObj.pan_document, 'user');
    formObj.pan_document = pan[0];
    const gst = await uploadImages(formObj.gst_document, 'user');
    formObj.gst_document = gst[0];
  }

  const submitHandler = async (values, actions) => {
    // console.log(values);
    try {
      setPersistence(auth, browserSessionPersistence).then(async () => {
        const user = await createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password,
        );
        dispatch(
          setCredentials({ userId: user.user.uid, email: user.user.email }),
        );
        dispatch(
          setToken({
            accessToken: user._tokenResponse.idToken,
            refreshToken: user._tokenResponse.refreshToken,
          }),
        );

        const UserData = {
          uId: user.user.uid,
          email: values.email,
          phonenumber: values.phonenumber,
          whatsapp_number: values.whatsapp_number,
          company_name: values.company_name,
          company_logo: values.company_logo,
          business_type: values.business_type,
          country_code: values.country_code,
          state: values.state,
          city: values.city,
          first_name: values.first_name,
          last_name: values.last_name,
          address: values.address,
          website: values.website,
          pan_card_no: values.pan_card_no,
          pan_document: values.pan_document,
          gst_no: values.gst_no,
          gst_document: values.gst_document,
        };
        await replaceImgWithUrls(UserData).then(() => {
          register(UserData)
            .unwrap()
            .then(() => {
              actions.resetForm();
              toast.success(
                'This account will be verified shortly. For any questions, please contact admin',
                TOAST_CONFIG,
              );
            })
            .catch((err) => {
              console.error(err);
              TOAST_CONFIG.autoClose = 5000;
              toast.error(`Error Occured: ${err.data?.message}`, TOAST_CONFIG);
            });
        });
        // await register(UserData)
        //   .unwrap()
        //   .then(() => {
        //     actions.resetForm();
        //     toast.success(
        //       'Your account will be verified shortly. For any questions, please contact us at admin@skyhigh.com or +1234567890',
        //       TOAST_CONFIG,
        //     );
        //   })
        //   .catch((err) => {
        //     console.error(err);
        //     TOAST_CONFIG.autoClose = 5000;
        //     toast.error(`Error Occured: ${err.data?.message}`, TOAST_CONFIG);
        //   });
        // actions.resetForm();
        // toast.success("Account Created Successfully", TOAST_CONFIG);
      });
    } catch (err) {
      console.error(err);
      TOAST_CONFIG.autoClose = 5000;
      toast.error(`Error Occured: ${err.data?.message}`, TOAST_CONFIG);
    }
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    const selectedStateData = data.states.find(
      (state) => state.name == selectedState,
    );

    if (selectedStateData) {
      setCities(selectedStateData.districts.map((district) => district.name));
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: registerSchema,
    onSubmit: submitHandler,
  });

  return (
    <>
      <Header />
      <Container maxWidth={false} disableGutters>
        <ToastContainer />
        <Grid container>
          <Grid
            // md={6}
            // display="flex"
            // justifyContent="center"
            // alignItems="center"
            className="sign-up-image-container"
            item
          >
            {/* <img
            className="sign-up-img vw-100"
            src={SignUpImage}
            alt="Vector for Sing Up"
          /> */}
          </Grid>
          <Grid
          // md={6}
          // className="sign-up-form-container"
          // display="flex"
          // justifyContent="center"
          // alignItems="center"
          // height="100vh"
          // item
          >
            <Card className="">
              <CardContent>
                {/* <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  onClick={() => navigate('')}
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.1rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    backgroundImage:
                      "url('https://ik.imagekit.io/freelance/Skyhigh%20Tours%20&%20Travels.jpg?updatedAt=1709327685439')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    width: '150px',
                    height: '100px',
                  }}
                ></Typography> */}
                <Typography variant="h5" component="h1">
                  User Registration
                </Typography>
                <hr />
                <form onSubmit={handleSubmit}>
                  <Typography>
                    <TextField
                      id="company_name"
                      label="Company Name"
                      name="company_name"
                      variant="outlined"
                      className="mb-3"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      value={values.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.company_name && touched.company_name}
                      helperText={
                        errors.company_name &&
                        touched.company_name &&
                        errors.company_name
                      }
                    />

                    <TextField
                      id="business_type"
                      label="Business Type"
                      name="business_type"
                      variant="outlined"
                      className="mb-3"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      value={values.business_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.business_type && touched.business_type}
                      helperText={
                        errors.business_type &&
                        touched.business_type &&
                        errors.business_type
                      }
                      select
                    >
                      <MenuItem value="solo_proprietor">
                        Solo Proprietor
                      </MenuItem>
                      <MenuItem value="partnership">Partnership</MenuItem>
                      <MenuItem value="joint_venture">Joint Venture</MenuItem>
                      <MenuItem value="public_co_ltd">Public Co. Ltd.</MenuItem>
                      <MenuItem value="freelancer">Freelancer</MenuItem>
                    </TextField>

                    <TextField
                      id="company_logo"
                      name="company_logo"
                      variant="outlined"
                      label="Company Logo"
                      type="file"
                      className="mb-3"
                      sx={{ m: 2, width: '25ch' }}
                      required
                      value={values.company_logo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.company_logo && touched.company_logo}
                      helperText={
                        errors.company_logo &&
                        touched.company_logo &&
                        errors.company_logo
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextField
                      id="state"
                      label="State"
                      name="state"
                      variant="outlined"
                      className="mb-3"
                      sx={{ m: 2, width: '25ch' }}
                      required
                      value={values.state}
                      onChange={(e) => {
                        handleStateChange(e);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      error={errors.state && touched.state}
                      helperText={errors.state && touched.state && errors.state}
                      select
                    >
                      {data.states.map((state) => (
                        <MenuItem key={state.name} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="city"
                      label="City"
                      name="city"
                      variant="outlined"
                      className="mb-3"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.city && touched.city}
                      helperText={errors.city && touched.city && errors.city}
                      select
                    >
                      {cities.map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id="first_name"
                      label="First Name"
                      name="first_name"
                      variant="outlined"
                      required
                      className="mb-3"
                      sx={{ m: 2, width: '25ch' }}
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.first_name && touched.first_name}
                      helperText={
                        errors.first_name &&
                        touched.first_name &&
                        errors.first_name
                      }
                    />
                    <TextField
                      id="last_name"
                      label="Last Name"
                      name="last_name"
                      variant="outlined"
                      required
                      className="mb-3"
                      sx={{ m: 2, width: '25ch' }}
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.last_name && touched.last_name}
                      helperText={
                        errors.last_name &&
                        touched.last_name &&
                        errors.last_name
                      }
                    />
                    <TextField
                      id="country_code"
                      label="Country Code"
                      name="country_code"
                      required
                      variant="outlined"
                      sx={{ m: 2, width: '25ch' }}
                      className="mb-3"
                      value={values.country_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.country_code && touched.country_code}
                      helperText={
                        errors.country_code &&
                        touched.country_code &&
                        errors.country_code
                      }
                    />
                    <TextField
                      id="phonenumber"
                      label="Phone Number"
                      name="phonenumber"
                      type="tel"
                      placeholder="123-456-7890"
                      sx={{ m: 2, width: '25ch' }}
                      defaultValue={values.phonenumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.phonenumber && touched.phonenumber}
                      helperText={
                        errors.phonenumber &&
                        touched.phonenumber &&
                        errors.phonenumber
                      }
                      variant="outlined"
                      required
                      className="mb-3"
                    />
                    <TextField
                      id="whatsapp_number"
                      label="Whatsapp Number"
                      name="whatsapp_number"
                      type="tel"
                      placeholder="123-456-7890"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      defaultValue={values.whatsapp_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.whatsapp_number && touched.whatsapp_number}
                      helperText={
                        errors.whatsapp_number &&
                        touched.whatsapp_number &&
                        errors.whatsapp_number
                      }
                      variant="outlined"
                      className="mb-3"
                    />
                    <TextField
                      id="email"
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="john@email.com"
                      sx={{ m: 2, width: '25ch' }}
                      defaultValue={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      variant="outlined"
                      required
                      className="mb-3"
                    />
                    <TextField
                      id="address"
                      label="Address"
                      name="address"
                      variant="outlined"
                      sx={{ m: 2, width: '25ch' }}
                      className="mb-3"
                      required
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.address && touched.address}
                      helperText={
                        errors.address && touched.address && errors.address
                      }
                    />
                    <TextField
                      id="website"
                      label="Website"
                      name="website"
                      variant="outlined"
                      sx={{ m: 2, width: '25ch' }}
                      className="mb-3"
                      value={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.website && touched.website}
                      helperText={
                        errors.website && touched.website && errors.website
                      }
                    />
                  </Typography>
                  <Typography variant="h5" component="h1">
                    Accounts Details
                  </Typography>
                  <hr />
                  <Typography>
                    <TextField
                      id="pan_card_no"
                      label="PAN Card Number"
                      name="pan_card_no"
                      variant="outlined"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      className="mb-3"
                      value={values.pan_card_no}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.pan_card_no && touched.pan_card_no}
                      helperText={
                        errors.pan_card_no &&
                        touched.pan_card_no &&
                        errors.pan_card_no
                      }
                    />
                    <TextField
                      id="pan_document"
                      // label="PAN Card Document"
                      type="file"
                      name="pan_document"
                      label="PAN Document"
                      variant="outlined"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      className="mb-3"
                      value={values.pan_document}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.pan_document && touched.pan_document}
                      helperText={
                        errors.pan_document &&
                        touched.pan_document &&
                        errors.pan_document
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="gst_no"
                      label="GST Number"
                      name="gst_no"
                      variant="outlined"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      className="mb-3"
                      value={values.gst_no}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.gst_no && touched.gst_no}
                      helperText={
                        errors.gst_no && touched.gst_no && errors.gst_no
                      }
                    />
                    <TextField
                      id="gst_document"
                      label="GST Document"
                      type="file"
                      name="gst_document"
                      variant="outlined"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      className="mb-3"
                      value={values.gst_document}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.gst_document && touched.gst_document}
                      helperText={
                        errors.gst_document &&
                        touched.gst_document &&
                        errors.gst_document
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Typography>
                  <Typography variant="h5" component="h1">
                    Verification Details
                  </Typography>
                  <hr />
                  <Typography>
                    <TextField
                      id="email"
                      label="Enter your Email"
                      name="email"
                      type="email"
                      placeholder="john@email.com"
                      sx={{ m: 2, width: '25ch' }}
                      defaultValue={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      variant="outlined"
                      required
                      className="mb-3"
                    />
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      name="password"
                      placeholder="john123"
                      variant="outlined"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      defaultValue={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password && touched.password}
                      helperText={
                        errors.password && touched.password && errors.password
                      }
                      className="mb-3"
                    />
                    <TextField
                      id="password"
                      label="Confirm Password"
                      type="password"
                      name="password"
                      placeholder="john123"
                      variant="outlined"
                      required
                      sx={{ m: 2, width: '25ch' }}
                      defaultValue={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password && touched.password}
                      helperText={
                        errors.password && touched.password && errors.password
                      }
                      className="mb-3"
                    />
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="body1">
                      Note : Fields marked with * are mandatory.
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        className="sign-up-button"
                        disableElevation
                        sx={{ mr: 1 }}
                      >
                        Sign Up
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        className="cancel-button"
                        disableElevation
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '1.2rem',
                      }}
                      marginTop={1}
                    >
                      <small>
                        Aleady have an account?{' '}
                        <Link
                          to="/signin"
                          className="fw-bold text-decoration-none"
                        >
                          Sign In
                        </Link>
                      </small>
                    </Typography>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer/>
    </>
  );
};

export default SignUp;
