import React, { useMemo } from 'react';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from '../optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';
import { useDeleteItineraryMutation } from '../../apis/itineraryApis/itineraryApi';
import { selectCurrentUser } from '../../app/store/reducers/authSlice';
import { useSelector } from 'react-redux';

const ItinearyTable = ({ data, pageSize, pageIndex, setPagination }) => {
  const { userType } = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  const handleRedirect = (itineraryId) => {
    const viewUrl = `../itineraries/view/${itineraryId}`;
    navigate(viewUrl);
  };
  const handleEdit = (itineraryId) => {
    const viewUrl = `edit/${itineraryId}`;
    navigate(viewUrl);
  };

  const [deleteItineary] = useDeleteItineraryMutation();
  const handleDelete = async (Id) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this itineary? Its is hampered your booking also.',
    );
    if (confirmDelete) {
      await deleteItineary(Id)
        .unwrap()
        .then(() => {
          console.log(`Itinerary with ID ${Id} delete successfully.`);
        })
        .catch((error) => {
          console.log(`Failed to delete Itinerary with ID ${Id}:`, error);
        });
    }
  };

  const formDuration = (duration) => {
    const days = parseInt(duration);
    const nights = days - 1;
    return `${days} Days ${nights} Nights`;
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row, index) => index + 1 + pageIndex * pageSize, {
      header: '#',
      id: 'serialNumber',
    }),
    columnHelper.accessor((row) => `${row._id}`, { header: 'Itineary ID' }),
    columnHelper.accessor('itineraryName', { header: 'Itinerary Name' }),
    columnHelper.accessor('created_by', { header: 'Created By' }),
    // columnHelper.accessor("description", { header: "Itinerary Description" }),
    columnHelper.accessor('duration', {
      header: 'Itinerary Duration',
      cell: (info) => formDuration(info.getValue()),
    }),
    columnHelper.accessor('location', { header: 'location' }),
    columnHelper.accessor('country', { header: 'Country' }),
    columnHelper.accessor('createdAt', { header: 'Created Date' }),

    columnHelper.accessor('_id', {
      header: 'Options',
      cell: (info) => {
        const optionsArr = [
          {
            name: 'view',
            onClickHandler: () => handleRedirect(info.getValue().toString()),
          },
          {
            name: 'edit',
            onClickHandler: () => handleEdit(info.getValue().toString()),
          },
          {
            name: 'delete',
            onClickHandler: () => handleDelete(info.getValue().toString()),
          },
        ];

        return (
          <OptionsDropdown actions={optionsArr}>
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];

  const formateDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  const formatedData = data.data.map((item) => ({
    ...item,
    createdAt: formateDate(item.createdAt),
  }));

  const COLUMNS = useMemo(() => columns, []);

  return (
    <>
      <BaseTable
        data={formatedData}
        total_count={data.count}
        columns={COLUMNS}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        noPaginate={false}
      />
    </>
  );
};

export default ItinearyTable;
