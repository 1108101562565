// EditActivity.js
import * as React from 'react';
import { useState } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useUpdateActivityMutation,
  useGetActivityByIdQuery,
} from '../../../../apis/activityApis/activityApi';
import { Divider } from '@mui/material';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

export default function EditActivity() {
  const { id } = useParams();

  // Fetch activity data by its ID
  const { data: activity, isLoading, isError } = useGetActivityByIdQuery(id);

  const [updateActivity, { isLoading: isUpdating }] =
    useUpdateActivityMutation();

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error fetching activity</Typography>;
  }

  return (
    <React.Fragment>
      <Paper elevation={3}>
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 5 }}>
            Activity Details
          </Typography>
          <Formik
            initialValues={{
              activityType: activity?.type || '',
              activityName: activity?.activityName || '',
              description: activity?.description || '',
              tourInclusions: activity?.tourInclusions || '',
              WhattoExpect: activity?.WhattoExpect || '',
              location: activity?.location || '',
              country: activity?.country || '',
              duration: activity?.duration || '',
              price: activity?.price || {
                ST: { AdultCost: 0, ChildCost: 0, InfantCost: 0 },
                WOT: { AdultCost: 0, ChildCost: 0, InfantCost: 0 },
                PT: [],
              },
            }}
            onSubmit={(values, { setSubmitting }) => {
              const updatedActivity = {
                type: values.activityType,
                activityName: values.activityName,
                description: values.description,
                tourInclusions: values.tourInclusions,
                WhattoExpect: values.WhattoExpect,
                location: values.location,
                country: values.country,
                duration: values.duration,
                price: values.price,
              };
              updateActivity({ activityId: id, body: updatedActivity })
                .then(() => {
                  setSubmitting(false);
                  toast.success('Activity updated Successfully', TOAST_CONFIG);
                })
                .catch((error) => {
                  console.error('Error Updating Activity:', error);
                  toast.error('Failed to update activity', TOAST_CONFIG);
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Activity Type
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      id="activityType"
                      name="activityType"
                      label="Activity Type"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Activity Name
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      id="activityName"
                      name="activityName"
                      label="Activity Name"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Description
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      id="description"
                      name="description"
                      label="Description"
                      multiline
                      fullWidth
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Tour Inclusions
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      id="tourInclusions"
                      name="tourInclusions"
                      label="TourInclusions"
                      multiline
                      fullWidth
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      What to Expect
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      id="WhattoExpect"
                      name="WhattoExpect"
                      label="WhattoExpect"
                      multiline
                      fullWidth
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Location
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      id="location"
                      name="location"
                      label="Location"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Country
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      id="country"
                      name="country"
                      label="Country"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Duration
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      id="duration"
                      name="duration"
                      label="Duration"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginY={1}
                    >
                      <Typography variant="h5" fontWeight={'bold'}>
                        {values.activityType === 'Fun' && <> Without </>}{' '}
                        Transfer Prices
                      </Typography>
                    </Box>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Price (WOT - Adult)
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="price.WOT.AdultCost"
                      name="price.WOT.AdultCost"
                      label="WOT - Adult Cost"
                      fullWidth
                      size="small"
                      value={values.price.WOT.AdultCost}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Price (WOT - Child)
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="price.WOT.ChildCost"
                      name="price.WOT.ChildCost"
                      label="WOT - Child Cost"
                      fullWidth
                      size="small"
                      value={values.price.WOT.ChildCost}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginY={1}
                    >
                      <Typography variant="h5" fontWeight={'bold'}>
                        {values.activityType === 'Fun' && <> Shared </>}{' '}
                        Transfer Prices
                      </Typography>
                    </Box>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Price (ST - Adult)
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="price.ST.AdultCost"
                      name="price.ST.AdultCost"
                      label="ST - Adult Cost"
                      fullWidth
                      size="small"
                      value={values.price.ST.AdultCost}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Price (ST - Child)
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="price.ST.ChildCost"
                      name="price.ST.ChildCost"
                      label="ST - Child Cost"
                      fullWidth
                      size="small"
                      value={values.price.ST.ChildCost}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginY={1}
                    >
                      <Typography variant="h5" fontWeight={'bold'}>
                        Private Transfer Prices
                      </Typography>
                    </Box>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name="price.PT"
                      render={(arrayHelpers) => (
                        <>
                          <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            marginY={1}
                          >
                            <Typography variant="h5" fontWeight={'bold'}>
                              Private Transfer
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                arrayHelpers.push({
                                  minPassengers: 0,
                                  maxPassengers: 1,
                                  price: 0,
                                })
                              }
                            >
                              Add Range
                            </Button>
                          </Box>
                          <Divider />
                          {values.price.PT.map((pt, index) => (
                            <Grid container gap={3} key={index} marginY={2}>
                              <Grid item xs={12} sm={3}>
                                <TextField
                                  id={`price.PT[${index}].minPassengers`}
                                  name={`price.PT[${index}].minPassengers`}
                                  label="Min Passengers"
                                  fullWidth
                                  size="small"
                                  value={values.price.PT[index].minPassengers}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <TextField
                                  id={`price.PT[${index}].maxPassengers`}
                                  name={`price.PT[${index}].maxPassengers`}
                                  label="Max Passengers"
                                  fullWidth
                                  size="small"
                                  value={values.price.PT[index].maxPassengers}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <TextField
                                  id={`price.PT[${index}].price`}
                                  name={`price.PT[${index}].price`}
                                  label="Price"
                                  fullWidth
                                  size="small"
                                  value={values.price.PT[index].price}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                -
                              </Button>
                            </Grid>
                          ))}
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Button
                      type="submit"
                      variant="contained"
                      disableElevation
                      disabled={isSubmitting || isUpdating}
                    >
                      {isUpdating ? 'Updating...' : 'Update'}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
