import { RouterProvider } from "react-router-dom";
import router from "./router";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return <RouterProvider router={router} />;
}

export default App;
