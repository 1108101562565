import * as React from 'react';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useUpdateLocationMutation,
  useGetLocationByIdQuery,
} from '../../../../apis/locationsApi/locationsApi';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

export default function EditLocation() {
  const { id } = useParams();
  // Fetch location data by its ID
  const { data: location, isLoading, isError } = useGetLocationByIdQuery(id);

  const [updateLocation, { isLoading: isUpdating }] =
    useUpdateLocationMutation();

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error fetching activity</Typography>;
  }

  return (
    <React.Fragment>
      <Paper elevation={3} sx={{ marginRight: '15%', marginLeft: '15%' }}>
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 5 }}>
            Location Details
          </Typography>
          <Formik
            initialValues={{
              locationName: location?.locationName || '',
              country: location?.country || '',
              currencyCode: location?.currencyCode || '',
              currencyName: location?.currencyName || '',
              exclusion: location?.exclusion.join('\n') || '',
              document_required: location?.document_required.join('\n') || '',
              terms_n_conditions: location?.terms_n_conditions.join('\n') || '',
              important_note: location?.important_note.join('\n') || '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              const updatedLocation = {
                locationName: values.locationName,
                country: values.country,
                currencyCode: values.currencyCode,
                currencyName: values.currencyName,
                exclusion: values.exclusion
                  .trim()
                  .replace(/(\r\n|\n|\r)/gm, '')
                  .split(','),
                document_required: values.document_required
                  .trim()
                  .replace(/(\r\n|\n|\r)/gm, '')
                  .split(','),
                terms_n_conditions: values.terms_n_conditions
                  .trim()
                  .replace(/(\r\n|\n|\r)/gm, '')
                  .split(','),
                important_note: values.important_note
                  .trim()
                  .replace(/(\r\n|\n|\r)/gm, '')
                  .split(','),
              };
              updateLocation({ locationId: id, body: updatedLocation })
                .then(() => {
                  setSubmitting(false);
                  toast.success('Location updated successfully', TOAST_CONFIG);
                })
                .catch((error) => {
                  console.error('Error updating location:', error);
                  toast.error('Failed to update location', TOAST_CONFIG);
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Location Name
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      required
                      id="locationName"
                      name="locationName"
                      label="Location Name"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Country
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      required
                      id="country"
                      name="country"
                      label="Country"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Currency Code
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      required
                      id="currencyCode"
                      name="currencyCode"
                      label="Currency Code"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Currency Name
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      required
                      id="currencyName"
                      name="currencyName"
                      label="Currency Name"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Exclusion
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      id="exclusion"
                      name="exclusion"
                      label="Exclusion"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Document Required
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      id="document_required"
                      name="document_required"
                      label="Document Required"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Terms and Conditions
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      id="terms_n_conditions"
                      name="terms_n_conditions"
                      label="Terms and Conditions"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InputLabel
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 700,
                      }}
                    >
                      Important Notes
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <Field
                      as={TextField}
                      id="important_note"
                      name="important_note"
                      label="Important Notes"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} />
                  <Grid item xs={12} sm={5}>
                    <Button
                      type="submit"
                      variant="contained"
                      disableElevation
                      disabled={isSubmitting || isUpdating}
                    >
                      {isUpdating ? 'Updating...' : 'Update'}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
