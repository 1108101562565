import React from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetLocationByIdQuery } from '../../../../apis/locationsApi/locationsApi';
import Location from '../../../../components/location/Location';

const ViewLocation = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetLocationByIdQuery(id);
  // console.log(data);
  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }
  if (isError) {
    return <Typography>Error fetching location</Typography>;
  }
  return <>{data && <Location data={data || []} />}</>;
};

export default ViewLocation;
