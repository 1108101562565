import { apiSlice } from '../../app/api/baseApi';

export const itineraryApi = apiSlice.injectEndpoints({
  tagTypes: ['Itinerary'],
  endpoints: (builder) => ({
    //for create Itinerary
    createItinerary: builder.mutation({
      query: (body) => ({
        url: '/itinerarybook',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Itinerary'],
    }),
    createAdminItinerary: builder.mutation({
      query: (body) => ({
        url: '/itinerary-admin',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Itinerary'],
    }),
    editAdminItinerary: builder.mutation({
      query: ({ id, body }) => ({
        url: `/itinerary-admin/${id}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: ['Itinerary'],
    }),
    //to edit Itinerary
    editItinerary: builder.mutation({
      query: ({ id, body }) => ({
        url: `/itinerarybook/${id}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: ['Itinerary'],
    }),
    //to get Itinerary by ID
    getItineraryById: builder.query({
      query: (itineraryId) => `/itinerary/${itineraryId}`,
      providesTags: (result, error, itineraryId) => [
        {
          type: 'Itinerary',
          id: `${itineraryId}`,
        },
      ],
    }),
    //to get all Itineraries
    getAllItineraries: builder.query({
      query: (params) => {
        return {
          url: `/itinerary`,
          params: { ...params },
        };
      },
      providesTags: ['Itinerary'],
    }),
    // get all admin created itineray
    getAllAdminItineraries: builder.query({
      query: (params) => {
        return {
          url: `/all-itinerary-admin`,
          params: { ...params },
        };
      },
      providesTags: ['Itinerary'],
    }),
    // to delete itinerary by id
    deleteItinerary: builder.mutation({
      query: (activityId) => ({
        url: `/itinerary/${activityId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Itinerary'],
    }),
  }),
});

export const {
  useCreateItineraryMutation,
  useCreateAdminItineraryMutation,
  useEditAdminItineraryMutation,
  useEditItineraryMutation,
  useGetItineraryByIdQuery,
  useGetAllItinerariesQuery,
  useGetAllAdminItinerariesQuery,
  useDeleteItineraryMutation,
} = itineraryApi;
