import React from 'react';
import { Box, Paper, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LeadBookingModal from "./LeadBookingModal";

export default function Navbar() {
  const [openModal, setOpenModal] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    name: '',
    mobile: '',
    email: '',
    noOfAdults: '',
    noOfChildren: '',
    noOfInfants: '',
    checkInDate: '',
    noOfNights: '',
    checkOutDate: '',
    hotelCategory: '',
  });

  const navigate = useNavigate();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formValues);
    handleCloseModal();
  };

  return (
    <div>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(https://ik.imagekit.io/freelance/dubai.jpg?updatedAt=1725910887856)`,
          borderRadius: '1px',
          height: '28rem',
        }}
      >
        <img
          style={{ display: 'none' }}
          src="https://ik.imagekit.io/freelance/dubai.jpg?updatedAt=1725910887856"
          alt="header"
          height="28rem"
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
            borderRadius: '12px',
            height: '28rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography component="h1" variant="h3" color="inherit" gutterBottom>
            {'Plan Your Custom Vacation'}
          </Typography>
          <Button variant="contained" disableElevation onClick={handleOpenModal}>
            Plan My Trip
          </Button>
        </Box>
      </Paper>

      <LeadBookingModal
        open={openModal}
        onClose={handleCloseModal}
        formValues={formValues}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}
