import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

export default function Header() {
  return (
    <div>
      <header className="bg-white">
        <nav
          className="flex items-center justify-between p-6 mx-auto max-w-7xl lg:px-8"
          aria-label="Global"
        >
          <div className="flex items-center lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5 flex items-center">
              <img
                className="w-auto h-8 mr-2"
                src="https://ik.imagekit.io/freelance/Skyhigh%20Tours.png?updatedAt=1721248097662"
                width= "90px"
                alt="Company Logo"
              />
            </a>
          </div>
          <div className="flex space-x-4 lg:hidden">
            <a href="tel:+918889619888" className="flex items-center">
              <PhoneIcon />
              <span className="mr-2">+91 88896 19888</span>
            </a>
            <a href="mailto:info@skyhighmds.com" className="flex items-center">
              <MailIcon />
              <span className="ml-2">info@skyhighmds.com</span>
            </a>
          </div>
        </nav>
      </header>
    </div>
  );
}
