import React, { useReducer } from 'react';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { Formik, FieldArray, Form } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../app/store/reducers/authSlice';
import { useGetAllActivitiesQuery } from '../../../../apis/activityApis/activityApi';
import { useCreateItineraryMutation } from '../../../../apis/itineraryApis/itineraryApi';
import {
  useGetAllCountriesQuery,
  useGetAllLocationsQuery,
  useGetAllVisasQuery,
} from '../../../../apis/locationsApi/locationsApi';
import { getAllCurrencies } from '../../../../app/store/reducers/currencySlice';
import {
  useLazyGetSingleBookingQuery,
  useSendBookingMutation,
} from '../../../../apis/bookingApis/bookingApi';
import { reducer } from './reducer';
import { TOAST_CONFIG, replaceImgWithUrls, addDaysToDate } from './utils';
import { FormFields } from './FormFields';
import { ActivityItem } from './ActivityItem';
import _ from 'lodash';
import { useGetAllHotelsQuery } from '../../../../apis/hotelApis/hotelApi';
import { HotelItem } from './HotelItem';
import { useNavigate } from 'react-router-dom';

const CreateItineraries = () => {
  const formRef = React.useRef();
  const currencies = useSelector(getAllCurrencies);
  const { data: allCountries } = useGetAllCountriesQuery();
  const { data: allLocations } = useGetAllLocationsQuery({ keyword: '' });
  const { data: allVisas } = useGetAllVisasQuery();
  const { data: activityData } = useGetAllActivitiesQuery({ keyword: '' });
  // const { data: hotelData } = useGetAllHotelsQuery({ keyword: '' });
  const currentUser = useSelector(selectCurrentUser);
  const [createItinerary] = useCreateItineraryMutation();
  const [sendBooking] = useSendBookingMutation();
  const [trigger] = useLazyGetSingleBookingQuery();
  const navigate = useNavigate();

  const handleDurationChange = (e) => {
    if (
      formRef.current?.values.tour_start_date &&
      formRef.current?.values.NoOfNights &&
      e.target.value > 0
    ) {
      const days = parseInt(formRef.current?.values.NoOfNights) + 1;
      const existingDays = state.days.length;
      if (days > existingDays) {
        const extraDays = days - existingDays;
        const result = Array.from({ length: extraDays }, (_, i) => [
          {
            activityId: ``,
            activity: {},
            numberOfAdults: formRef.current?.values?.numberOfAdults,
            numberOfChildren: formRef.current?.values?.numberOfChildren,
            numberOfInfants: formRef.current?.values?.numberOfInfants,
          },
        ]);
        // const hotelsArr = Array.from({ length: extraDays }, (_, i) => [
        //   {
        //     id: ``,
        //     hotel: {},
        //     room: {},
        //     quantity: 0,
        //     numberOfAdults: formRef.current?.values?.numberOfAdults,
        //     numberOfChildren: formRef.current?.values?.numberOfChildren,
        //     numberOfInfants: formRef.current?.values?.numberOfInfants,
        //   },
        // ]);
        const finalArr = [...state.days, ...result];
        // const finalHotelsArr = [...state.hotel, ...hotelsArr];
        const endDate = addDaysToDate(
          formRef.current?.values.tour_start_date,
          days - 1,
        );
        formRef.current?.setFieldValue('tour_end_date', endDate);
        // formRef.current?.setFieldValue('hotels', finalHotelsArr);
        formRef.current?.setFieldValue('days', finalArr);
        // dispatch({ type: 'CHANGE_HOTEL_DAYS', payload: finalHotelsArr });
        dispatch({ type: 'CHANGE_DAYS', payload: finalArr });
      } else {
        const extraDays = existingDays - days;
        const finalArr = state.days.slice(extraDays);
        // const finalHotelsArr = state.hotel.slice(extraDays);
        const endDate = addDaysToDate(
          formRef.current?.values.tour_start_date,
          days - 1,
        );
        formRef.current?.setFieldValue('tour_end_date', endDate);
        formRef.current?.setFieldValue('days', finalArr);
        // dispatch({ type: 'CHANGE_HOTEL_DAYS', payload: finalHotelsArr });
        dispatch({ type: 'CHANGE_DAYS', payload: finalArr });
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    states: [],
    cities: [],
    hotel: [],
    days: [],
  });

  const INITIAL_VALUES = {
    itineraryName: '',
    description: '',
    location: '',
    country: '',
    NoOfNights: '',
    numberOfAdults: 1,
    numberOfChildren: 0,
    numberOfInfants: 0,
    tour_start_date: '',
    tour_end_date: '',
    visaStatus: false,
    images: [],
    rating: '',
    markup_amount: 0.0,
    // hotels: [{ id: '', start: '', end: '', rooms: [''] }],
    days: [],
  };

  return (
    <Paper elevation={6} sx={{ padding: '1rem' }}>
      <Typography variant="h4" fontWeight={700}>
        Create Itinerary
      </Typography>
      <Divider />
      <br />
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, actions) => {
          const reqBody = _.cloneDeep(values);
          let total_amount = 0.0;
          let total_adult_amount = 0.0;
          let total_child_amount = 0.0;
          let total_infant_amount = 0.0;
          reqBody.days.forEach((day, index) => {
            return day.forEach((d, idx) => {
              d.activityId = state.days[index][idx].activity._id;
              d.numberOfAdults = state.days[index][idx].numberOfAdults;
              d.numberOfChildren = state.days[index][idx].numberOfChildren;
              d.numberOfInfants = state.days[index][idx].numberOfInfants;
              total_amount =
                parseFloat(state.days[index][idx].TotalCost) +
                parseFloat(total_amount);
              total_adult_amount =
                parseFloat(state.days[index][idx].AdultCost) +
                parseFloat(total_adult_amount);
              total_child_amount =
                parseFloat(state.days[index][idx].ChildCost) +
                parseFloat(total_child_amount);
              total_infant_amount =
                parseFloat(state.days[index][idx].InfantCost) +
                parseFloat(total_infant_amount);
              d.totalCost = parseFloat(
                state.days[index][idx].TotalCost || 0.0,
              ).toFixed(2);
              d.AdultCost = parseFloat(
                state.days[index][idx].AdultCost,
              ).toFixed(2);
              d.ChildCost = parseFloat(
                state.days[index][idx].ChildCost,
              ).toFixed(2);
              d.InfantCost = parseFloat(
                state.days[index][idx].InfantCost,
              ).toFixed(2);
              d.activity = {};
            });
          });
          // state.hotel.forEach((hotel) => {
          //   hotel.forEach((h) => {
          //     total_amount = total_amount + h.totalRoomPrice;
          //     total_adult_amount = total_adult_amount + h.totalRoomPrice;
          //   });
          // });
          reqBody.total_amount =
            parseFloat(total_amount) +
            parseFloat(reqBody.markup_amount);
          reqBody.total_adult_amount = parseFloat(
            total_adult_amount || 0.0,
          ).toFixed(2);
          reqBody.total_child_amount = parseFloat(
            total_child_amount || 0.0,
          ).toFixed(2);
          reqBody.total_infant_amount = parseFloat(
            total_infant_amount || 0.0,
          ).toFixed(2);
          if (values.visaStatus === true)
            reqBody.locationVisaPrice = allVisas.data.find(
              (x) => x.country === values.country,
            ).price;
          await createItinerary(reqBody)
            .unwrap()
            .then(async (res) => {
              toast.success(
                `Itinerary: ${values.itineraryName} Created Successfully`,
                TOAST_CONFIG,
              );
              await trigger(res.data._id)
                .unwrap()
                .then(async (bookingRes) => {
                  const location = allLocations.data.find(
                    (x) => x.locationName === values.location,
                  );
                  await sendBooking({
                    pdfContent: {
                      ...res.data,
                      itineraryInfo: bookingRes,
                      currency: currencies[values.country][0].code,
                      document_required: location.document_required,
                      important_note: location.important_note,
                      terms_n_conditions: location.terms_n_conditions,
                      exclusion: location.exclusion,
                      ...(values.visaStatus === true && {
                        visa: allVisas.data.find(
                          (x) => x.country === values.country,
                        ),
                      }),
                    },
                    Id: currentUser.userId,
                  })
                    .unwrap()
                    .then((res) => {
                      toast.success(`Sent Mail Successfully`, TOAST_CONFIG);
                      actions.setSubmitting(false);
                      actions.resetForm();
                      navigate('/dashboard/itineraries');
                    })
                    .catch((err) => {
                      toast.error(`Error Occured`, TOAST_CONFIG);
                      actions.setSubmitting(false);
                      console.error(err);
                    });
                });
            })
            .catch((err) => {
              toast.error(`Error Occured`, TOAST_CONFIG);
              actions.setSubmitting(false);
              console.error(err);
            });
        }}
        innerRef={formRef}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <FormFields
                values={values}
                handleChange={handleDurationChange}
                setFieldValue={setFieldValue}
                allCountries={allCountries}
                allLocations={allLocations}
                allVisas={allVisas}
                currencies={currencies}
                dispatch={dispatch}
              />
            </Grid>
            {/* <Divider sx={{ marginY: '1rem' }} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <p className="text-2xl font-semibold">Hotels</p>
              </Grid>
              <FieldArray name="hotels">
                {({ push }) => (
                  <>
                    {values.days.map((_, index) => (
                      <Grid item key={index} sm={12}>
                        <FieldArray name={`hotels.${index}`}>
                          {({ push: pushActivity, pop: popActivity }) => (
                            <Box
                              sx={{
                                background: '#EBE4D1',
                                padding: '1rem',
                                borderRadius: '12px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  paddingBottom: '0.5rem',
                                }}
                              >
                                <Typography variant="h6" fontWeight={700}>
                                  Day {index + 1}:{' '}
                                  {addDaysToDate(values.tour_start_date, index)}
                                </Typography>
                                <Box sx={{ display: 'flex' }}>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    sx={{ float: 'right' }}
                                    onClick={() => {
                                      const data = {
                                        id: ``,
                                        hotel: {},
                                        room: {},
                                        numberOfAdults:
                                          formRef.current?.values
                                            ?.numberOfAdults,
                                        numberOfChildren:
                                          formRef.current?.values
                                            ?.numberOfChildren,
                                        numberOfInfants:
                                          formRef.current?.values
                                            ?.numberOfInfants,
                                        TotalCost: 0.0,
                                        quantity: 0,
                                      };
                                      dispatch({
                                        type: 'ADD_HOTEL',
                                        payload: { index, data },
                                      });
                                      pushActivity(data);
                                    }}
                                  >
                                    + Hotels
                                  </Button>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    sx={{ float: 'right' }}
                                    color="error"
                                    onClick={() => {
                                      if (state.days[index].length > 1) {
                                        dispatch({
                                          type: 'REMOVE_HOTEL',
                                          payload: { index },
                                        });
                                        popActivity();
                                      }
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </Box>
                              {values.days[index].map((_, idx) => (
                                <HotelItem
                                  key={idx}
                                  index={index}
                                  idx={idx}
                                  values={values}
                                  hotelData={hotelData}
                                  dispatch={dispatch}
                                  state={state}
                                  currencies={currencies}
                                />
                              ))}
                            </Box>
                          )}
                        </FieldArray>
                      </Grid>
                    ))}
                  </>
                )}
              </FieldArray>
            </Grid> */}
            <Divider sx={{ marginY: '0.7rem' }} />
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <p className="text-2xl font-semibold">Activities</p>
              </Grid>
              <FieldArray name="days">
                {({ push }) => (
                  <>
                    {values.days.map((_, index) => (
                      <Grid item key={index} sm={12}>
                        <FieldArray name={`days.${index}`}>
                          {({ push: pushActivity, pop: popActivity }) => (
                            <Box
                              sx={{
                                background: '#EBE4D1',
                                padding: '0.5rem',
                                borderRadius: '12px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  // paddingBottom: '0.5rem',
                                }}
                              >
                                {/* <Typography variant="h6" fontWeight={700}>
                                  Day {index + 1}:{' '}
                                  {addDaysToDate(values.tour_start_date, index)}
                                </Typography> */}
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    sx={{ float: 'right', fontSize: '12' }}
                                    onClick={() => {
                                      const data = {
                                        activityId: ``,
                                        activity: {},
                                        numberOfAdults:
                                          formRef.current?.values
                                            ?.numberOfAdults,
                                        numberOfChildren:
                                          formRef.current?.values
                                            ?.numberOfChildren,
                                        numberOfInfants:
                                          formRef.current?.values
                                            ?.numberOfInfants,
                                        TotalCost: 0.0,
                                      };
                                      dispatch({
                                        type: 'ADD_ACTIVITY',
                                        payload: { index, data },
                                      });
                                      pushActivity(data);
                                    }}
                                  >
                                    + Activity
                                  </Button>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    sx={{ float: 'right' }}
                                    color="error"
                                    onClick={() => {
                                      if (state.days[index].length > 1) {
                                        dispatch({
                                          type: 'REMOVE_ACTIVITY',
                                          payload: { index },
                                        });
                                        popActivity();
                                      }
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </Box>
                              {values.days[index].map((_, idx) => (
                                <ActivityItem
                                  key={idx}
                                  index={index}
                                  idx={idx}
                                  values={values}
                                  activityData={activityData}
                                  dispatch={dispatch}
                                  state={state}
                                  currencies={currencies}
                                />
                              ))}
                            </Box>
                          )}
                        </FieldArray>
                      </Grid>
                    ))}
                  </>
                )}
              </FieldArray>
            </Grid>
            <Divider sx={{ marginY: '0.7rem' }} />
            <Button
              type="submit"
              variant="contained"
              disableElevation
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default CreateItineraries;
