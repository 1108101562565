import React, { useMemo } from 'react';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from '../optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useDeleteUserMutation } from '../../apis/userApis/userApi';

const UserTable = ({ data }) => {
  const navigate = useNavigate();

  const handleRedirect = (userId) => {
    const viewUrl = `view/${userId}`;
    navigate(viewUrl);
  };

  const [deletedUser] = useDeleteUserMutation();
  const handleDelete = async (Id) => {
    await deletedUser(Id)
      .unwrap()
      .then(() => {
        console.log(`User With Id ${Id} deleted successfully`);
      })
      .catch((error) => {
        console.log(`Failed to deleted user`, error);
      });
  };

  const columnHelper = createColumnHelper();
  const columns = [
    // columnHelper.accessor('agentId', { header: 'AgentId' }),
    columnHelper.accessor((row, index) => index + 1, {
      header: '#',
      id: 'serialNumber',
    }),
    columnHelper.accessor('agentId', { header: 'AgencyId'}),
    columnHelper.accessor('email', { header: 'Email' }),
    columnHelper.accessor('first_name', { header: 'First Name' }),
    columnHelper.accessor('last_name', { header: 'Last Name' }),
    // columnHelper.accessor('address', { header: 'Address' }),
    columnHelper.accessor('user_type', { header: 'User Type' }),
    columnHelper.accessor('phonenumber', { header: 'Phone Number' }),
    // columnHelper.accessor('whatsapp_number', { header: 'Whatsapp Number' }),
    columnHelper.accessor('company_name', { header: 'Company Name' }),
    // columnHelper.accessor('business_type', { header: 'Business Type' }),
    columnHelper.accessor('state', { header: 'State' }),
    columnHelper.accessor('createdAt', { header: 'Created Date' }),

    columnHelper.accessor('_id', {
      header: 'Options',
      cell: (info) => {
        const optionsArr = [
          {
            name: 'view',
            onClickHandler: () => handleRedirect(info.getValue().toString()),
          },
          {
            name: 'delete',
            onClickHandler: () => handleDelete(info.getValue().toString()),
          },
        ];
        return (
          <OptionsDropdown actions={optionsArr}>
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];

  const formateDate = (dateString) => { 
    const date = new Date(dateString); 
    return date.toISOString().split('T')[0]; 
  };
  const formatedData = data.data.map((item) => ({ 
    ...item, 
    createdAt: formateDate(item.createdAt), 
  }));

  const COLUMNS = useMemo(() => columns, []);
  return (
    <>
      <BaseTable data={formatedData} total_count={data.count} columns={COLUMNS} />
    </>
  );
};

export default UserTable;
