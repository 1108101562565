import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllQuotationQuery } from '../../../apis/bookingApis/bookingApi';
import BookingTable from '../../../components/bookingTable/BookingTable';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../app/store/reducers/authSlice';
import QuotationTable from '../../../components/QuotationTable';

const Itineraries = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState('');
  const [itineraryName, setItineraryName] = useState(searchParams.get('itineraryName') || '');
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentUser = useSelector(selectCurrentUser);

  const { data, isLoading, isFetching, refetch } = useGetAllQuotationQuery(
    {
      keyword: searchParams.get('keyword') || '',
      itineraryName: searchParams.get('itineraryName') || '',
      page: pageIndex,
      limit: pageSize,
    },
    { refetchOnMountOrArgChange: true },
  );

  const handleSearch = () => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    const params = {};
    if (keyword) params.keyword = keyword;
    if (itineraryName) params.itineraryName = itineraryName;
    setSearchParams(params);
  };

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'}>
          <Typography variant="h5" fontWeight={700}>
            Itineraries Table
          </Typography>
          <button
            type="submit"
            className="h-full p-1 text-sm font-medium text-white bg-blue-700 border border-blue-700 rounded-e-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            onClick={() => refetch()}
          >
            Refresh
          </button>
        </Box>
        <Box gap={2} display={'flex'}>
          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={() => navigate(pathname + '/create')}
          >
            Create
          </button>
        </Box>
      </Box>
      <Divider role="presentation" sx={{ marginTop: '0.3rem' }} />
      <br />
      <div className="flex gap-2 mb-2">
        <input
          type="search"
          id="search-dropdown"
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg  border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Search By Quotation ID"
          defaultValue={keyword}
          onChange={handleChange}
        />
         <input
          type="search"
          className="block p-2 text-sm border rounded-lg focus:ring focus:border-blue-500"
          placeholder="Search by Itinerary Name"
          value={itineraryName}
          onChange={(e) => setItineraryName(e.target.value)}
        />
        <button
          type="submit"
          className=" p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
          onClick={handleSearch}
        >
          Search
          <span className="sr-only">Search</span>
        </button>
      </div>
      <QuotationTable
        data={data?.data?.length > 0 ? data : { data: [] }}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        currentUser={currentUser}
        isLoading={isLoading || isFetching}
      />
    </>
  );
};

export default Itineraries;
