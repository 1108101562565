import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Navbar from '../navbar/Navbar';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const HomeLayout = () => {
  return (
    <React.Fragment>
      <ToastContainer />
      <CssBaseline />
      <Container maxWidth="xl">
        <Navbar />
        <Container maxWidth="xl">
          <Box sx={{ paddingTop: '0.7rem' }}>
            <Outlet />
          </Box>
        </Container>
      </Container>
    </React.Fragment>
  );
};

export default HomeLayout;
