import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllHotelsQuery } from '../../../apis/hotelApis/hotelApi';
import HotelTable from '../../../components/hotelTable/HotelTable';

const Hotels = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState('');

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { data, isLoading, isFetching, refetch } = useGetAllHotelsQuery(
    {
      keyword: searchParams.get('keyword') || '',
      page: pageIndex,
      limit: pageSize,
    },
    { refetchOnMountOrArgChange: true },
  );

  const handleSearch = () => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setSearchParams({ keyword: keyword });
  };

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'}>
          <Typography variant="h4" fontWeight={700}>
            Hotels
          </Typography>
          <Button
            disableElevation
            sx={{ marginLeft: '0.5rem' }}
            variant="contained"
            onClick={() => refetch()}
          >
            {' '}
            Refresh
          </Button>
        </Box>
        <Button
          variant="contained"
          disableElevation
          onClick={() => navigate(pathname + '/create')}
        >
          {' '}
          Create{' '}
        </Button>
      </Box>
      <Divider role="presentation" sx={{ marginTop: '0.3rem' }} />
      <br />

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TextField
            className="custom-select"
            label="Select Hotel"
            type="text"
            placeholder="Hotel Name"
            defaultValue={keyword}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" disableElevation onClick={handleSearch}>
            {' '}
            Search
          </Button>
        </Grid>
      </Grid>
      {!isLoading && !isFetching && data?.data.length > 0 && (
        <HotelTable
          data={data}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
        />
      )}
    </>
  );
};

export default Hotels;
