import React from 'react';
import {
  Box,
  Button,
  Divider,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useGetAllWalletTransactionsQuery } from '../../../apis/walletApis/walletApi';
import AllAgentWalletTransactionTable from '../../../components/Accounts/AllAgentWalletTransactionTable';

const AccountTransaction = () => {
  const [searchParams] = useSearchParams();
  const { data, error, isLoading, isFetching } =
    useGetAllWalletTransactionsQuery();
    console.log("Fetched Transactions Data:", data);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'}>
          <Typography variant="h5" fontWeight={700}>
            All Agents Wallet Statement
          </Typography>
          <Button
            onClick={() => window.location.reload()}
            variant="contained"
            sx={{ ml: 2 }}
          >
            Refresh
          </Button>
        </Box>
      </Box>
      <Divider role="presentation" sx={{ marginTop: '0.3' }} />
      <br />

      {isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      )}

      {!isLoading && !isFetching && data?.transactions.length && (
        <AllAgentWalletTransactionTable data={data.transactions} />
      )}
    </>
  );
};

export default AccountTransaction;
