import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllActivitiesQuery } from '../../../apis/activityApis/activityApi';
import ActivitiesTable from '../../../components/activitiesTable/ActivitiesTable';

const Activities = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState('');
  const [country, setCountry] = useState('');
  const [type, setType] = useState('');
  const [location, setLocation] = useState('');

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data, isLoading, isFetching, refetch } = useGetAllActivitiesQuery({
    keyword: searchParams.get('keyword') || '',
    country: searchParams.get('country') || '',
    type: searchParams.get('type') || '',
    location: searchParams.get('location') || '',
  });

  const handleSearch = () => {
    const params = {};
    if (keyword) params.keyword = keyword;
    if (country) params.country = country;
    if (type) params.type = type;
    if (location) params.location = location; 
    setSearchParams(params);
  };

  return ( 
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'}>
          <Typography variant="h5" fontWeight={700}>
            Activities Table
          </Typography>
          <button
            type="submit"
            className="h-full p-1 text-sm font-medium text-white bg-blue-700 border border-blue-700 rounded-e-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            onClick={() => refetch()}
          >
            Refresh
          </button>
        </Box>
        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          onClick={() => navigate(pathname + '/create')}
        >
          Create
        </button>
      </Box>
      <Divider role="presentation" sx={{ marginTop: '0.3rem' }} />
      <br />

      <div className="flex gap-2 mb-2">
        <input
          type="search"
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Activity Name"
          defaultValue={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <input
          type="search"
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Country"
          defaultValue={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <input
          type="search"
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Type"
          defaultValue={type}
          onChange={(e) => setType(e.target.value)}
        />
        <input
          type="search"
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Location"
          defaultValue={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <button
          type="submit"
          className="p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
          onClick={handleSearch}
        >
          Search
          <span className="sr-only">Search</span>
        </button>
      </div>

      {!isLoading && !isFetching && data?.data.length > 0 && (
        <ActivitiesTable data={data} />
      )}
    </>
  );
};

export default Activities;
