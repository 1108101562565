import { Box, Typography } from '@mui/material';
import React from 'react';
import Select, { components } from 'react-select';

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <Box display={'flex'} alignItems={'center'} gap={2} textAlign={'left'}>
        <img
          src={children?.option?.images[0] || ''}
          alt="Hotel"
          width={150}
          height={150}
        />
        <Typography>{children?.option[children?.fieldName]}</Typography>
      </Box>
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <Box display={'flex'} alignItems={'center'} gap={2} textAlign={'left'}>
      {/* <img
        src={children?.option?.images[0] || ''}
        alt="Hotel"
        width={150}
        height={150}
      /> */}
      <Typography sx={{ whiteSpace: 'normal' }}>
        {children?.option[children?.fieldName]}
      </Typography>
    </Box>
  </components.SingleValue>
);

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  fieldValue,
  fieldName,
  cb,
  custom = false,
  defaultValue,
}) => {
  const onChange = (option) => {
    cb &&
      cb(isMulti ? option.map((item) => item[fieldValue]) : option[fieldValue]);
    form.setFieldValue(
      field.name,
      isMulti ? option.map((item) => item[fieldValue]) : option[fieldValue],
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option) => field.value.indexOf(option[fieldValue]) >= 0,
          )
        : options.find((option) => option[fieldValue] === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  const filterOptions = (candidate, input) => {
    let searchItem = candidate.label.option[fieldName].toLowerCase();
    if (input) {
      return searchItem.includes(input.toLowerCase());
    }
    return true;
  };

  return (
    <>
      {custom ? (
        <Select
          className={className}
          name={field.name}
          getOptionValue={(options) => options[fieldValue]}
          getOptionLabel={(option) => ({ option, fieldName })}
          onChange={onChange}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          defaultValue={defaultValue}
          components={{ Option: CustomOption, SingleValue: SingleValue }}
          filterOption={filterOptions}
        />
      ) : (
        <Select
          className={className}
          name={field.name}
          getOptionValue={(options) => options[fieldValue]}
          getOptionLabel={(option) => option[fieldName]}
          onChange={onChange}
          defaultValue={defaultValue}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
        />
      )}
    </>
  );
};

export default CustomSelect;
