import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from '../optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  useLazyGetSingleBookingQuery,
  useSendBookingMutation,
  useConfirmBookingMutation,
  useConfirmPaymentQuotationMutation,
  useConfirmOfflinePaymentMutation,
} from '../../apis/bookingApis/bookingApi';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal } from '@mui/material';
import BookingPreview from '../bookingPreview/BookingPreview';
import {
  useGetAllLocationsQuery,
  useGetAllVisasQuery,
} from '../../apis/locationsApi/locationsApi';
import QuotationPreview from '../QuotationPreview';
import {
  useCreateOrderMutation,
  useVerifyPaymentMutation,
} from '../../apis/paymentApis/paymentApis';
import { toast } from 'react-toastify';
import { getAllCurrencies } from '../../app/store/reducers/currencySlice';
import ConfirmationModal from '../BookingModal/BookingModal';
import BookingModalOffline from '../BookingModal/BookingModalOffline';
import { useDebitWalletMutation } from '../../apis/paymentApis/paymentApis';
import WalletPaymentModal from '../Wallet/WalletPaymentModal';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

export const PreviewModal = forwardRef(({ children }, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  function handleClose() {
    setOpen(false);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minWidth: 400,
    minHeight: 500,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </>
  );
});

const BookingTable = ({
  data,
  currentUser,
  uId,
  walletBalance,
  pageSize,
  pageIndex,
  setPagination,
  refetch,
}) => {
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [walletPaymentAmount, setWalletPaymentAmount] = useState(0);
  const [walletCurrency, setWalletCurrency] = useState('');
  const dispatch = useDispatch();
  const modalRef = useRef();
  const quotationModalRef = useRef();
  const [bookingData, setBookingData] = useState({});
  const [previewData, setPreviewData] = useState({});
  const { data: allLocations } = useGetAllLocationsQuery({ keyword: '' });
  const { data: allVisas } = useGetAllVisasQuery();
  const [modalFlag, setModalFlag] = useState(0);
  const [confirmModalOpen, SetConfirmModalOpen] = useState(false);
  const [confirmOfflineModalOpen, SetConfirmOfflineModalOpen] = useState(false);
  const [createOrder] = useCreateOrderMutation();
  const [verifyPayment] = useVerifyPaymentMutation();
  const [confirmBooking] = useConfirmBookingMutation();
  const [confirmPayment] = useConfirmPaymentQuotationMutation();
  const [confirmOfflinePayment] = useConfirmOfflinePaymentMutation();
  const [sendBooking] = useSendBookingMutation();
  const currencies = useSelector(getAllCurrencies);
  const [debitWallet] = useDebitWalletMutation();
  

  const convertToPdf = async (res) => {
    if (bookingData) { 
      const location = allLocations?.data?.find(
        (x) => x.locationName === res.location, 
      );
      const resultData = {
        ...bookingData,
        itineraryInfo: res,
        document_required: location?.document_required, 
        currency: bookingData.location_data?.currencyCode,
        important_note: location?.important_note,
        terms_n_conditions: location?.terms_n_conditions,
        exclusion: location?.exclusion,
        ...(bookingData.visaStatus === true && {
          visa: allVisas.data.find((x) => x.country === res.country),
        }),
      };
      
      setPreviewData(resultData);

      modalFlag === 0 && modalRef.current.handleOpen();
      modalFlag === 1 && quotationModalRef.current.handleOpen();
    }
  };

  const [trigger, result] = useLazyGetSingleBookingQuery();

  useEffect(() => {
    result.status === 'fulfilled' && convertToPdf(result.data);
  }, [result]);

  const handleMarkConfirmed = useCallback(
    async (bookingData) => {
      try {
        let currency = bookingData.location_data.currencyCode;
        // Step 1: Create Razorpay Order
        const orderDetails = {
          amount: bookingData.total_amount,
          currency: currency,
          receipt: `${bookingData.bill_no}`,
        };
        const orderResponse = await createOrder(orderDetails).unwrap();

        // Step 2: Open Razorpay payment form
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: orderResponse.amount,
          currency: orderResponse.currency,
          name: 'Sky High Tours and Travel',
          description: 'Booking Payment',
          order_id: orderResponse.id,
          handler: async function (response) {
            try {
              // Step 3: Verify payment
              const paymentDetails = {
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              };
              const verificationResponse =
                await verifyPayment(paymentDetails).unwrap();

              if (verificationResponse.status === 'success') {
                // Step 4: Confirm payment and update booking status
                const paymentResponse = await confirmPayment({
                  id: bookingData._id,
                  is_payment: true,
                }).unwrap();

                if (paymentResponse.status === 'success') {
                  await confirmBooking({
                    id: bookingData._id,
                    is_payment: true,
                  }).unwrap();

                  toast.success(
                    'Booking Confirmed and Payment Successful!',
                    TOAST_CONFIG,
                  );

                  const { data: itineraryData } = await trigger(
                    bookingData._id,
                  );
                  const location = allLocations.data.find(
                    (x) => x.locationName === itineraryData.location,
                  );
                  await sendBooking({
                    pdfContent: {
                      ...bookingData,
                      itineraryInfo: itineraryData,
                      currency: currencies[itineraryData.country][0].code,
                      document_required: location.document_required,
                      important_note: location.important_note,
                      terms_n_conditions: location.terms_n_conditions,
                      exclusion: location.exclusion,
                      ...(bookingData.visaStatus && {
                        visa: allVisas.data.find(
                          (x) => x.country === itineraryData.country,
                        ),
                      }),
                    },
                    Id: currentUser.userId,
                    is_confirmed: true,
                    is_payment: true,
                  }).unwrap();
                  toast.success(
                    'Booking Confirmed and Email Sent Successfully!',
                    TOAST_CONFIG,
                  );
                } else {
                  toast.success(
                    'Booking Confirmed and Payment recieved.',
                    TOAST_CONFIG,
                  );
                }
              } else {
                toast.error('Payment verification failed.', TOAST_CONFIG);
              }
            } catch (err) {
              toast.error('Error verifying payment.', TOAST_CONFIG);
              console.error(err);
            }
          },
          prefill: {
            name: currentUser.name,
            email: currentUser.email,
            contact: currentUser.phonenumber,
          },
          notes: {
            address: 'Some Address',
          },
          theme: {
            color: '#F37254',
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      } catch (err) {
        toast.error('Error occurred while confirming booking.', TOAST_CONFIG);
        console.error(err);
      }
    },
    [
      createOrder,
      verifyPayment,
      confirmPayment,
      confirmBooking,
      trigger,
      sendBooking,
      allLocations.data,
      allVisas.data,
      currencies,
      currentUser,
    ],
  );
  const handleMarkOffline = useCallback(
    async (bookingData, offlinePaymentData) => {
      try {
        const response = await confirmOfflinePayment({
          id: bookingData._id,
          ...offlinePaymentData,
        });
        refetch();
        toast.success(
          'Offline payment confirmed and booking updated!',
          TOAST_CONFIG,
        );
      } catch (err) {
        toast.error('Error confirming offline payment.', TOAST_CONFIG);
        console.error(err);
      }
    },
    [confirmOfflinePayment],
  );
  const handleWalletPayment = useCallback(
    async (bookingData) => {
      try {
        const walletBalance1 = walletBalance || 0;
        const totalAmount = bookingData.total_amount;
        if (walletBalance1 < totalAmount) {
          toast.error('Insufficient wallet balance.', TOAST_CONFIG);
          return;
        }

        const debitResponse = await dispatch(
          debitWallet({
            userId: uId,
            amount: totalAmount,
            currency: "INR",
            description: `Payment for Booking ID: ${bookingData.bill_no}`,
          }),
        ).unwrap();
        
        if (debitResponse.status !== 'success') {
          toast.error('Failed to process wallet payment.', TOAST_CONFIG);
          return;
        }
        const paymentResponse = await confirmPayment({
          id: bookingData._id,
          is_payment: true,
        }).unwrap();
  
        if (paymentResponse.status === 'success') {
          await confirmBooking({
            id: bookingData._id,
            is_payment: true,
          }).unwrap();
  
          toast.success(
            'Booking Confirmed and Payment Successful using Wallet!',
            TOAST_CONFIG,
          );
  
          refetch(); // Refresh data after successful payment
        } else {
          toast.error('Failed to confirm booking.', TOAST_CONFIG);
        }
      } catch(error){
        toast.error('Error processing wallet payment.', TOAST_CONFIG);
        console.log(error);
      }
    },
    [currentUser, confirmPayment, confirmBooking, dispatch, refetch],
  );

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row, index) => index + 1 + pageIndex * pageSize, {
      header: '#',
      id: 'serialNumber',
    }),
    columnHelper.accessor((row) => 'CNF/' + row.bill_no, {
      header: 'BookingId',
      id: 'bill_no',
    }),
    columnHelper.accessor(
      (row) => (row.itineraryName ? row.itineraryName : row.bookingName),
      {
        header: 'Itinerary Name',
        id: 'itineraryName',
      },
    ),
    // columnHelper.accessor('itineraryName', { header: 'Itinerary Name' }),
    columnHelper.accessor('agent_data.email', { header: 'Email' }),
    columnHelper.accessor('agent_data.phonenumber', { header: 'Phone' }),
    columnHelper.accessor(
      (row) => ({
        amount:
          row.location_data.currencyCode +
          ' ' +
          parseFloat(row.total_amount).toFixed(2),
        is_payment: row.is_payment,
      }),
      {
        header: 'Total Amount',
        id: 'total_amount',
        cell: (info) => {
          const { amount } = info.getValue();
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{amount}</span>
            </div>
          );
        },
      },
    ),
    columnHelper.accessor('status', {
      header: 'Payment Status',
      cell: (info) => {
        const status = info.getValue();
        const due_amount = info.row.original.due_amount;
        let badgeColor = 'bg-red-500';

        if (status === 'paid') {
          badgeColor = 'bg-green-500';
        } else if (status === 'due') {
          badgeColor = 'bg-yellow-500';
        } else if (status === 'pending') {
          badgeColor = 'bg-red-500';
        }

        return (
          <div className="flex items-center space-x-2">
            <span
              className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${badgeColor} text-white`}
            >
              {status}
            </span>
            {status === 'due' && (
              <span className="text-sm text-red-700">
                {info.row.original.location_data.currencyCode}{' '}
                {parseFloat(due_amount).toFixed(2)}
              </span>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('created_at', { header: 'Booking date' }),
    columnHelper.accessor('_id', {
      header: 'Options',
      cell: (info) => {
        const { is_payment } = info.row.original;
        const optionsArr = [
          {
            name: 'Download PDF',
            onClickHandler: () => {
              setModalFlag(0);
              setBookingData(info.row.original);
              trigger(info.row.original._id);
            },
          },
          {
            name: 'Download Quotation',
            onClickHandler: () => {
              setModalFlag(1);
              setBookingData(info.row.original);
              trigger(info.row.original._id);
            },
          },
        ];

        if (!is_payment) {
          optionsArr.push({
            name: 'Online Payment',
            onClickHandler: () => {
              setBookingData(info.row.original);
              SetConfirmModalOpen(true);
            },
          });
        }
        // if (!is_payment) {
        //   optionsArr.push({
        //     name: 'Offline Payment',
        //     onClickHandler: () => {
        //       setBookingData(info.row.original);
        //       SetConfirmOfflineModalOpen(true);
        //     },
        //   });
        // }
        if(!is_payment){
          optionsArr.push({
            name: 'Wallet Payment',
            onClickHandler: () => {
              if (currentUser.wallet?.balance >= info.row.original.total_amount) {
                setWalletPaymentAmount(info.row.original.total_amount);
                setWalletCurrency(info.row.original.location_data.currencyCode);
                setBookingData(info.row.original);
                setWalletModalOpen(true);
              } else {
                setWalletPaymentAmount(info.row.original.total_amount);
                setWalletCurrency(info.row.original.location_data.currencyCode);
                setBookingData(info.row.original);
                setWalletModalOpen(true);
                
              }
            },
          });
        }
        return (
          <OptionsDropdown actions={optionsArr}>
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];
  const formateDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  const formatedData = data?.data?.map((item) => ({
    ...item,
    created_at: formateDate(item.created_at),
    tour_start_date: formateDate(item.tour_start_date),
    tour_end_date: formateDate(item.tour_end_date),
  })) || [];

  const userData = data.data.map((i) => ({
    ...i,
    email: i.email,
  })) || [];
  const COLUMNS = useMemo(() => columns, []);

  const handleConfirmModalClose = () => {
    SetConfirmModalOpen(false);
  };

  const handleConfirmModalProceed = () => {
    handleMarkConfirmed(bookingData);
    SetConfirmModalOpen(false);
  };

  const handleOfflineBookingModalClose = () => {
    SetConfirmOfflineModalOpen(false);
  };
  const handleOfflineBookingModalProceed = (offlinePaymentData) => {
    handleMarkOffline(bookingData, offlinePaymentData);
    SetConfirmOfflineModalOpen(false);
  };
  const handleWalletPaymentConfirm = async () => {
    try {
      await handleWalletPayment(bookingData);
      setWalletModalOpen(false); // Close the modal on success
    } catch (err) {
      toast.error('Error processing wallet payment.', TOAST_CONFIG);
      console.error(err);
    }
  };
  
  return (
    <>
      <PreviewModal ref={modalRef}> 
        <BookingPreview data={previewData} /> 
      </PreviewModal> 
      <PreviewModal ref={quotationModalRef}> 
        <QuotationPreview data={previewData} /> 
      </PreviewModal> 
      <BaseTable 
        data={formatedData}
        currentUser={userData}
        total_count={data?.count || 0}
        columns={COLUMNS}
        previewData={previewData}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        noPaginate={false}
      />
      <ConfirmationModal
        open={confirmModalOpen}
        handleClose={handleConfirmModalClose}
        handleConfirm={handleConfirmModalProceed}
      />
      <BookingModalOffline
        open={confirmOfflineModalOpen}
        handleClose={handleOfflineBookingModalClose}
        handleConfirm={handleOfflineBookingModalProceed}
      />
      <WalletPaymentModal
        open={walletModalOpen}
        amount={walletPaymentAmount}
        currency={walletCurrency}
        onClose={() => setWalletModalOpen(false)}
        onConfirm={handleWalletPaymentConfirm}
      />

    </>
  );
};

export default BookingTable;
