import { apiSlice } from '../../app/api/baseApi';

export const LeadApi = apiSlice.injectEndpoints({
  tagType: ['Leads'],
  endpoints: (builder) => ({
    createLeads: builder.mutation({
      query: (body) => ({
        url: '/leades',  
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Leads'],
    }),
    getAllLeads: builder.query({
      query: (keyword) => `all-leades/${keyword.keyword || ''}`,  
      providesTags: (result, error, leadsId) => [
        {
          type: 'Leads',
          id: `${leadsId}`,
        },
      ],
    }),
    updateLeadStatus: builder.mutation({
      query: ({ leadId, status }) => ({
        url: `/leads/${leadId}/status`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: ['Leads'],
    }),
  }),
});

export const {
  useCreateLeadsMutation,
  useGetAllLeadsQuery,
  useUpdateLeadStatusMutation
} = LeadApi;
