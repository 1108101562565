import { createSlice } from '@reduxjs/toolkit';
const currencyMapping = {
  'United Arab Emirates': [{ code: 'AED', name: 'Dirham' }],
  'Kingdom of Thailand': [{ code: 'THB', name: 'Baht' }],
  'Republic of Singapore': [{ code: 'SGD', name: 'Dollar' }],
  'Indonesia': [{ code: 'IDR', name: 'Rupiah' }],
  'Vietnam': [{ code: 'USD', name: 'United States Dollar' }],
  'Azerbaijan': [{ code: 'USD', name: 'United States Dollar' }],
};
const currencySlice = createSlice({
  name: 'currency',
  initialState: {
    allCurrencies: currencyMapping,
  },
  reducers: {
    setAllCurrencies: (state, action) => {
      const currencyData = action.payload;
      state.allCurrencies = currencyData;
    },
  },
});

export const { setAllCurrencies } = currencySlice.actions;

export default currencySlice.reducer;

export const getAllCurrencies = (state) => state.currency.allCurrencies;
