import { apiSlice } from "../../app/api/baseApi";

export const userApi = apiSlice.injectEndpoints({
  tagTypes: ["User"],
  endpoints: (builder) => ({
    //to get all users
    getAllUsers: builder.query({
      query: (keyword) => `/users?keyword=${keyword}`,
      providesTags: ["User"],
    }),
  
  // to get user by ID
  getUserById: builder.query({
    query: (userId) => `/users/${userId}`,
    providesTags: (result, error, userId) => [
      {
        type: "User",
        id: `${userId}`,
      },
    ],
  }),
  // to deleted user by Id
  deleteUser: builder.mutation({
    query: (userId) => ({
      url: `/users/${userId}`,
      method: 'DELETE'
    }),
  }),
  // New mutation endpoint for agent verification
  verifyAgent: builder.mutation({
    query: (userId) => ({
        url: `/users/${userId}/verify`, 
        method: "POST",
      }),
    }), 
  }),
});

export const { useGetAllUsersQuery, useGetUserByIdQuery, useDeleteUserMutation, useVerifyAgentMutation } = userApi;
