import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Rating, Typography } from '@mui/material';
import ModuleHeader from '../../../../components/moduleHeader/ModuleHeader';
import { useGetHotelByIdQuery } from '../../../../apis/hotelApis/hotelApi';
import { createColumnHelper } from '@tanstack/react-table';
import BaseTable from '../../../../components/baseTable/BaseTable';
import { useSelector } from 'react-redux';
import { getAllCurrencies } from '../../../../app/store/reducers/currencySlice';

const ViewHotel = () => {
  const { id } = useParams();
  const currencies = useSelector(getAllCurrencies);
  const { data: hotel, isLoading, isError } = useGetHotelByIdQuery(id);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('images', {
      header: 'Image',
      cell: (info) => {
        return (
          <>
            <img height={200} width={200} src={info.row.original.images[0]} />
          </>
        );
      },
    }),
    columnHelper.accessor('type', { header: 'Room' }),
    columnHelper.accessor('facility', { header: 'Facilities' }),
    columnHelper.accessor(
      (row) => currencies?.[hotel?.country]?.[0]?.code + ' ' + row.price,
      {
        header: 'Price',
      },
    ),
    columnHelper.accessor(
      (row) =>
        row.capacityAdult +
        ' Adult, ' +
        row.capacityChild +
        ' Child, ' +
        row.capacityInfant +
        ' Infant',
      { header: 'Capacity', id: 'capacity' },
    ),
  ];

  const COLUMNS = useMemo(() => columns, [hotel]);

  return (
    <>
      {hotel && (
        <>
          <ModuleHeader
            headerTitle={hotel.hotelName}
            headerImg={
              hotel.images && hotel.images.length > 0 ? hotel.images[0] : null
            }
          />
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="h4">Description</Typography>
              <Typography color="text.secondary">
                {hotel.description}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" fontWeight={700}>
                Rating:
              </Typography>
              <Rating value={hotel.rating} readOnly precision={0.5} />
              <Typography variant="h5" fontWeight={700}>
                Location:
              </Typography>
              <Typography>{hotel.location}</Typography>
            </Grid>
            <Grid item xs={12}>
              <br />
              <Typography variant="h4">Available Rooms</Typography>
              <BaseTable
                data={hotel.rooms}
                total_count={hotel.rooms.length}
                columns={COLUMNS}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ViewHotel;
