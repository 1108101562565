import * as React from 'react';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useUpdateVisaMutation,
  useGetVisaByIdQuery,
} from '../../../../apis/locationsApi/locationsApi';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

export default function EditVisa() {
  const { id } = useParams();
  const { data: visa, isLoading, isError } = useGetVisaByIdQuery(id);
  const [updateVisa, { isLoading: isUpdating }] = useUpdateVisaMutation();

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error fetching visa</Typography>;
  }

  const initialValues = {
    country: visa.country || '',
    adult_price: visa.price.adult_price || '',
    child_price: visa.price.child_price || '',
    infant_price: visa.price.infant_price || '',
    visaType: visa.visaType || '',
    note: visa.note.join('\n') || '',
    checklist: visa.checklist.join('\n') || '',
  };

  const handleSubmit = async (values) => {
    try {
      await updateVisa({
        visaId: id,
        body: {
          country: values.country,
          price: {
            adult_price: values.adult_price,
            child_price: values.child_price,
            infant_price: values.infant_price,
          },
          visaType: values.visaType,
          note: values.note
            .trim()
            .replace(/(\r\n|\n|\r)/gm, '')
            .split(','),
          checklist: values.checklist
            .trim()
            .replace(/(\r\n|\n|\r)/gm, '')
            .split(','),
        },
      }).unwrap();
      toast.success('Visa updated successfully', TOAST_CONFIG);
    } catch (error) {
      toast.error('Failed to update visa', TOAST_CONFIG);
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={3} sx={{ marginRight: '15%', marginLeft: '15%' }}>
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 5 }}>
            Edit Visa
          </Typography>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Country"
                      name="country"
                      fullWidth
                      value={values.country}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Adult Price"
                      name="adult_price"
                      type="number"
                      fullWidth
                      value={values.adult_price}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Child Price"
                      name="child_price"
                      type="number"
                      fullWidth
                      value={values.child_price}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Infant Price"
                      name="infant_price"
                      type="number"
                      fullWidth
                      value={values.infant_price}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Visa Type"
                      name="visaType"
                      fullWidth
                      value={values.visaType}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Note"
                      name="note"
                      multiline
                      rows={4}
                      fullWidth
                      value={values.note}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Checklist"
                      name="checklist"
                      multiline
                      minRows={10}
                      fullWidth
                      value={values.checklist}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isUpdating}
                    >
                      {isUpdating ? 'Updating...' : 'Update Visa'}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
