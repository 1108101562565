import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from '../optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useUpdateWalletMutation } from '../../apis/walletApis/walletApi';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const getBadgeColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'bg-green-500';
    case 'Pending':
      return 'bg-yellow-500';
    case 'Failed':
      return 'bg-red-500';
    default:
      return 'bg-yellow-500';
  }
};

const AllAgentWalletTransactionTable = ({ data }) => {
  const navigate = useNavigate();
  const [updateWallet] = useUpdateWalletMutation();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (transaction) => {
    setSelectedTransaction(transaction);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTransaction(null);
  };

  const handleConfirmUpdate = async () => {
    if (selectedTransaction) {
      try {
        await updateWallet({ transactionId: selectedTransaction.wallet.transactions.transactionId });
        navigate('/dashboard/accounts');
      } catch (error) {
        console.error('Update failed:', error);
      } finally {
        handleClose();
      }
    }
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row, index) => index + 1, {
      header: '#',
      id: 'serialNumber',
    }),
    columnHelper.accessor('agentId', {
      header: 'Agent ID',
    }),
    columnHelper.accessor('email', {
      header: 'Email',
    }),
    columnHelper.accessor('wallet.transactions.transactionId', {
      header: 'Transaction ID',
    }),
    columnHelper.accessor('wallet.transactions.amount', {
      header: 'Amount',
      cell: (info) => `${info.getValue()}`,
    }),
    columnHelper.accessor('wallet.transactions.bankName', {
      header: 'Bank Name',
    }),
    columnHelper.accessor('wallet.transactions.branch', {
      header: 'Branch',
    }),
    columnHelper.accessor('wallet.transactions.date', {
      header: 'Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    }),
    columnHelper.accessor('wallet.transactions.remarks', {
      header: 'Remarks',
    }),
    columnHelper.accessor('wallet.transactions.status', {
      header: 'Status',
      cell: (info) => {
        const status = info.getValue();
        const badgeColor = getBadgeColor(status);
        return (
          <div className={`p-2 rounded ${badgeColor}`}>
            <span className="text-white">{status}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor('_id', {
      header: 'Options',
      cell: (info) => {
        const transaction = info.row.original;
        return (
          <OptionsDropdown
            actions={[
              {
                name: 'Update',
                onClickHandler: () => handleOpen(transaction),
              },
            ]}
          >
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];

  const COLUMNS = useMemo(() => columns, []);

  return (
    <div>
      <BaseTable data={data} total_count={data.length} columns={COLUMNS} />
      
      {/* Modal for Update Confirmation */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Confirm Transaction Update
          </Typography>
          {selectedTransaction && (
            <>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                <strong>Transaction ID:</strong> {selectedTransaction.wallet.transactions.transactionId}
              </Typography>
              <Typography sx={{ mt: 1 }}>
                <strong>Amount:</strong> {selectedTransaction.wallet.transactions.amount}
              </Typography>
              <Button
                onClick={handleConfirmUpdate}
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
              >
                Confirm
              </Button>
              <Button onClick={handleClose} sx={{ mt: 1 }}>
                Cancel
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default AllAgentWalletTransactionTable;
