import React from "react";
import { Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export default function CountItineary({ data }) {
  // const [searchParams] = useSearchParams();
  // const startDate = searchParams.get("start");
  // const endDate = searchParams.get("end");

  return (
    <>
      {/* <Typography variant="h5" fontWeight={400} sx={{ m: 1, fontWeight: 700 }}>
        Looking for tours between {startDate} to {endDate}
      </Typography> */}
      <Typography variant="h5" fontWeight={400} sx={{ m: 1 }}>
        {data?.count || 0} tours found
      </Typography>
    </>
  );
}
