import React, { useMemo } from 'react';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from '../optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Rating from '@mui/material/Rating';
import { useDeleteActivityMutation } from '../../apis/activityApis/activityApi';
import { useNavigate } from 'react-router-dom';

const ActivitiesTable = ({ data }) => {
  const navigate = useNavigate();

  const handleRedirect = (activityId) => {
    const viewUrl = `view/${activityId}`;
    navigate(viewUrl);
  };

  const handleEdit = (activityId) => {
    const viewUrl = `edit/${activityId}`;
    navigate(viewUrl);
  };

  const [deleteActivity] = useDeleteActivityMutation();
  const handleDelete = async (Id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Activity?");
    if(confirmDelete){
      await deleteActivity(Id)
      .unwrap()
      .then(() => {
        console.log(`Activity with ID ${Id} deleted successfully.`);
      })
      .catch((error) => {
        console.log(`Failed to delete Activity with ID ${Id}:`, error);
      });
    }
    
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row, index) => index + 1, {
      header: '#',
      id: 'serialNumber',
    }),
    // columnHelper.accessor('_id', { header: 'ActivityID'}),
    columnHelper.accessor('type', { header: 'Activity Type' }),
    columnHelper.accessor('country', { header: 'Country Name' }),
    columnHelper.accessor('activityName', { header: 'activityName' }),
    columnHelper.accessor('location', { header: 'location / City' }),
    // columnHelper.accessor('description', {
    //   header: 'Activity Description',
    //   cell: (info) => {
    //     return (
    //       <>
    //         {info.getValue().length > 10
    //           ? info.getValue().split(0, 10)[0] + '...'
    //           : info.getValue()}
    //       </>
    //     );
    //   },
    // }),
    columnHelper.accessor('duration', { header: 'Activity duration' }),
    columnHelper.accessor('source', { header: 'Source' }),
    columnHelper.accessor('destination', { header: 'destination' }),
    columnHelper.accessor('createdAt', { header: 'Created date' }),
    // columnHelper.accessor("price.WOT.AdultCost", {
    //   header: "Without Transfer Adult Cost",
    // }),
    // columnHelper.accessor("price.WOT.ChildCost", {
    //   header: "Without Transfer Child Cost",
    // }),
    // columnHelper.accessor("price.WOT.InfantCost", {
    //   header: "Without Transfer Infant Cost",
    // }),
    // columnHelper.accessor("price.ST.AdultCost", {
    //   header: "Sharing Transfer Adult Cost",
    // }),
    // columnHelper.accessor("price.ST.ChildCost", {
    //   header: "Sharing Transfer Child Cost",
    // }),
    // columnHelper.accessor("price.ST.InfantCost", {
    //   header: "Sharing Transfer Infant Cost",
    // }),
    // columnHelper.accessor("price.PT.AdultCost", {
    //   header: "Private Transfer Adult Cost",
    // }),
    // columnHelper.accessor("price.PT.ChildCost", {
    //   header: "Private Transfer Child Cost",
    // }),
    // columnHelper.accessor("price.PT.InfantCost", {
    //   header: "Private Transfer Infant Cost",
    // }),

    // columnHelper.accessor("rating", {
    //   header: "Ratings",
    //   cell: (info) => {
    //     return (
    //       <>
    //         <Rating
    //           name="simple-controlled"
    //           value={parseFloat(info.getValue())}
    //           precision={0.5}
    //           readOnly
    //         />
    //       </>
    //     );
    //   },
    // }),
    columnHelper.accessor('_id', {
      header: 'Options',
      cell: (info) => {
        const optionsArr = [
          {
            name: 'view',
            onClickHandler: () => handleRedirect(info.getValue().toString()),
          },
          {
            name: 'edit',
            onClickHandler: () => handleEdit(info.getValue().toString()),
          },
          {
            name: 'delete',
            onClickHandler: () => handleDelete(info.getValue().toString()), 
          },
        ];
        return (
          <OptionsDropdown actions={optionsArr}>
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];

  const formateDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  const formatedData = data.data.map((item) => ({
    ...item,
    createdAt: formateDate(item.createdAt),
  }));

  const COLUMNS = useMemo(() => columns, []);

  return (
    <>
      <BaseTable
        data={formatedData}
        total_count={data.count}
        columns={COLUMNS}
      />
    </>
  );
};

export default ActivitiesTable;
