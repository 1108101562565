import React from "react";
import FeaturedItineraries from "../../components/featuredItineraries/FeaturedItineraries";
import { Alert, Grid, Typography } from "@mui/material";
import SearchFilter from "../../components/searchfilter/SearchFilter";
import ItinearyList from "../../components/itinearyList/itinearyList";
import CountItineary from "../../components/countitineary/CountItineary";
import { useGetAllAdminItinerariesQuery } from "../../apis/itineraryApis/itineraryApi";
import { useSearchParams } from "react-router-dom";

export default function Search() {
  const n = 12;
  const durationArray = Array.from({ length: n }, (_, index) => ({ [index + 1]: false }));
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = React.useState(durationArray);
  // console.log(searchParams.get("location"));
  const { data, isLoading, isFetching } = useGetAllAdminItinerariesQuery(
    {
      locaion: searchParams.get("location") || "",
      country: searchParams.get("country") || "",
      state: searchParams.get("state") || "",
    },
    { refetchOnMountOrArgChange: true }
  );

  if (isLoading || isFetching) {
    <Typography>Loading...</Typography>;
  }
  // console.log(data);

  return (
    <>
      <FeaturedItineraries />
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <SearchFilter state={state} setState={setState} />
        </Grid>

        <Grid item md={8} xs={12}>
          <CountItineary data={data} />
          <Grid container spacing={2}>
            {data &&
              data?.data?.length > 0 &&
              data.data.map((eachItem, index) => (
                <Grid item key={index} xs={12} md={4}>
                  <ItinearyList data={eachItem} />
                </Grid>
              ))}
            {data && !data?.data.length && (
              <>
                <Alert severity="error">No Itineary Found </Alert>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
