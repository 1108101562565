import { createBrowserRouter } from 'react-router-dom';
import SignUp from '../pages/signup/SignUp';
import SignIn from '../pages/signin/SignIn';
import Home from '../pages/home/Home';
import HomeLayout from '../components/homeLayout/HomeLayout';
import SearchLayout from '../components/searchLayout/searchLayout';
import Search from '../pages/search/search';
import AuthGuard from '../app/guard/AuthGuard';
import Dashboard from '../pages/dashboard/Dashboard';
import Hotels from '../pages/dashboard/hotels/Hotels';
import Bookings from '../pages/dashboard/bookings/Bookings';
import CreateHotel from '../pages/dashboard/hotels/createHotel/CreateHotel';
import Users from '../pages/dashboard/users/Users';
import Itineraries from '../pages/dashboard/itineraries/Itineraries';
import CreateItineraries from '../pages/dashboard/itineraries/createItineraries/CreateItineraries';
import Activities from '../pages/dashboard/activities/Activities';
import CreateActivities from '../pages/dashboard/activities/createActivity/CreateActivities';
import ViewActivity from '../pages/dashboard/activities/viewActivities/ViewActivities';
import ViewHotel from '../pages/dashboard/hotels/viewHotel/ViewHotel';
import ViewItineraries from '../pages/dashboard/itineraries/viewItineraries/ViewItineraries';
import ItineraryPage from '../pages/itineraryPage/ItineraryPage';
import ForgotPassword from '../pages/forgotPassword/ForgotPassword';
import Locations from '../pages/dashboard/locations/Locations';
import CreateLocations from '../pages/dashboard/locations/createLocations/CreateLocations';
import Profile from '../pages/profile/Profile';
import ViewLocation from '../pages/dashboard/locations/viewLocations/ViewLocation';
import User from '../components/user/User';
import ViewUser from '../pages/dashboard/users/viewUser/ViewUser';
import EditItinerary from '../pages/dashboard/itineraries/editItinerary/EditItinerary';
import EditLocation from '../pages/dashboard/locations/editLocation/EditLocation';
import EditActivity from '../pages/dashboard/activities/editActivity/EditActivity';
import EditProfile from '../pages/profile/EditProfile';
import CreateAdminItinerary from '../pages/dashboard/admin-templete/createItineraries/CreateItineraries';
import CreateVisa from '../pages/dashboard/visa/createVisa/CreateVisa';
import Visa from '../pages/dashboard/visa/Visa';
import ViewVisa from '../pages/dashboard/visa/ViewVisa';
import EditVisa from '../pages/dashboard/visa/editVisa/EditVisa';
import AdminTempletes from '../pages/dashboard/admin-templete/AdminTempletes';
import CustomizeItinerary from '../pages/dashboard/itineraries/CustomizeItinerary';
import TermsandCondition from '../pages/termsandconditions/TermsandCondition';
import PrivacyPolicy from '../pages/privacypolicy/PrivacyPolicy';
import ContactUs from '../pages/contactus/ContactUs';
import Dubai from '../pages/leadGeneration/Dubai/Dubai';
import EditAdmin from '../pages/dashboard/admin-templete/EditAdmin';
import LeadQuery from '../pages/dashboard/leadQuery/LeadQuery';
import UpdateBalance from '../pages/wallet/UpdateWallet';
import AccountTransaction from '../pages/dashboard/accounts/AccountTransaction';
import AgentWiseWalletRequest from '../pages/dashboard/accounts/AgentWiseWalletRequest';

const router = createBrowserRouter([
  {
    element: <AuthGuard />,
    children: [
      {
        path: '/',
        element: <HomeLayout />,
        children: [
          { path: '/', element: <Home /> },
          {
            path: 'itineraries',
            element: <SearchLayout />, 
            children: [
              { path: '', element: <Search /> }, 
              { path: 'view/:id', element: <ItineraryPage /> }, 
              { path: 'customize/:id', element: <CustomizeItinerary /> }, 
            ],
          },
          {
            path: 'profile',
            children: [
              { path: '', element: <Profile /> },
              { path: 'edit', element: <EditProfile /> },
            ],
          },
          {
            path: 'wallet',
            children: [
              { path: '', element: <UpdateBalance /> },
            ],
          },
          {
            path: 'dashboard',
            element: <Dashboard />,
            children: [
              {
                path: 'hotels',

                children: [
                  { path: '', element: <Hotels /> }, 
                  { path: 'create', element: <CreateHotel /> }, 
                  { path: 'view/:id', element: <ViewHotel /> }, 
                ],
              },
              {
                path: 'itineraries',

                children: [
                  { path: '', element: <Itineraries /> }, 
                  { path: 'create', element: <CreateItineraries /> }, 
                  { path: 'view/:id', element: <ViewItineraries /> }, 
                  { path: 'edit/:id', element: <EditItinerary /> },  
                  { path: 'customize/:id', element: <CustomizeItinerary /> }, 
                ],
              },
              {
                path: 'activities',

                children: [
                  { path: '', element: <Activities /> },
                  { path: 'create', element: <CreateActivities /> },
                  { path: 'view/:id', element: <ViewActivity /> },
                  { path: 'edit/:id', element: <EditActivity /> },
                ],
              },
              {
                path: 'location',

                children: [
                  { path: '', element: <Locations /> },
                  { path: 'create', element: <CreateLocations /> },
                  { path: 'view/:id', element: <ViewLocation /> },
                  { path: 'edit/:id', element: <EditLocation /> },
                ],
              },
              { path: 'bookings', element: <Bookings /> },
              // { path: "bookings", element: <Hotels /> },
              {
                path: 'users',
                children: [
                  { path: '', element: <Users /> },
                  { path: 'view/:id', element: <ViewUser /> },
                  { path: 'view/:id/verify', element: <User /> },
                ],
              },
              {
                path: 'visa',
                children: [
                  { path: '', element: <Visa /> },
                  { path: 'create', element: <CreateVisa /> },
                  { path: 'view/:id', element: <ViewVisa /> },
                  { path: 'edit/:id', element: <EditVisa /> },
                ],
              },
              {
                path: 'admintemplate', 
                children: [ 
                  { path: '', element: <AdminTempletes /> }, 
                  { path: 'create', element: <CreateAdminItinerary /> }, 
                  { path: 'edit/:id', element: <EditAdmin /> }, 
                ],
              },
              {
                path: 'wallet-statement',
                children: [
                  { path: '', element: <AgentWiseWalletRequest/> },
                ],
              },
              {
                path: 'accounts',
                children: [
                  { path: '', element: <AccountTransaction/> },
                ],
              },
              {
                path: 'leads-query',
                children: [
                  { path: '', element: <LeadQuery /> },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '/signup', element: <SignUp /> }, 
  { path: '/signin', element: <SignIn /> }, 
  { path: '/reset', element: <ForgotPassword /> }, 
  { path: '/termsandcondition', element: <TermsandCondition /> }, 
  { path: '/privacy-policy', element: <PrivacyPolicy /> }, 
  { path: '/contactus', element: <ContactUs /> }, 
  { path: '/dubai', element: <Dubai /> }, 
]);
// /hotels
// /admin/hotels crm admin hotel

export default router;
