import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Box, Paper } from '@mui/material';

const ModuleHeader = ({ headerImg, headerTitle }) => {
  return (
    <>
      {headerImg && (
        <Paper
          sx={{
            position: 'relative',
            backgroundColor: 'grey.800',
            color: '#fff',
            mb: 4,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${headerImg})`,
            borderRadius: '12px',
            height: '33rem',
          }}
        >
          {/* Increase the priority of the hero background image */}
          {
            <img
              style={{ display: 'none' }}
              src={headerImg}
              alt={'header'}
              height={'33rem'}
            />
          }
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,.3)',
              borderRadius: '12px',
              height: '33rem',
            }}
          />
          <Grid container>
            <Grid item md={6}>
              <Box
                sx={{
                  position: 'relative',
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  gutterBottom
                  fontWeight={700}
                >
                  {headerTitle}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default ModuleHeader;
