import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  auth,
  browserSessionPersistence,
  setPersistence,
} from "../../app/firebase/firebase.config";
import { sendPasswordResetEmail } from "firebase/auth";
import { database } from "../../app/firebase/firebase.config";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

function ForgotPassword() {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const emalVal = e.target.email.value;
    try {
      await setPersistence(auth, browserSessionPersistence);
      await sendPasswordResetEmail(auth, emalVal);
      alert("Password reset Email sent. Please check your email");
    } catch (error) {
      alert(error.code);
    }
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   const email = data.get("email");

  //   try {
  //     await setPersistence(auth, browserSessionPersistence);
  //     await auth.sendPasswordResetEmail(email);
  //     console.log(auth.currentUser);
  //     console.log(email);
  //     setResetEmailSent(true);
  //     toast.success("Password reset Email sent. Please check your email");
  //   } catch (error) {
  //     console.error(error);
  //     // Handle error, show error message to the user
  //     toast.error("Error occurred while sending reset email.")
  //   }
  // };

  const defaultTheme = createTheme();

  return (
    <>
    <Header/>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>

            <Box noValidate sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Reset Password
                </Button>
              </form>
              <Grid container>
                <Typography variant="body1" marginTop={1}>
                  <Link to="/signup" className="fw-bold text-decoration-none">
                    Register Here
                  </Link>
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <Footer/>
    </>
    
  );
}

export default ForgotPassword;
