import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { loginSchema } from '../../app/schema/authSchema';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../app/firebase/firebase.config';
import { browserSessionPersistence, setPersistence } from 'firebase/auth';
import { useState } from 'react';
import timeclock from '../../assets/dummy/timeclock.jpg';
import demo from '../../assets/dummy/demo.jpg';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { FaRegEye } from 'react-icons/fa6';
import { FaRegEyeSlash } from 'react-icons/fa6';

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import './SignIn.css';
import { setCredentials, setToken } from '../../app/store/reducers/authSlice';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../../apis/authApis/loginApi';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Background from '../../assets/images/robin-noguier-sydwCr54rf0-unsplash.jpg';
const SignIn = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [type, setType] = useState('password');
  const [hidden, setHidden] = useState('password');

  const handleToggle = () => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };
  const [login] = useLoginMutation();

  const TOAST_CONFIG = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = async (values, actions) => {
    try {
      setPersistence(auth, browserSessionPersistence).then(() => {
        signInWithEmailAndPassword(auth, values.email, values.password)
          .then((userCredential) => {
            const user = userCredential.user;
            dispatch(setCredentials({ userId: user.uid, email: user.email }));
            dispatch(
              setToken({
                accessToken: userCredential._tokenResponse.idToken,
                refreshToken: userCredential._tokenResponse.refreshToken,
              }),
            );
            login()
              .unwrap()
              .then((loginRes) => {
                dispatch(
                  setCredentials({
                    userId: loginRes?.data?.uId,
                    email: loginRes?.data?.email,
                    name:
                      loginRes?.data?.first_name +
                      ' ' +
                      loginRes?.data?.last_name,
                    phone: loginRes?.data?.phonenumber,
                    userType: loginRes?.data?.user_type,
                    verified: loginRes?.data?.verified,
                    companyLogo: loginRes?.data?.company_logo,
                  }),
                );
                actions.resetForm();
                navigate('/');
              });
          })
          .catch((error) => {
            console.error(error);
            setErrorMessage('Username or password is incorrect!!');
          });
      });
    } catch (err) {
      TOAST_CONFIG.autoClose = 5000;
      console.error(err);
      toast.error(`Error occurred: ${err.message}`, TOAST_CONFIG);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: submitHandler,
  });

  return (
    <div className="w-screen h-screen p-2">
      <div
        className="grid content-center p-5 rounded-lg md:p-0"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${Background})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          height: '100%',
          height: '100%',
          backgroundSize: 'cover',
        }}
      >
        <div className="w-full mx-auto bg-white rounded-lg shadow dark:border sm:max-w-md xl:p-0 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <img
              src="https://ik.imagekit.io/freelance/Skyhigh%20Tours.png?updatedAt=1721248097662"
              alt="Sky High"
              className="h-8"
            />
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Sign in to your account
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              action="POST"
              onSubmit={handleSubmit}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="name@company.com"
                  required=""
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Password
                </label>
                <div className="flex items-center">
                  <input
                    type={type}
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    required=""
                  />
                  <span
                    className="flex items-center justify-around"
                    onClick={handleToggle}
                  >
                    {type === 'password' ? (
                      <FaRegEyeSlash className="absolute h-20 mr-10 font-light text-gray-500" />
                    ) : (
                      <FaRegEye className="absolute h-20 mr-10 font-light text-gray-500" />
                    )}
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-end">
                <Link
                  to={'/reset'}
                  className="text-sm font-medium text-primary-600 hover:underline"
                >
                  Forgot password?
                </Link>
              </div>
              <button
                type="submit"
                disabled={!values.email || !values.password}
                className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-gray-600"
              >
                Sign in
              </button>
              <p className="text-sm font-light text-center text-gray-500">
                Don’t have an account yet?{' '}
                <Link
                  to={'/signup'}
                  className="font-medium text-primary-600 hover:underline"
                >
                  Sign up
                </Link>
              </p>

              <ul className="flex flex-wrap items-center justify-center text-sm font-light text-gray-500">
                <li>
                  <Link to="/privacy-policy" className="me-4">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/termsandcondition" className="me-4">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" className="me-4">
                    Contact Us
                  </Link>
                </li>
              </ul>
              <p className="text-sm font-light text-center text-gray-500">
                © Copyright 2024 SkyHigh Tours and Travel. All Rights
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <>
  //     <Header />
  //     <Container maxWidth={false} disableGutters>
  //       <ToastContainer />
  //       <Grid container>
  //         <Grid
  //           item
  //           md={6}
  //           display="flex"
  //           justifyContent="center"
  //           alignItems="center"
  //           className="sign-in-image-container"
  //         >
  //           <img
  //             className="sign-in-img"
  //             src={demo}
  //             alt="Vector for Sing In"
  //             style={{ width: '100%', objectFit: 'cover', height: '80vh' }}
  //           />
  //         </Grid>
  //         <Grid
  //           item
  //           md={6}
  //           display="flex"
  //           justifyContent="center"
  //           alignItems="center"
  //           height="80vh"
  //           className="sign-in-form-container"
  //         >
  //           <Card className="sign-in-card">
  //             <Typography
  //               variant="h4"
  //               component="h1"
  //               align="center"
  //               style={{ marginBottom: '20px' }}
  //             >
  //               Login
  //             </Typography>
  //             <CardContent>
  //               <form onSubmit={handleSubmit}>
  //                 {errorMessage && (
  //                   <Typography
  //                     variant="body2"
  //                     color="error"
  //                     style={{
  //                       fontWeight: 'bold',
  //                       color: 'red',
  //                       marginTop: '10px',
  //                     }}
  //                   >
  //                     {errorMessage}
  //                   </Typography>
  //                 )}

  //                 <TextField
  //                   id="email"
  //                   name="email"
  //                   type="email"
  //                   placeholder="UserName"
  //                   defaultValue={values.email}
  //                   onChange={handleChange}
  //                   onBlur={handleBlur}
  //                   error={errors.email && touched.email}
  //                   helperText={errors.email && touched.email && errors.email}
  //                   variant="outlined"
  //                   fullWidth
  //                   className="mb-3"
  //                   style={{ marginBottom: '20px' }}
  //                   InputProps={{
  //                     startAdornment: (
  //                       <InputAdornment position="start">
  //                         <PersonIcon />
  //                       </InputAdornment>
  //                     ),
  //                   }}
  //                 />

  //                 <TextField
  //                   id="password"
  //                   type="password"
  //                   name="password"
  //                   placeholder="password"
  //                   variant="outlined"
  //                   defaultValue={values.password}
  //                   onChange={handleChange}
  //                   onBlur={handleBlur}
  //                   error={errors.password && touched.password}
  //                   helperText={
  //                     errors.password && touched.password && errors.password
  //                   }
  //                   fullWidth
  //                   className="mb-3"
  //                   style={{ marginBottom: '20px' }}
  //                   InputProps={{
  //                     startAdornment: (
  //                       <InputAdornment position="start">
  //                         <LockIcon />
  //                       </InputAdornment>
  //                     ),
  //                   }}
  //                 />
  //                 <Box
  //                   display="flex"
  //                   justifyContent="space-between"
  //                   alignItems="center"
  //                   marginBottom="20px"
  //                 >
  //                   <Button
  //                     type="submit"
  //                     variant="contained"
  //                     color="primary"
  //                     className="sign-in-button"
  //                     disabled={isSubmitting}
  //                     disableElevation
  //                   >
  //                     Sign In
  //                   </Button>
  //                   <Typography
  //                     marginTop={1}
  //                     sx={{
  //                       fontSize: '1.2rem',
  //                     }}
  //                   >
  //                     <small>
  //                       <Link
  //                         to="/reset"
  //                         className="fw-bold text-decoration-none"
  //                       >
  //                         Forgot Password
  //                       </Link>
  //                     </small>
  //                   </Typography>
  //                 </Box>
  //                 <Typography
  //                   sx={{
  //                     fontSize: '1.2rem',
  //                   }}
  //                   marginTop={1}
  //                 >
  //                   <small>
  //                     New User?{' '}
  //                     <Link
  //                       to="/signup"
  //                       className="fw-bold text-decoration-none"
  //                     >
  //                       Register Here
  //                     </Link>
  //                   </small>
  //                 </Typography>
  //               </form>
  //             </CardContent>
  //           </Card>
  //         </Grid>
  //       </Grid>
  //     </Container>
  //     <Footer />
  //   </>
  // );
};

export default SignIn;
