import { apiSlice } from '../../app/api/baseApi'; 
  
export const paymentApi = apiSlice.injectEndpoints({ 
  endpoints: (builder) => ({ 
    createOrder: builder.mutation({ 
      query: (orderDetails) => ({ 
        url: '/create-order',  
        method: 'POST', 
        body: orderDetails, 
      }), 
    }), 
    verifyPayment: builder.mutation({ 
      query: (paymentDetails) => ({ 
        url: '/verify-payment', 
        method: 'POST', 
        body: paymentDetails,  
      }), 
    }),  
    debitWallet: builder.mutation({
      query: (debitDetails) => ({
        url: '/wallet/debit',
        method: 'POST',
        body: debitDetails,
      }),
    }),
  }), 
}); 
 
export const { useCreateOrderMutation, useVerifyPaymentMutation, useDebitWalletMutation } = paymentApi; 
