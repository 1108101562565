import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import '../../styles/BaseTable.css';

const BaseTable = ({
  columns,
  data,
  total_count,
  pageSize = 1000,
  pageIndex = 0,
  noPaginate = true,
  setPagination,
  getRowProps
}) => {
  // const [{ pageIndex, pageSize }, setPagination] = useState({
  //   // pageIndex: skip ?? 0,
  //   pageIndex: 0,
  //   pageSize: 10,
  // });

  const pagination = useMemo(
    () => ({ pageIndex, pageSize }),
    [pageIndex, pageSize],
  );

  const pageCount = total_count ? Math.ceil(total_count / pageSize) : 1;

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    pageCount: pageCount ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  // useEffect(() => {
  //   setSkip && setSkip(pageSize * pageIndex);
  // }, [pageIndex]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table
        sx={{ overflowX: 'scroll' }}
        className="w-full text-sm text-left text-gray-500 rtl:text-right"
      >
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} scope="col" className="px-6 py-3">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {tableInstance.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              style={getRowProps ? getRowProps(row) : {}}
              className="odd:bg-white even:bg-gray-50 hover:bg-gray-200"
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-6 py-4">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* pagination starts */}
      <Divider />

      {!noPaginate && (
        <nav
          className="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
          aria-label="Table navigation"
        >
          <span className="flex items-center justify-center text-base font-normal text-gray-500 gap-x-3">
            <p>Showing Page</p>
            <span className="font-semibold text-gray-900 ">
              {tableInstance.getState().pagination.pageIndex + 1} of{' '}
              {tableInstance.getPageCount()}
            </span>
            <span className="flex items-center justify-center text-gray-900">
              <p className="mr-2 text-nowrap">| Go to page:</p>
              <input
                type="number"
                id="first_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                defaultValue={tableInstance.getState().pagination.pageIndex + 1}
                disabled={pageCount === 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  tableInstance.setPageIndex(page);
                }}
              />
            </span>
          </span>
          <ul className="inline-flex items-stretch -space-x-px">
            <li>
              <button
                className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:bg-gray-200"
                onClick={() => {
                  tableInstance.setPageIndex(0);
                }}
                disabled={!tableInstance.getCanPreviousPage()}
              >
                <span className="sr-only">First</span>
                <svg
                  className="w-6 h-6 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m17 16-4-4 4-4m-6 8-4-4 4-4"
                  />
                </svg>
              </button>
            </li>
            <li>
              <button
                className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white  border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:bg-gray-200"
                onClick={() => {
                  tableInstance.previousPage();
                }}
                disabled={!tableInstance.getCanPreviousPage()}
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </li>
            <li>
              <button
                className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:bg-gray-200"
                onClick={() => {
                  tableInstance.nextPage();
                }}
                disabled={!tableInstance.getCanNextPage()}
              >
                <span className="sr-only">Next</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </li>
            <li>
              <button
                className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 disabled:bg-gray-200"
                onClick={() => {
                  tableInstance.setPageIndex(tableInstance.getPageCount() - 1);
                }}
                disabled={!tableInstance.getCanNextPage()}
              >
                <span className="sr-only">Last</span>
                <svg
                  className="w-6 h-6 text-gray-500 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m7 16 4-4-4-4m6 8 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default BaseTable;
