import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllAdminItinerariesQuery } from '../../../apis/itineraryApis/itineraryApi';
import ItinearyTable from '../../../components/itinearyTable/ItinearyTable';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../app/store/reducers/authSlice';

const AdminTempletes = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState('');
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userType } = useSelector(selectCurrentUser);

  const { data, isLoading, isFetching, refetch } =
    useGetAllAdminItinerariesQuery(
      {
        keyword: searchParams.get('keyword') || '',
        page: pageIndex,
        limit: pageSize,
      },
      { refetchOnMountOrArgChange: true },
    );

  const handleSearch = () => {
    setSearchParams({ keyword: keyword });
  };

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'}>
          <Typography variant="h5" fontWeight={700}>
            Template Itineraries
          </Typography>
          <button type="submit"
            className="p-1 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            onClick={() => refetch()}
          >
            Refresh
          </button>
        </Box>
        <Box gap={2} display={'flex'}>
          {userType === 'Admin' && (
            <button
              className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
              onClick={() => navigate(pathname + "/create")}
            >
              Create
            </button>
          )}
        </Box>
      </Box>
      <Divider role="presentation" sx={{ marginTop: '0.3rem' }} />
      <br />
      <div className="flex mb-2">
        <input
          type="search"
          id="search-dropdown"
          className="block p-2.5 text-sm text-gray-900 bg-gray-50 rounded-l-lg  border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Search By Quotation ID"
          defaultValue={keyword}
          onChange={handleChange}
        />
        <button
          type="submit"
          className=" p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
          onClick={handleSearch}
        >
          Search
          <span className="sr-only">Search</span>
        </button>
      </div>
      {!isLoading && !isFetching && data?.data.length > 0 && (
        <ItinearyTable
          data={data}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
        />
      )}
    </>
  );
};

export default AdminTempletes;
