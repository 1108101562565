import React, { useMemo } from 'react';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from '../optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { useDeleteLocationMutation } from '../../apis/locationsApi/locationsApi';

const LocationTable = ({ data }) => {
  const navigate = useNavigate();

  const handleRedirect = (locationId) => {
    const viewUrl = `view/${locationId}`;
    navigate(viewUrl);
  };

  const handleEdit = (locationId) => {
    const viewUrl = `edit/${locationId}`;
    navigate(viewUrl);
  };

  const [deleteLocation] = useDeleteLocationMutation();
  const handleDelete = async (Id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this Location?");
    if(confirmDelete){
      await deleteLocation(Id)
      .unwrap()
      .then(() => {
        console.log(`Location with ID ${Id} deleted successfully.`);
      })
      .catch((error) => {
        console.log(`Failed to delete Location with ID ${Id}:`, error);
      });
    }
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row, index) => index + 1, {
      header: '#',
      id: 'serialNumber',
    }),
    columnHelper.accessor('locationName', { header: 'Location' }),
    columnHelper.accessor('country', { header: 'Country' }),
    columnHelper.accessor('status', {
      header: 'status',
      cell: (info) => {
        const statusValue = info.getValue();
        const badgeContent = statusValue === 0 ? 'Active' : 'Inactive';
        const badgeColor = statusValue === 0 ? 'success' : 'error';
        return (
          <Badge color={badgeColor} variant="dot">
            {badgeContent}
          </Badge>
        );
      },
      // cell: (info) => {
      //   return (
      //     <>
      //       <Rating
      //         name="simple-controlled"
      //         value={info.getValue()}
      //         precision={0.5}
      //         readOnly
      //       />
      //     </>
      //   );
      // },
    }),
    columnHelper.accessor('createdAt', { header: 'Created Date' }),
    columnHelper.accessor('_id', {
      header: 'Options',
      cell: (info) => {
        const optionsArr = [
          {
            name: 'view',
            onClickHandler: () => handleRedirect(info.getValue().toString()),
          },
          {
            name: 'edit',
            onClickHandler: () => handleEdit(info.getValue().toString()),
          },
          {
            name: 'delete',
            onClickHandler: () => handleDelete(info.getValue().toString()),
          },
        ];
        return (
          <OptionsDropdown actions={optionsArr}>
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];
  const formateDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  const formatedData = data.data.map((item) => ({
    ...item,
    createdAt: formateDate(item.createdAt),
  }));

  const COLUMNS = useMemo(() => columns, []);

  return (
    <>
      <BaseTable
        data={formatedData}
        total_count={data.count}
        columns={COLUMNS}
      />
    </>
  );
};

export default LocationTable;
