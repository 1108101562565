import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from 'firebase/storage';
import { storage } from '../app/firebase/firebase.config';
import { v4 } from 'uuid';
import Compressor from 'compressorjs';

async function uploadImageToFirebase(image, path) {
  const storageRef = ref(storage, `/${path}/${image.name + v4()}`);
  const response = await uploadBytes(storageRef, image);
  const url = await getDownloadURL(response.ref);
  return url;
}

export async function uploadImages(images, path) {
  const imagePromises = Array.from(images, (image) =>
    uploadImageToFirebase(image, path),
  );

  const imageRes = await Promise.all(imagePromises);
  return imageRes; // list of url like ["https://..", ...]
}

export const compressImage = async (file) => {
  try {
    const compressedBlob = await new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8, // Adjust the desired image quality (0.0 - 1.0)
        maxWidth: 800, // Adjust the maximum width of the compressed image
        maxHeight: 800, // Adjust the maximum height of the compressed image
        mimeType: 'image/jpeg', // Specify the output image format
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    });

    return compressedBlob;
  } catch (error) {
    console.error(error);
    throw new Error('Could not compress image');
  }
};

export async function compressAndUploadImages(images, path) {
  const imagePromises = Array.from(images, async (image) => {
    const blob = await compressImage(image);
    const url = await uploadImageToFirebase(blob, path);
    return url;
  });

  const imageRes = await Promise.all(imagePromises);
  return imageRes; // List of URLs like ["https://..", ...]
}

export const removeImage = async (imageUrl) => {
  try {
    const imageRef = ref(storage, imageUrl); // Create a reference to the image

    // Delete the image
    await deleteObject(imageRef);

    console.log('Image deleted successfully!');
  } catch (error) {
    console.error('Error deleting image:', error);
  }
};
