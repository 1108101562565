import React from 'react';
import {
  useGetMeQuery,
  useUpdateUserProfileMutation,
} from '../../apis/authApis/loginApi';
import { useForm } from 'react-hook-form';
import { compressImage, uploadImages } from '../../helpers/imageUploadHelper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const LABEL_CLASS = 'block mb-2 text-sm font-medium text-gray-900';
const INPUT_CLASS =
  'bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5';
const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const EditProfile = () => {
  const navigate = useNavigate();
  const {
    data: userData,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useGetMeQuery();

  const [updateUserProfile] = useUpdateUserProfileMutation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      first_name: userData.data[0].first_name,
      last_name: userData.data[0].last_name,
      company_name: userData.data[0].company_name,
      company_logo: userData.data[0].company_logo,
      email: userData.data[0].email,
      address: userData.data[0].address,
      city: userData.data[0].city,
      state: userData.data[0].state,
      website: userData.data[0].website,
      whatsapp_number: userData.data[0].whatsapp_number,
      phonenumber: userData.data[0].phonenumber,
      gst_no: userData.data[0].gst_no,
      pan_card_no: userData.data[0].pan_card_no,
      pan_document: userData.data[0].pan_document,
      gst_document: userData.data[0].gst_document,
    },
  });

  // Render based on user data or loading/error state
  if (isLoading) {
    return <p>Loading profile data...</p>;
  }

  if (isError) {
    return <p>Error fetching profile data</p>;
  }

  async function replaceImgWithUrls(blob, formObj, keyName) {
    const imageUrlArr = await uploadImages(blob, 'user');
    formObj[keyName] = imageUrlArr[0];
  }

  const submitHandler = async (body) => {
    console.log('clicked', body);
    const logo =
      typeof body.company_logo !== 'string'
        ? await compressImage(body.company_logo[0])
        : null;
    const pan =
      typeof body.pan_document !== 'string'
        ? await compressImage(body.pan_document[0])
        : null;
    const gst =
      typeof body.gst_document !== 'string'
        ? await compressImage(body.gst_document[0])
        : null;
    logo && (await replaceImgWithUrls([logo], body, 'company_logo'));
    pan && (await replaceImgWithUrls([pan], body, 'pan_document'));
    gst && (await replaceImgWithUrls([gst], body, 'gst_document'));

    await updateUserProfile(body)
      .unwrap()
      .then((res) => {
        toast.success(`Profile Updated Successfully`, TOAST_CONFIG);
        // reset();
        navigate('/profile');
      })
      .catch((err) => {
        toast.error(`Failed! Could not update profile`, TOAST_CONFIG);

        console.error(err);
      });
  };

  const cLogo = watch('company_logo');
  const panImg = watch('pan_document');
  const gstImg = watch('gst_document');

  return (
    <div className="p-2 md:p-4">
      <div className="w-full px-6 pb-8 mt-8 sm:rounded-lg">
        <div className="flex justify-between">
          <h2 className="text-4xl font-bold">Update Profile</h2>
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
        <form action="" method="post" onSubmit={handleSubmit(submitHandler)}>
          <div className="grid mt-8">
            <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">
              <img
                className="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-indigo-300 dark:ring-indigo-500"
                src={
                  typeof cLogo === 'string'
                    ? cLogo
                    : URL.createObjectURL(cLogo[0])
                }
                alt="Company Logo"
              />

              <div className="flex flex-col space-y-5 sm:ml-8">
                <div className="relative">
                  <label
                    title="Click to upload"
                    htmlFor="company_logo"
                    className="flex items-center gap-4 px-6 py-4 cursor-pointer before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                  >
                    <div className="relative">
                      <span className="relative block text-base font-semibold text-blue-900 group-hover:text-blue-500">
                        Change Logo
                      </span>
                    </div>
                  </label>
                  <input
                    type="file"
                    name="company_logo"
                    id="company_logo"
                    {...register('company_logo')}
                    className="hidden"
                  />
                </div>
              </div>
            </div>

            <div className="items-center mt-8 sm:mt-14 text-[#202142]">
              <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                <div className="w-full">
                  <label htmlFor="first_name" className={LABEL_CLASS}>
                    Your first name
                  </label>
                  <input
                    {...register('first_name', { required: true })}
                    type="text"
                    className={INPUT_CLASS}
                    required
                  />
                </div>

                <div className="w-full">
                  <label htmlFor="last_name" className={LABEL_CLASS}>
                    Your last name
                  </label>
                  <input
                    type="text"
                    {...register('last_name', { required: true })}
                    className={INPUT_CLASS}
                    required
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="company_name" className={LABEL_CLASS}>
                    Company Name
                  </label>
                  <input
                    type="text"
                    {...register('company_name', { required: true })}
                    className={INPUT_CLASS}
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="mb-2 sm:mb-6">
                  <label htmlFor="email" className={LABEL_CLASS}>
                    Your email
                  </label>
                  <input
                    {...register('email', { required: true })}
                    type="email"
                    className={INPUT_CLASS}
                    required
                    disabled
                  />
                </div>

                <div className="mb-2 sm:mb-6">
                  <label htmlFor="phonenumber" className={LABEL_CLASS}>
                    Phone
                  </label>
                  <input
                    {...register('phonenumber', { required: true })}
                    type="text"
                    className={INPUT_CLASS}
                    required
                  />
                </div>
                <div className="mb-2 sm:mb-6">
                  <label htmlFor="website" className={LABEL_CLASS}>
                    Website
                  </label>
                  <input
                    {...register('website', { required: true })}
                    type="text"
                    className={INPUT_CLASS}
                    required
                  />
                </div>
                <div className="mb-2 sm:mb-6">
                  <label htmlFor="pan_card_no" className={LABEL_CLASS}>
                    PAN
                  </label>
                  <input
                    {...register('pan_card_no', { required: true })}
                    type="text"
                    className={INPUT_CLASS}
                    required
                  />
                </div>
                <div className="mb-2 sm:mb-6">
                  <label htmlFor="gst_no" className={LABEL_CLASS}>
                    GST No
                  </label>
                  <input
                    {...register('gst_no', { required: true })}
                    type="text"
                    className={INPUT_CLASS}
                    required
                  />
                </div>

                <div className="mb-6">
                  <label htmlFor="message" className={LABEL_CLASS}>
                    Address
                  </label>
                  <textarea
                    rows="4"
                    {...register('address', { required: true })}
                    className="block p-2.5 w-full text-sm text-indigo-900 bg-indigo-50 rounded-lg border border-indigo-300 focus:ring-indigo-500 focus:border-indigo-500 "
                    placeholder="Write your address here..."
                  ></textarea>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="flex flex-col gap-3">
                  <img
                    className="p-1 rounded-lg w-80 h-52 ring-2 ring-indigo-300 dark:ring-indigo-500"
                    src={
                      typeof panImg === 'string'
                        ? panImg
                        : URL.createObjectURL(panImg[0])
                    }
                    alt="PAN"
                  />
                  <div className="relative w-52">
                    <label
                      title="Click to upload"
                      htmlFor="pan_document"
                      className="flex items-center gap-4 px-6 py-4 cursor-pointer before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                    >
                      <div className="relative">
                        <span className="relative block text-base font-semibold text-blue-900 group-hover:text-blue-500">
                          Change PAN
                        </span>
                      </div>
                    </label>
                    <input
                      type="file"
                      name="pan_document"
                      id="pan_document"
                      {...register('pan_document')}
                      className="hidden"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <img
                    className="p-1 rounded-lg w-72 h-80 ring-2 ring-indigo-300 dark:ring-indigo-500"
                    src={
                      typeof gstImg === 'string'
                        ? gstImg
                        : URL.createObjectURL(gstImg[0])
                    }
                    alt="GST"
                  />
                  <div className="relative w-52">
                    <label
                      title="Click to upload"
                      htmlFor="gst_document"
                      className="flex items-center gap-4 px-6 py-4 cursor-pointer before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                    >
                      <div className="relative">
                        <span className="relative block text-base font-semibold text-blue-900 group-hover:text-blue-500">
                          Change GST
                        </span>
                      </div>
                    </label>
                    <input
                      type="file"
                      name="gst_document"
                      id="gst_document"
                      {...register('gst_document')}
                      className="hidden"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="text-white bg-indigo-700  hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
