import { uploadImages } from '../../../../helpers/imageUploadHelper';
import countryData from '../../../data1.json';

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

export const getActivityPrice = (activity, transport, noa, noc) => {
  // console.log('transport: ', transport)
  if (['WOT', 'PT', 'ST'].includes(transport)) {
    let cost;
    if (transport === 'WOT') {
      cost =
        activity.price[transport]['AdultCost'] * noa +
        activity.price[transport]['ChildCost'] * noc;
      return cost;
    } else if (transport === 'ST') {
      cost =
        (activity.price[transport]['AdultCost'] +
          activity.price['WOT']['AdultCost']) *
          noa +
        (activity.price[transport]['ChildCost'] +
          activity.price['WOT']['ChildCost']) *
          noc;
      return cost;
    } else {
      let totalPrice = 0;
      let zerosArray = Array.from({
        length: activity.price.PT.length,
      }).fill(0);
      let maxPassengersArray = activity.price.PT.map((obj) => [
        obj.maxPassengers,
        obj.price,
      ]);
      maxPassengersArray.sort((x, y) => x[0] - y[0]);
      // console.log('max pas arr: ', maxPassengersArray)
      let totalPassengers = noa + noc;
      while (totalPassengers > 0) {
        let i = 0,
          flag = 0;
        while (i < maxPassengersArray.length) {
          if (parseInt(maxPassengersArray[i][0]) >= totalPassengers) {
            zerosArray[i] = zerosArray[i] + 1;
            flag = 1;
            break;
          } else if (i == maxPassengersArray.length - 1) {
            zerosArray[i] = zerosArray[i] + 1;
            break;
          }
          i = i + 1;
        }
        if (flag) {
          totalPassengers = -1;
        } else {
          totalPassengers =
            totalPassengers -
            parseInt(maxPassengersArray[maxPassengersArray.length - 1][0]);
        }
      }
      for (let i = 0; i < maxPassengersArray.length; i++) {
        totalPrice =
          totalPrice + zerosArray[i] * parseInt(maxPassengersArray[i][1]);
      }
      totalPrice =
        totalPrice +
        activity.price['WOT']['AdultCost'] * noa +
        activity.price['WOT']['ChildCost'] * noc;
      // console.log('total price: ', totalPrice)
      return totalPrice;
    }
  }
  return 0;
};

export const addDaysToDate = (inputDate, numDays) => {
  // Splitting the input date string into year, month, and day components
  var parts = inputDate.split('-');
  var year = parseInt(parts[0]);
  var month = parseInt(parts[1]) - 1; // Adjusting month to zero-based index
  var day = parseInt(parts[2]);

  // Creating a Date object with the provided date
  var date = new Date(year, month, day);

  // Adding the specified number of days
  date.setDate(date.getDate() + numDays);

  // Formatting the resulting date as 'yyyy-MM-dd'
  var result =
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2);
  return result;
};

export const replaceImgWithUrls = async (formObj) => {
  const activityImageUrlArr = await uploadImages(formObj.images, 'itineraries');
  formObj.images = activityImageUrlArr;
};

export const getStateAndCity = (country) => {
  const stateArr = countryData.find((c) => c.name === country)?.states || [];
  const cityArr = stateArr[0]?.cities || [];
  return { stateArr, cityArr };
};
