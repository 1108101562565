import React from 'react';
import { Field } from 'formik';
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  Button,
} from '@mui/material';
import CustomSelect from '../../../../components/customSelect/CustomSelect';
import { addDaysToDate } from './utils';

export const ActivityItem = ({
  index,
  idx,
  values,
  activityData,
  dispatch,
  state,
  currencies,
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
    <Grid container spacing={2}>
      <Grid item sm={1} md={4}>
        <Typography fontWeight={700}>
          Activity: {idx + 1} (Day {index + 1}:&nbsp;
          {addDaysToDate(values.tour_start_date, index)})
        </Typography>
        <Field
          className="custom-select"
          name={`days.${index}.${idx}.activityId`}
          options={
            activityData?.data.filter(
              (x) => x.location.toLowerCase() === values.location.toLowerCase(),
            ) || []
          }
          fieldName={'activityName'}
          fieldValue={'_id'}
          component={CustomSelect}
          placeholder="Select Activity"
          isMulti={false}
          custom={true}
          cb={(e) => {
            console.log('first');
            dispatch({
              type: 'CHANGE_SINGLE_DAY',
              payload: { id: e, index, idx, activityData },
            });
            if (state.days[index][idx].transportType) {
              dispatch({
                type: 'CHANGE_TRANSFER',
                payload: {
                  index,
                  idx,
                  transportType: state.days[index][idx].transportType,
                },
              });
            }
          }}
        />
      </Grid>
      <Grid item sm={2} md={3}>
        <Typography fontWeight={700}>Transfer Option</Typography>
        <Field
          className="custom-select"
          name={`days.${index}.${idx}.transportType`}
          fieldName={'name'}
          fieldValue={'type'}
          options={state.days[index][idx]?.activity?.priceTags || []}
          component={CustomSelect}
          placeholder="Select Transfer"
          isMulti={false}
          custom={false}
          cb={(e) => {
            dispatch({
              type: 'CHANGE_TRANSFER',
              payload: { index, idx, transportType: e },
            });
          }}
        />
      </Grid>
      <Grid item sm={2} md={1}>
        <Typography fontWeight={700}>Adults</Typography>
        <Typography fontWeight={700}>
          {state.days[index][idx].numberOfAdults}
        </Typography>
        {/* <FormControl>
          <Field
            className="custom-select"
            as={TextField}
            placeholder="Adult"
            value={state.days[index][idx].numberOfAdults}
            disabled
          />
        </FormControl> */}
      </Grid>
      <Grid item sm={2} md={1}>
        <Typography fontWeight={700}>Childrens</Typography>
        <Typography fontWeight={700}>
          {state.days[index][idx].numberOfChildren}
        </Typography>
        {/* <Field
          className="custom-select"
          as={TextField}
          placeholder="Child"
          disabled
          value={state.days[index][idx].numberOfChildren}
        /> */}
      </Grid>
      <Grid item sm={2} md={1}>
        <Typography fontWeight={700}>Infants</Typography>
        <Typography fontWeight={700}>
          {state.days[index][idx].numberOfInfants}
        </Typography>
        {/* <Field
          className="custom-select"
          as={TextField}
          placeholder="Infant"
          disabled
          value={state.days[index][idx].numberOfInfants}
        /> */}
      </Grid>
      <Grid item sm={2} md={2}>
        <Typography fontWeight={700}>Total Price</Typography>
        <Typography fontWeight={800} display="flex">
          {currencies[values.country][0].code}{' '}
          {parseFloat(state.days?.[index]?.[idx]?.TotalCost || 0.0).toFixed(
            2,
          ) || 0.0}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);
