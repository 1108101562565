import * as React from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {
  useCreditWalletMutation,
  useCreditWalletRechargeMutation,
} from '../../apis/walletApis/walletApi';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UpdateBalance() {
  const [paymentMode, setPaymentMode] = React.useState('Cash Deposit');
  const [amount, setAmount] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [branch, setBranch] = React.useState('');
  const [transactionId, setTransactionId] = React.useState('');
  const [date, setDate] = React.useState('');
  const [remarks, setRemarks] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');

  const [creditWallet, { isLoading }] = useCreditWalletMutation();
  const [creditWalletRecharge] = useCreditWalletRechargeMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (paymentMode === 'Online Recharge') {
      initiateRazorpayPayment();
      return;
    }

    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      setSnackbarMessage('Please enter a valid amount.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await creditWallet({
        amount,
        bankName,
        branch,
        transactionId,
        date,
        remarks,
        paymentMode,
        status: 'Pending',
      }).unwrap();

      setSnackbarMessage('Deposit request submitted successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage(error.message || 'Failed to submit request');
      setSnackbarSeverity('error');
    } finally {
      setOpenSnackbar(true);
      setAmount('');
      setBankName('');
      setBranch('');
      setTransactionId('');
      setRemarks('');
    }
  };

  const initiateRazorpayPayment = () => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: Number(amount) * 100, // Razorpay expects the amount in paise (1 INR = 100 paise)
      currency: "INR",
      name: 'Sky High Tours And Travels',
      description: 'Online Recharge Payment',
      handler: async (response) => {
        try {
          const rechargeResponse = await creditWalletRecharge({
            paymentId: response.razorpay_payment_id,
            order_id: response.razorpay_order_id,
            signature: response.razorpay_signature,
            amount,
            remarks,
          }).unwrap();

          setSnackbarMessage('Payment successful! Wallet updated.');
          setSnackbarSeverity('success');
        } catch (error) {
          setSnackbarMessage(error.message || 'Failed to update wallet balance');
          setSnackbarSeverity('error');
        } finally {
          setOpenSnackbar(true);
        }
      },
      prefill: {
        email: '',
        contact: '',
      },
      notes: {
        remarks,
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 5 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Update Deposit Request for Cash/Bank Transfers
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Once you update the deposit request, please allow us 30 minutes - 1 hour to update your deposit account.
        </Typography>

        <Box sx={{ mb: 3, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
          <Typography variant="h6">Bank Account Information</Typography>
          <Typography>Account Name: Sky High Tours And Travels</Typography>
          <Typography>Account No: 50200068188572</Typography>
          <Typography>IFSC: HDFC0000470</Typography>
          <Typography>UPI: Sewani.aman20-2@okhdfcbank</Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Payment Mode</InputLabel>
                <Select
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  label="Payment Mode"
                >
                  <MenuItem value="Cash Deposit">Cash Deposit</MenuItem>
                  <MenuItem value="Online Recharge">Online Recharge</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Amount"
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
                type="number"
              />
            </Grid>

            {paymentMode === 'Cash Deposit' && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Bank Name"
                    placeholder="Your bank name in which money has been deposited"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Branch"
                    placeholder="Enter branch name"
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Bank Transaction ID"
                    placeholder="Enter Transaction ID"
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="On Date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    required
                  />
                </Grid>
              </>
            )}

            {paymentMode === 'Online Recharge' && (
              <Grid item xs={12}>
                <Typography>Choose a payment method: Credit Card, Debit Card, UPI</Typography>
                <Button variant="contained" color="secondary" onClick={initiateRazorpayPayment}>
                  Proceed to Razorpay
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Remarks (if any)"
                placeholder="Enter any remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Grid>

            {paymentMode !== 'Online Recharge' && (
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
                  {isLoading ? 'Submitting...' : 'Submit Deposit Request'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default UpdateBalance;
