import { apiSlice } from '../../app/api/baseApi';

export const bookingApi = apiSlice.injectEndpoints({
  tagTypes: ['Booking'],
  endpoints: (builder) => ({
    createBooking: builder.mutation({
      query: (body) => ({
        url: '/booking',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Booking'],
    }),

    getSingleBooking: builder.query({
      query: (id) => `/booking/${id}`,
    }),

    getAllBooking: builder.query({
      query: (params) => {
        return {
          url: `/booking`,
          params: { ...params, is_confirmed: true },
        };
      },
      providesTags: ['Booking'],
    }),
    getAllQuotation: builder.query({
      query: (params) => {
        return {
          url: `/booking`,
          params: { ...params, is_confirmed: false },
        };
      },
      providesTags: ['Booking'],
    }),

    deleteBooking: builder.mutation({
      query: (bookingId) => ({
        url: `/booking/${bookingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Booking'],
    }),

    sendBooking: builder.mutation({
      query: (body) => ({
        url: `/confirmBooking`, 
        method: 'POST', 
        body: { ...body }, 
      }),
      invalidatesTags: ['Booking'], 
    }),
 
    confirmQuotation: builder.mutation({ 
      query: (body) => ({
        url: `/confirm-quotation`, 
        method: 'POST', 
        body: { ...body }, 
      }),
      invalidatesTags: ['Booking'], 
    }),

    confirmBooking: builder.mutation({
      query: (body) => ({
        url: `/confirm-booking`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Booking'],
    }),
    confirmPaymentQuotation: builder.mutation({
      query: (body) => ({
        url: '/confirm-payment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['payment'],
    }),
    ConfirmOfflinePayment: builder.mutation({
      query: (body) => ({
        url: '/confirm-offline-payment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['payment'],
    }),
  }),
});

export const {
  useCreateBookingMutation,
  useLazyGetSingleBookingQuery,
  useGetSingleBookingQuery,
  useGetAllBookingQuery,
  useGetAllQuotationQuery,
  useDeleteBookingMutation,
  useSendBookingMutation,
  useConfirmQuotationMutation,
  useConfirmBookingMutation,
  useConfirmPaymentQuotationMutation,
  useConfirmOfflinePaymentMutation,
} = bookingApi;
