import * as yup from "yup";

//validation schema for user authentication
const passwordValidator =
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

const registerSchema = yup.object().shape({
  // firstName: yup.string().required("First Name Required"),
  // lastName: yup.string().required("Last Name Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Address Required"),
  password: yup
    .string()
    .min(8)
    .matches(passwordValidator, {
      message:
        "Password Should Contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
    })
    .required("Password Required"),
});

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Address Required"),
  password: yup
    .string()
    .min(8)
    // .matches(passwordValidator, {
    //   message:
    //     "Password Should Contain Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
    // })
    .required("Password Required"),
});

export { registerSchema, loginSchema };
