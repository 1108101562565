import React, { useMemo } from 'react';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from '../optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { useGetAllLeadsQuery, useUpdateLeadStatusMutation } from '../../apis/leadApis/LeadApi';

const LeadsTable = ({ data }) => {
  const navigate = useNavigate();
  const [updateLeadStatus] = useUpdateLeadStatusMutation();
  const { refetch } = useGetAllLeadsQuery();  

  const columnHelper = createColumnHelper();
  const getBadgeColor = (status) => {
    switch (status) {
      case 'success':
        return 'bg-green-500';
      case 'process':
        return 'bg-yellow-500';
      case 'pending':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };
  const columns = [
    columnHelper.accessor((row, index) => index + 1, {
      header: '#',
      id: 'serialNumber',
    }),
    columnHelper.accessor('leads_no', { header: 'Id' }),
    columnHelper.accessor('destination', { header: 'Destination' }),
    columnHelper.accessor('name', { header: 'Name' }),
    columnHelper.accessor('mobile', { header: 'Mobile' }),
    columnHelper.accessor('email', { header: 'Email' }),
    columnHelper.accessor('adultNo', { header: 'Adults' }),
    columnHelper.accessor('childNo', { header: 'Children' }),
    columnHelper.accessor('checkInDate', { header: 'Check-In Date' }),
    columnHelper.accessor('nights', { header: 'Nights' }),
    columnHelper.accessor('hotelCategory', { header: 'Hotel Category' }),
    columnHelper.accessor('createdAt', { header: 'Created Date' }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => {
        const status = info.getValue();
        const badgeColor = getBadgeColor(status);
        return (
          <span className={`inline-flex items-center px-2 py-1 text-xs font-semibold text-white ${badgeColor} rounded`}>
            {status}
          </span>
        );
      },
    }),
    columnHelper.accessor('_id', {
      header: 'Options',
      cell: (info) => {
        const optionsArr = [
          {
            name: 'Process',
            onClickHandler: () => handleStatusChange(info.row.original._id, 'process'),
          },
          {
            name: 'Success',
            onClickHandler: () => handleStatusChange(info.row.original._id, 'success'),
          },
        ];

        return (
          <OptionsDropdown
            actions={optionsArr.map((option) => ({
              name: option.name,
              onClickHandler: option.onClickHandler,
            }))}
          >
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];

  const handleStatusChange = async (leadId, status) => {
    try {
      await updateLeadStatus({ leadId, status });
      refetch(); 
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const formattedData = data.data.map((item) => ({
    ...item,
    createdAt: formatDate(item.createdAt),
    checkInDate: formatDate(item.checkInDate),
  }));

  const getRowStyle = (status) => {
    switch (status) {
      case 'pending':
        return { backgroundColor: 'yellow' };
      case 'process':
        return { backgroundColor: 'lightblue' };
      case 'success':
        return { backgroundColor: 'lightgreen' };
      default:
        return {};
    }
  };

  const COLUMNS = useMemo(() => columns, []);

  return (
    <>
      <BaseTable
        data={formattedData}
        total_count={data.count}
        columns={COLUMNS}
        getRowProps={(row) => ({
          style: getRowStyle(row.original.status),  
        })}
      />
    </>
  );
};

export default LeadsTable;
