import React, { forwardRef, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import PaymentsIcon from '@mui/icons-material/Payments';
// import PaymentIcon from '@mui/icons-material/Payment';
// import CardTravelIcon from '@mui/icons-material/CardTravel';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAllCurrencies } from '../../app/store/reducers/currencySlice';
import bookingLogo from '../../assets/images/logo.png';
import { selectCurrentUser } from '../../app/store/reducers/authSlice';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const PDFPage = ({
  children,
  isFirstPage = false,
  id = '',
  pax = '',
  name = '',
  companyLogo = '',
  totalAmount = '',
}) => {
  return (
    <Box
      style={{
        width: '100%',
        height: '29.7cm',
        position: 'relative',
        background: 'white',
        outline: '1px dotted black',
      }}
    >
      <div className="flex flex-col justify-between h-full">
        <section>
          {!isFirstPage && (
            <>
              <nav class="bg-white border-gray-200 ">
                <div class="max-w-[700px] flex items-center justify-between mx-auto border-b-black border-b">
                  <div class="flex items-center pb-1 ">
                    <img src={companyLogo} class="h-20" alt="Company Logo" />
                  </div>
                  <div class="block w-auto">
                    <p class="text-blue-700 p-0 text-sm tracking-wider">
                      Proposal ID: {id}
                    </p>
                    <p class="text-blue-700 p-0 text-sm tracking-wider">
                      Pax: {pax}
                    </p>
                    <p class="text-blue-700 p-0 text-sm tracking-wider">
                      Name: {name}
                    </p>
                  </div>
                </div>
              </nav>
            </>
          )}
          <table
            style={{
              margin: '0 auto',
              width: '100%',
              maxWidth: '700px',
              borderSpacing: '0',
            }}
          >
            {children}
          </table>
        </section>
        {!isFirstPage && (
          <footer className="grid grid-cols-12 max-w-[700px] mx-auto ">
            <p className="col-span-8 text-lg font-bold text-red-500 sm:text-sm">
              Tour prices may vary with currency exchange rates at the time of
              booking.
            </p>
            {/* <p className="col-span-4 text-right text-blue-500">{totalAmount}</p> */}
          </footer>
        )}
      </div>
    </Box>
  );
};

const truncateDescription = (text, worldLimit) => {
  const words = text.split(' ');
  if (words.length > worldLimit) {
    return words.slice(0, worldLimit).join(' ') + '...';
  }
  return text;
};

const PreviewComponent = forwardRef(({ children, previewData }, ref) => {
  const { companyLogo } = useSelector(selectCurrentUser);

  const currencies = useSelector(getAllCurrencies);
  const pageStyle = '@page { size: A4 portrait; }';
  const currencyCode = currencies[previewData.itineraryInfo.country][0].code;
  const totalPax =
    previewData.number_of_children +
    previewData.number_of_adults +
    previewData.number_of_infants;
  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    // Add the correct ordinal suffix to the day
    const dayWithSuffix =
      day +
      (day % 10 === 1 && day !== 11
        ? 'st'
        : day % 10 === 2 && day !== 12
          ? 'nd'
          : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th');

    return `${dayWithSuffix} ${month}, ${year}`;
  }

  const formDuration = (duration) => {
    const days = parseInt(duration) + 1;
    const nights = days - 1;
    return `${days} days ${nights} nights`;
  };

  return (
    <Box
      sx={{ overflowY: 'scroll', height: '90vh', width: '60vw', padding: 6 }}
    >
      {previewData?.itineraryName && (
        <div ref={ref}>
          {/* new Page */}
          <style>{pageStyle}</style>
          <PDFPage isFirstPage={true}>
            <tr>
              <td style={{ textAlign: 'center' }}>
                <img src={bookingLogo} alt="logo" width="100%" />
              </td>
            </tr>
            <tr>
              <td style={{ height: '700px', position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <img
                    src={previewData.itineraryInfo.images[0] || ''}
                    alt={previewData.itineraryName || ''}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div
                  style={{
                    position: 'relative',
                    paddingLeft: '80px',
                    letterSpacing: '7px',
                  }}
                >
                  <h6
                    style={{
                      fontSize: '45px',
                      marginTop: '0%',
                      marginBottom: '0%',
                      color: '#e5f6ff',
                    }}
                  >
                    {previewData.itineraryInfo.location || ''}
                  </h6>
                  <span
                    style={{
                      fontSize: '45px',
                      color: '#e5f6ff',
                      fontWeight: '600',
                    }}
                  >
                    {previewData.itineraryInfo.days.length - 1 || 0}N/
                    {previewData.itineraryInfo.days.length || 0}D
                  </span>
                </div>
              </td>
            </tr>
          </PDFPage>
          {/* new Page */}
          <PDFPage
            id={previewData.bill_no}
            name={previewData.bookingName || previewData.itineraryName}
            pax={`${previewData.number_of_adults}Adult ${previewData.number_of_children}Child ${previewData.number_of_infants}Infant`}
            companyLogo={companyLogo}
            totalAmount={`${currencyCode} ${previewData.total_amount} | ${totalPax}`}
          >
            <tr>
              <td className="text-center">
                <h6 className="text-4xl mt-0 mb-[5%] font-bold pt-10">
                  Package Summary
                </h6>
              </td>
            </tr>
            <tr className="w-full">
              <td className="inline-block w-full mt-5">
                <table className="w-full">
                  <tbody className="text-2xl">
                    <tr>
                      <td>
                        Travel Date -{' '}
                        {formatDate(
                          new Date(
                            previewData.tour_start_date,
                          ).toLocaleDateString('en-CA', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }),
                        )}{' '}
                        to{' '}
                        {formatDate(
                          new Date(
                            previewData.tour_end_date,
                          ).toLocaleDateString('en-CA', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="pt-5">
                        No. Of Nights - {formDuration(previewData.duration)}
                      </td>
                    </tr>
                    <tr>
                      <td className="pt-5">
                        Group - {previewData.number_of_adults} Adults{' '}
                        {previewData.number_of_children} Children{' '}
                        {previewData.number_of_infants} Infants
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="pt-5">Sector - 4N Seminyak 3N Ubud</td>
                    </tr> */}
                    {/* <tr>
                      <td className="pt-5">No. Of Rooms - 03 Package</td>
                    </tr> */}
                    <tr>
                      {/* <td className="pt-5">
                        Package Cost{' '}
                        {currencies[previewData.itineraryInfo.country][0].code}
                        &nbsp;
                        {previewData.total_amount}
                      </td> */}
                    </tr>
                    <tr>
                      <td className="pt-5">
                        Visa:{' '}
                        {previewData.visaStatus ? 'Included' : 'Not Included'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </PDFPage>

          <PDFPage
            id={previewData.bill_no}
            name={previewData.bookingName || previewData.itineraryName}
            pax={`${previewData.number_of_adults}Adult ${previewData.number_of_children}Child ${previewData.number_of_infants}Infant`}
            companyLogo={companyLogo}
            totalAmount={`${currencyCode} ${previewData.total_amount} | ${totalPax}`}
          >
            <Typography
              variant="h5"
              fontWeight={'bold'}
              textAlign={'center'}
              mt={3}
              style={{ marginBottom: '30px' }}
            >
              Inclusions
            </Typography>
            <ul className="px-4 list-disc">
              {previewData.itineraryInfo.days.map((day, index) => (
                <li>
                  <strong> {index + 1}:</strong>{' '}
                  {day
                    .map((d) => {
                      let transportLabel;
                      switch (d.transportType) {
                        case 'PT':
                          transportLabel = 'Private Transfer';
                          break;
                        case 'WOT':
                          transportLabel = 'Without Transfer';
                          break;
                        case 'ST':
                          transportLabel = 'Shared Transfer';
                          break;
                        default:
                          transportLabel = 'Transfer';
                      }
                      return `${d.activityName} (${transportLabel})`;
                    })
                    .join(', ')}
                </li>
              ))}
            </ul>
          </PDFPage>

          <PDFPage
            id={previewData.bill_no}
            name={previewData.bookingName || previewData.itineraryName}
            pax={`${previewData.number_of_adults}Adult ${previewData.number_of_children}Child ${previewData.number_of_infants}Infant`}
            companyLogo={companyLogo}
            totalAmount={`${currencyCode} ${previewData.total_amount} | ${totalPax}`}
          >
            <Typography
              variant="h5"
              fontWeight={'bold'}
              textAlign={'center'}
              mt={3}
            >
              Activity Details
            </Typography>

            {/* Full table structure with rows and columns */}
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginTop: '20px',
                border: '1px solid black',
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      border: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#f2f2f2',
                      textAlign: 'center',
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      border: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#f2f2f2',
                      textAlign: 'center',
                    }}
                  >
                    Day
                  </th>
                  <th
                    style={{
                      border: '1px solid black',
                      padding: '8px',
                      backgroundColor: '#f2f2f2',
                      textAlign: 'center',
                    }}
                  >
                    Activity Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {previewData.itineraryInfo.days.map((day, index) => {
                  const activityDate = new Date(previewData.tour_start_date);
                  activityDate.setDate(activityDate.getDate() + index);
                  return day.map((d, activityIndex) => (
                    <tr key={activityIndex}>
                      {/* Date and Day only appear once per day */}
                      {activityIndex === 0 && (
                        <>
                          <td
                            rowSpan={day.length}
                            style={{
                              border: '1px solid black',
                              padding: '8px',
                              textAlign: 'center',
                            }}
                          >
                            {formatDate(activityDate)}
                          </td>
                          <td
                            rowSpan={day.length}
                            style={{
                              border: '1px solid black',
                              padding: '8px',
                              textAlign: 'center',
                            }}
                          >
                            Day {index + 1}
                          </td>
                        </>
                      )}
                      <td
                        style={{
                          border: '1px solid black',
                          padding: '8px',
                          textAlign: 'left',
                        }}
                      >
                        {d.activityName}
                      </td>
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          </PDFPage>
          
         
          {previewData.itineraryInfo.days.map((day, dayIndex) => (
            <React.Fragment key={dayIndex}>
              <PDFPage
                key={dayIndex}
                id={previewData.bill_no}
                name={previewData.bookingName || previewData.itineraryName}
                pax={`${previewData.number_of_adults}Adult ${previewData.number_of_children}Child ${previewData.number_of_infants}Infant`}
                companyLogo={companyLogo}
                totalAmount={`${currencyCode} ${previewData.total_amount} | ${totalPax}`}
              >
                {/* Header */}
                <tr style={{ padding: '20px 0' }}>
                  <td
                    colSpan={2}
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {dayIndex === 0 ? (
                      <>
                        Day {dayIndex + 1} - Arrival in{' '}
                        {previewData.itineraryInfo.location} &{' '}
                        {day[0]?.activityName || ''}
                      </>
                    ) : (
                      <>
                        Day {dayIndex + 1} - {day[0]?.activityName || ''}
                      </>
                    )}
                  </td>
                </tr>

                {/* Description */}
                <tr>
                  <td
                    colSpan={2}
                    style={{ padding: '10px', verticalAlign: 'top' }}
                  >
                    <table>
                      <tr>
                        <td>
                          <table>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    fontSize: '16px',
                                    lineHeight: '1.5em',
                                  }}
                                >
                                  {dayIndex === 0 ? (
                                    <>
                                      <h4
                                        style={{
                                          fontSize: '17px',
                                          fontWeight: 'bold',
                                          marginBottom: '14px',
                                        }}
                                      >
                                        Description :
                                      </h4>
                                      <p>
                                        Start on an incredible{' '}
                                        {previewData.itineraryInfo.location}{' '}
                                        journey as you arrive at the airport.
                                        Complete immigration and visa processes,
                                        then enjoy a comfortable transfer to
                                        your hotel. After check-in, indulge in
                                        the renowned Balinese Massage, known for
                                        its soothing and rejuvenating effects.
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <h4
                                        style={{
                                          fontSize: '18px',
                                          fontWeight: 'bold',
                                          marginBottom: '8px',
                                        }}
                                      >
                                        Description :
                                      </h4>
                                      <p>
                                        {truncateDescription(
                                          day[0]?.description ||
                                            'No description available',
                                          50,
                                        )}
                                      </p>
                                    </>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                {/* Image */}
                <tr>
                  <td
                    width="50%"
                    style={{ padding: '10px', textAlign: 'center' }}
                  >
                    <img
                      src={
                        day[0]?.images?.[0] ||
                        'https://via.placeholder.com/595x337'
                      }
                      alt=""
                      style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '337px',
                        objectFit: 'cover',
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan={2} style={{ paddingTop: '20px' }}>
                    <h4
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginBottom: '8px',
                      }}
                    >
                      Tour Inclusions:
                    </h4>
                    <p style={{ marginBottom: '16px' }}>
                      {day[0]?.tourInclusions || 'No Tour Inclusions available'}
                    </p>
                  </td>
                </tr>
              </PDFPage>
              {day.length > 1 &&
                day.slice(1).map((activity, activityIndex) => (
                  <PDFPage
                    key={activityIndex}
                    id={previewData.bill_no}
                    name={previewData.bookingName || previewData.itineraryName}
                    pax={`${previewData.number_of_adults}Adult ${previewData.number_of_children}Child ${previewData.number_of_infants}Infant`}
                    companyLogo={companyLogo}
                    totalAmount={`${currencyCode} ${previewData.total_amount} | ${totalPax}`}
                  >
                    <tr style={{ padding: '20px 0' }}>
                      <td
                        colSpan={2}
                        style={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        <h4
                          style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            marginBottom: '8px',
                          }}
                        >
                          Other Activity
                        </h4>
                        {activity?.activityName || 'No activity name available'}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width="50%"
                        style={{ padding: '10px', verticalAlign: 'top' }}
                      >
                        <table>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: '16px',
                                  lineHeight: '1.5em',
                                }}
                              >
                                <h4
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                  }}
                                >
                                  Description:
                                </h4>
                                <p>
                                  {truncateDescription(
                                    activity.description ||
                                      'No description available',
                                    50,
                                  )}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      {/* Image */}
                      <td
                        width="50%"
                        style={{ padding: '10px', textAlign: 'center' }}
                      >
                        <img
                          src={
                            activity.images?.[0] ||
                            'https://via.placeholder.com/595x337'
                          }
                          alt=""
                          style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '337px',
                            objectFit: 'cover',
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ paddingTop: '20px', marginBottom: '8px' }}
                      >
                        <h4
                          style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            marginBottom: '8px',
                          }}
                        >
                          Tour Inclusions:{' '}
                        </h4>
                        <p style={{ marginBottom: '16px' }}>
                          {activity.tourInclusions ||
                            'No Tour Inclusions available.'}
                        </p>

                        {/* <h4 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>What to Expect :</h4>
                        <p style={{ marginBottom: '16px' }}>{activity.WhattoExpect || 'No details available.'}</p> */}
                      </td>
                    </tr>
                  </PDFPage>
                ))}
            </React.Fragment>
          ))}
          {/* new Page */}
          {/* <Box
            style={{
              width: '100%',
              padding: '3rem',
              height: '29.7cm',
              position: 'relative',
              background: 'white',
              outline: '1px solid black',
            }}
          >
            <Typography variant="h5" fontWeight={'bold'} textAlign={'center'}>
              Package Cost
            </Typography>
            <Typography variant="h6" mt={3}>
              Travel Date :{' '}
              {new Date(previewData.tour_start_date).toLocaleDateString(
                'en-CA',
                { year: 'numeric', month: '2-digit', day: '2-digit' },
              )}{' '}
              to{' '}
              {new Date(previewData.tour_end_date).toLocaleDateString('en-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            </Typography>
            <br />
            <Typography variant="h6">
              No of Nights : {formDuration(previewData.duration)}
            </Typography>
            <br />
            <Typography variant="h6">
              Adults : {previewData.number_of_adults} &nbsp; Children :{' '}
              {previewData.number_of_children} &nbsp; Infants :{' '}
              {previewData.number_of_infants}
            </Typography>
            <br />
            <Typography variant="h6">
              Total Package Cost:{' '}
              {currencies[previewData.itineraryInfo.country][0].code}
              {previewData.total_amount}
            </Typography>
            <Typography variant="h6">
              Visa: {previewData.visaStatus ? 'Included' : 'Not Included'}
            </Typography>
            <Typography
              variant="h5"
              fontWeight={'bold'}
              textAlign={'center'}
              mt={3}
            >
              Ticket Availability
            </Typography>
            <ul className="list-disc">
              <li className="mt-2 text-lg font-medium">
                Passengers are required to carry their own masks and hand
                sanitizer for regular use.
              </li>
              <li className="mt-2 text-lg font-medium">
                SIC Transfers are dependent on the arrival time.
              </li>
              <li className="mt-2 text-lg font-medium">
                The itinerary may change if tickets are not available for the
                specified dates.
              </li>
            </ul>
          </Box> */}
          {previewData.visaStatus === true && previewData.visa && (
            <>
              {/* new Page */}

              <PDFPage
                id={previewData.bill_no}
                name={previewData.bookingName || previewData.itineraryName}
                pax={`${previewData.number_of_adults}Adult ${previewData.number_of_children}Child ${previewData.number_of_infants}Infant`}
                companyLogo={companyLogo}
                totalAmount={`${currencyCode} ${previewData.total_amount} | ${totalPax}`}
              >
                <Typography
                  variant="h5"
                  fontWeight={'bold'}
                  textAlign={'center'}
                  mt={3}
                >
                  Visa
                </Typography>
                <Typography variant="h6" fontWeight={'bold'} mt={3}>
                  Checklist
                </Typography>
                <>
                  <ul className="list-disc">
                    {previewData.visa.checklist.map((exc, index) => (
                      <li className="mt-2 text-lg font-medium" key={index}>
                        {exc}
                      </li>
                    ))}
                  </ul>
                </>
                <Typography variant="h6" fontWeight={'bold'} mt={3}>
                  Important Notes
                </Typography>
                <>
                  <ul className="list-disc">
                    {previewData.visa.note.map((exc, index) => (
                      <li className="mt-2 text-lg font-medium" key={index}>
                        {exc}
                      </li>
                    ))}
                  </ul>
                </>
              </PDFPage>
            </>
          )}

          {/* new Page */}

          <PDFPage
            id={previewData.bill_no}
            name={previewData.bookingName || previewData.itineraryName}
            pax={`${previewData.number_of_adults}Adult ${previewData.number_of_children}Child ${previewData.number_of_infants}Infant`}
            companyLogo={companyLogo}
            totalAmount={`${currencyCode} ${previewData.total_amount} | ${totalPax}`}
          >
            <Typography
              variant="h5"
              fontWeight={'bold'}
              textAlign={'center'}
              mt={3}
            >
              Exclusions
            </Typography>
            {previewData.exclusion.length > 0 ? (
              <>
                <ul className="px-4 list-disc">
                  {previewData.exclusion.map((exc, index) => (
                    <li className="mt-2 text-base font-medium" key={index}>
                      {exc}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <ul className="list-disc">
                <li className="mt-2 text-base font-medium">
                  XE+ 1 RS + 1% Handling Charges
                </li>
                <li className="mt-2 text-base font-medium">
                  Please ensure the guest completes the Singapore Arrival Card
                  online form before travel. It is mandatory. The form can be
                  accessed via the following link:
                  <Link
                    href="https://www.ica.gov.sg/enter-transit-depart/entering-singapore/sg-arrival-card"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Singapore Arrival Card Form
                  </Link>
                </li>
              </ul>
            )}
            <Typography
              variant="h5"
              fontWeight={'bold'}
              textAlign={'center'}
              mt={3}
            >
              Document Required:
            </Typography>
            {previewData.document_required.length > 0 ? (
              <>
                <ul className="px-4 list-disc">
                  {previewData.document_required.map((doc, index) => (
                    <li className="mt-2 text-base font-medium" key={index}>
                      {doc}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <ul className="list-disc">
                <li className="mt-2 text-base font-medium">
                  The following documents must be submitted upon booking
                  confirmation:
                </li>
                <li className="mt-2 text-base font-medium">
                  A copy of PAN card for each passenger
                </li>
                <li className="mt-2 text-base font-medium">
                  A copy of Passport for each passenger
                </li>
                <li className="mt-2 text-base font-medium">
                  A copy of Visa/Ticket for each passenger
                </li>
              </ul>
            )}
          </PDFPage>

          <PDFPage
            id={previewData.bill_no}
            name={previewData.bookingName || previewData.itineraryName}
            pax={`${previewData.number_of_adults}Adult ${previewData.number_of_children}Child ${previewData.number_of_infants}Infant`}
            companyLogo={companyLogo}
            totalAmount={`${currencyCode} ${previewData.total_amount} | ${totalPax}`}
          >
            <Typography variant="h5" fontWeight={'bold'} textAlign={'center'}>
              Important Note:
            </Typography>
            {previewData.important_note.length > 0 ? (
              <>
                <ul className="px-4 list-disc">
                  {previewData.important_note.map((note, index) => (
                    <li className="mt-2 text-base font-medium" key={index}>
                      {note}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <ul className="list-disc">
                <li className="mt-2 text-base font-medium">
                  Any special requests such as adjacent rooms, interconnecting
                  rooms, rooms on the same floor, 1 king-size/1 double bed,
                  early check-in, late check-out, non-smoking rooms, etc., are
                  subject to availability upon guest check-in and at the hotel's
                  discretion. We will prioritize these requests but cannot
                  guarantee them at any point.
                </li>

                <li className="mt-2 text-base font-medium">
                  Thank you for your attention to these details. Should you have
                  any questions or require further assistance, please do not
                  hesitate to contact us.
                </li>
              </ul>
            )}
            <br />
            <Typography
              variant="h5"
              fontWeight={'bold'}
              textAlign={'center'}
              mt={4}
            >
              Terms & Conditions:
            </Typography>
            {previewData.terms_n_conditions?.length > 0 ? (
              <ul className="px-4 list-disc">
                {previewData.terms_n_conditions.map((term, index) => (
                  <li className="mt-2 text-base font-medium" key={index}>
                    {term}
                  </li>
                ))}
              </ul>
            ) : (
              <ul className="list-disc">
                <li>
                  The above rates are valid for a minimum of 2 passengers
                  traveling together.
                </li>
                <li>
                  The above rates are not applicable during exhibitions or peak
                  dates.
                </li>
                <li>Eatables are strictly prohibited in vehicles.</li>
                <li>
                  Passengers are required to carry their own masks and hand
                  sanitizer for regular use.
                </li>
                <li>
                  Room occupancy and park capacity for attractions are subject
                  to availability.
                </li>
                <li>
                  Cancellation of attractions is not possible once issued, as
                  all are dated and must be pre-booked.
                </li>
                <li>
                  Please note that the above rates are net, non-commissionable,
                  and applicable only for our B2B travel partners.
                </li>
                <li>
                  Passengers are required to carry their own masks and hand
                  sanitizer for regular use.
                </li>
              </ul>
            )}
          </PDFPage>
          <PDFPage isFirstPage>
            <tr
              style={{
                marginBottom: '3%',
                display: 'inline-block',
                verticalAlign: 'top',
                borderSpacing: '20px',
                marginTop: '7%',
                alignItems: 'center',
              }}
            >
              <td>
                <table width="100%">
                  <tr>
                    <td style={{ width: '40%' }}>
                      <table>
                        <tbody style={{ fontWeight: '600' }}>
                          <tr>
                            <td>
                              <img
                                src={bookingLogo}
                                alt="SkyHighMinds"
                                className="w-full"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                <table width="100%">
                  <tr>
                    <td style={{ width: '60%' }}>
                      <table>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontSize: '20px',
                                fontWeight: 600,
                                textDecoration: 'underline',
                                paddingBottom: '20px',
                              }}
                            >
                              THANK YOU
                            </td>
                          </tr>
                          <tr style={{ fontSize: '14px', fontWeight: 600 }}>
                            <td style={{ paddingTop: '20px' }}>
                              Address 7, Ramtekri , Mandsaur (MP) 458001 India
                            </td>
                          </tr>
                          <tr style={{ fontSize: '14px', fontWeight: 600 }}>
                            <td style={{ paddingTop: '20px' }}>
                              Mobile +91 8889619888 +918871444228
                            </td>
                          </tr>
                          <tr style={{ fontSize: '14px', fontWeight: 600 }}>
                            <td style={{ paddingTop: '20px' }}>
                              Email - himanshu@skyhighmds.com
                            </td>
                          </tr>
                          <tr style={{ fontSize: '14px', fontWeight: 600 }}>
                            <td style={{ paddingTop: '20px' }}>
                              Website - www.bookonabudget.com
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </PDFPage>
        </div>
      )}
    </Box>
  );
});

const BookingPreview = ({ data: previewData }) => {
  const componentRef = useRef();
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: previewData.itineraryName || 'Tour',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    removeAfterPrint: true,
  });
  

  return (
    <>
      <Box display={'flex'} justifyContent={'center'} width={'100%'}>
        <Button variant="contained" onClick={handlePrint} sx={{ marginTop: 4 }}>
          Download
        </Button>
      </Box>
      <PreviewComponent ref={componentRef} previewData={previewData} />
    </>
  );
};

export default BookingPreview;
