import { apiSlice } from "../../app/api/baseApi";

export const activityApi = apiSlice.injectEndpoints({
  tagTypes: ["Activity"],
  endpoints: (builder) => ({
    //for create hotel
    createActivity: builder.mutation({
      query: (body) => ({
        url: "/activities",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["Activity"],
    }),
    //to get activity by ID
    getActivityById: builder.query({
      query: (activityId) => `/activities/${activityId}`,
      providesTags: (result, error, activityId) => [
        {
          type: "Activity",
          id: `${activityId}`,
        },
      ],
    }),
    
    //to get all activity
    getAllActivities: builder.query({
      query: (params) => {
        return {
          url: `/activities`,
          params: { ...params },
        };
      },
      providesTags: ["Activity"],
    }),
    // to delete activity by id
    deleteActivity: builder.mutation({
      query: (activityId) => ({
        url: `/activities/${activityId}`,
        method: "DELETE", 
      }),
      // You can invalidate the cache when a hotel is deleted 
      invalidatesTags: ["Activity"], 
    }),
    // to get an update activity by id
    updateActivity: builder.mutation({
      query: ({ activityId, body }) => ({
        url: `/activities/${activityId}`,
        method: "PUT",  
        body: {...body},
      }),
      invalidatesTags: ["Activity"],
    }),
  }),
});

export const {
  useCreateActivityMutation,
  useGetActivityByIdQuery,
  useGetAllActivitiesQuery,
  useLazyGetAllActivitiesQuery,
  useDeleteActivityMutation,
  useUpdateActivityMutation,
} = activityApi;
