import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({ 
  name: "auth", 
  initialState: { 
    user: null, 
    userType: null, 
    token: { accessToken: null, refreshToken: null }, 
  }, 
  reducers: { 
    setCredentials: (state, action) => { 
      const user = action.payload; 
      state.user = user; 
    },
    setUserType: (state, action) => { 
      state.userType = action.payload.userType; 
    },
    setToken: (state, action) => { 
      state.token.accessToken = action.payload.accessToken; 
      state.token.refreshToken = action.payload.refreshToken; 
    },
    logout: (state, action) => { 
      state.user = null; 
      state.token = { accessToken: null, refreshToken: null }; 
    },
  },
});

export const { setCredentials, setUserType, setToken, logout } =
  authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user; 
export const selectCurrentUserType = (state) => state.auth.userType; 
export const selectCurrentToken = (state) => state.auth.token; 
