import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Box,
  TextField,
  Card,
  CardContent,
  CardActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Grid,
} from '@mui/material';
import LeadGenrationHeader from '../../../components/LeadGenerationHead/LeadGenrationHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Navbar from '../../../components/LeadGenerationHead/Navbar';
import Footer from '../../../components/footer/Footer';
import { useCreateLeadsMutation } from '../../../apis/leadApis/LeadApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TOAST_CONFIG } from '../../dashboard/itineraries/createItineraries/utils';

const Dubai = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [formValues, setFormValues] = useState({
    name: '',
    mobile: '',
    email: '',
    adultNo: '',
    childNo: '',
    checkInDate: '',
    nights: '',
    hotelCategory: '',
  });

  const [createLeads, { isLoading }] = useCreateLeadsMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const leadData = {
        name: formValues.name,
        mobile: formValues.mobile,
        email: formValues.email,
        adultNo: formValues.adultNo,
        childNo: formValues.childNo,
        checkInDate: formValues.checkInDate,
        nights: formValues.nights,
        hotelCategory: formValues.hotelCategory,
      };
      await createLeads(leadData).unwrap();
      toast.success('Query successfully submitted!', TOAST_CONFIG);
    } catch (error) {
      toast.error('Failed to submit query. Try again!', TOAST_CONFIG);
    }
  };

  return (
    <>
      <LeadGenrationHeader />
      <Box display="flex" flexDirection="column" p={2}>
        <Navbar />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom>
              {'About Dubai'}
            </Typography>
            <Typography variant="body1" paragraph>
              {
                'Dubai is a vibrant city known for its futuristic skyline, luxury shopping, and world-class attractions. Located in the United Arab Emirates, it is home to iconic landmarks like the Burj Khalifa, the tallest building in the world, and the Palm Jumeirah, an artificial island. Dubai blends modernity with rich cultural heritage, offering experiences ranging from desert safaris and traditional souks to cutting-edge architecture and gourmet dining. The city is also a global business hub, attracting tourists and professionals from around the world.'
              }
            </Typography>
            <Typography variant="h4" gutterBottom>
              {'About Dubai Tours'}
            </Typography>
            <Typography variant="body1" paragraph>
              {
                "Dubai Tours provides curated experiences that showcase the best of Dubai. Our tours are designed to cater to various interests, including cultural excursions, shopping sprees, and adventure activities. With experienced guides and a focus on customer satisfaction, we ensure that your visit to Dubai is both enjoyable and memorable. Whether you're a first-time visitor or a seasoned traveler, Dubai Tours offers something for everyone."
              }
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ width: '100%', p: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {'Let’s Plan A Trip'}
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    value={formValues.mobile}
                    onChange={handleInputChange}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="No of Adults"
                    name="adultNo"
                    type="number"
                    value={formValues.adultNo}
                    onChange={handleInputChange}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="No of Children"
                    name="childNo"
                    type="number"
                    value={formValues.childNo}
                    onChange={handleInputChange}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Check-in Date"
                    type="date"
                    name="checkInDate"
                    value={formValues.checkInDate}
                    onChange={handleInputChange}
                    margin="normal"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    label="No of Nights"
                    name="nights"
                    type="number"
                    value={formValues.nights}
                    onChange={handleInputChange}
                    margin="normal"
                    required
                  />
                  <FormControl fullWidth margin="normal" required>
                    <InputLabel>Hotel Category</InputLabel>
                    <Select
                      name="hotelCategory"
                      value={formValues.hotelCategory}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="3">3 Star</MenuItem>
                      <MenuItem value="4">4 Star</MenuItem>
                      <MenuItem value="5">5 Star</MenuItem>
                    </Select>
                  </FormControl>
                  <CardActions>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isLoading}
                    >
                      {isLoading ? 'Submitting...' : 'Plan My Trip'}
                    </Button>
                  </CardActions>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default Dubai;
