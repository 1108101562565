import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const HotPackage = () => {
  return (
    <p className="text-4xl font-semibold text-gray-700">
        Featured Packages
    </p> 
  );
};

export default HotPackage;
