import React, { useState } from 'react';
import { Box, Button, Modal, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const BookingModalOffline = ({ open, handleClose, handleConfirm }) => {
  const [paidAmount, setPaidAmount] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [paymentName, setPaymentName] = useState('');
  const [remarks, setRemarks] = useState('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const handleConfirmClick = () => {
    const bookingData = {
      paidAmount: parseFloat(paidAmount),
      paymentMode,
      paymentName,
      remarks,
    };
    handleConfirm(bookingData);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Offline Booking
        </Typography>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="Paid Amount"
            variant="outlined"
            fullWidth
            value={paidAmount}
            onChange={(e) => setPaidAmount(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="payment-mode-label">Payment Mode</InputLabel>
            <Select
              labelId="payment-mode-label"
              value={paymentMode}
              onChange={(e) => setPaymentMode(e.target.value)}
              label="Payment Mode"
            >
              <MenuItem value="NFT">NFT</MenuItem>
              <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
              <MenuItem value="Cash">Cash</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Bank Name"
            variant="outlined"
            fullWidth
            value={paymentName}
            onChange={(e) => setPaymentName(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Remarks"
            variant="outlined"
            fullWidth
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            margin="normal"
          />
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirmClick}>
            Confirm Booking
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BookingModalOffline;
