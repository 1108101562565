import React, { useMemo } from 'react';
import BaseTable from '../baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';

const getBadgeColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'bg-green-500';
    case 'Pending':
      return 'bg-yellow-500';
    case 'Failed':
      return 'bg-red-500';
    default:
      return 'bg-yellow-500';
  }
};

const AgentWiseWalletTable = ({ data }) => {
  // console.log(data);
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row, index) => index + 1, {
      header: '#',
      id: 'serialNumber',
    }),
    columnHelper.accessor('transactionId', {
      header: 'Transaction ID',
    }),
    columnHelper.accessor('amount', {
      header: 'Amount',
      cell: (info) => `${info.getValue()}`,
    }),
    columnHelper.accessor('bankName', {
      header: 'Bank Name',
    }),
    columnHelper.accessor('branch', {
      header: 'Branch',
    }),
    columnHelper.accessor('date', {
      header: 'Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    }),
    columnHelper.accessor('remarks', {
      header: 'Remarks',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (info) => {
        const status = info.getValue();
        const badgeColor = getBadgeColor(status);
        return (
          <div className={`p-2 rounded ${badgeColor}`}>
            <span className="text-white">{status}</span>
          </div>
        );
      },
    }),
    // columnHelper.accessor('transactionId', {
    //   header: 'Options',
    //   cell: (info) => {
    //     const transactionId = info.getValue();
    //     const optionsArr = [];
    //     return (
    //       <OptionsDropdown
    //         actions={optionsArr.map((option) => ({
    //           name: option.name,
    //           onClickHandler: () => {
    //             console.log(`Update transaction ID: ${transactionId}`);
    //           },
    //         }))}
    //       >
    //         <MoreVertIcon />
    //       </OptionsDropdown>
    //     );
    //   },
    // }),
  ];

  const COLUMNS = useMemo(() => columns, []);

  return <BaseTable data={data} total_count={data.length} columns={COLUMNS} />;
};

export default AgentWiseWalletTable;
