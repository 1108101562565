import React, { useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useSelector } from 'react-redux';
import {
  selectCurrentUser,
  selectCurrentToken,
} from '../../app/store/reducers/authSlice';
import {
  useCreateBookingMutation,
  useSendBookingMutation,
} from '../../apis/bookingApis/bookingApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ModuleHeader from '../moduleHeader/ModuleHeader';
import HotelTimeline from '../hotelTimeline/HotelTimeline';
import ActivityTimeline from '../activityTimeline/ActivityTimeline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Controller, useForm } from 'react-hook-form';
import { getAllCurrencies } from '../../app/store/reducers/currencySlice';
import {
  useGetAllLocationsQuery,
  useGetAllVisasQuery,
} from '../../apis/locationsApi/locationsApi';

const LABEL_CLASS = 'block mb-2 text-sm font-medium text-gray-900';
const INPUT_CLASS =
  'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const getActivityPrice = (activity, transport, noa, noc) => {
  // console.log('transport: ', transport)
  if (['WOT', 'PT', 'ST'].includes(transport)) {
    let cost;
    if (transport === 'WOT') {
      cost =
        activity.price[transport]['AdultCost'] * noa +
        activity.price[transport]['ChildCost'] * noc;
      return cost;
    } else if (transport === 'ST') {
      cost =
        (activity.price[transport]['AdultCost'] +
          activity.price['WOT']['AdultCost']) *
          noa +
        (activity.price[transport]['ChildCost'] +
          activity.price['WOT']['ChildCost']) *
          noc;
      return cost;
    } else {
      let totalPrice = 0;
      let zerosArray = Array.from({
        length: activity.price.PT.length,
      }).fill(0);
      let maxPassengersArray = activity.price.PT.map((obj) => [
        obj.maxPassengers,
        obj.price,
      ]);
      maxPassengersArray.sort((x, y) => x[0] - y[0]);
      // console.log('max pas arr: ', maxPassengersArray)
      let totalPassengers = noa + noc;
      while (totalPassengers > 0) {
        let i = 0,
          flag = 0;
        while (i < maxPassengersArray.length) {
          if (parseInt(maxPassengersArray[i][0]) > totalPassengers) {
            zerosArray[i] = zerosArray[i] + 1;
            flag = 1;
            break;
          } else if (i == maxPassengersArray.length - 1) {
            zerosArray[i] = zerosArray[i] + 1;
            break;
          }
          i = i + 1;
        }
        if (flag) {
          totalPassengers = -1;
        } else {
          totalPassengers =
            totalPassengers -
            parseInt(maxPassengersArray[maxPassengersArray.length - 1][0]);
        }
      }
      for (let i = 0; i < maxPassengersArray.length; i++) {
        totalPrice =
          totalPrice + zerosArray[i] * parseInt(maxPassengersArray[i][1]);
      }
      totalPrice =
        totalPrice +
        activity.price['WOT']['AdultCost'] * noa +
        activity.price['WOT']['ChildCost'] * noc;
      // console.log('total price: ', totalPrice)
      return totalPrice;
    }
  }
  return 0;
};

function addDaysToDate(inputDate, numDays) {
  // Splitting the input date string into year, month, and day components
  var parts = inputDate.split('-');
  var year = parseInt(parts[0]);
  var month = parseInt(parts[1]) - 1; // Adjusting month to zero-based index
  var day = parseInt(parts[2]);

  // Creating a Date object with the provided date
  var date = new Date(year, month, day);

  // Adding the specified number of days
  date.setDate(date.getDate() + numDays);

  // Formatting the resulting date as 'yyyy-MM-dd'
  var result =
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2);
  return result;
}

const Itinerary = ({ data }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [markupAmount, setMarkupAmount] = useState(0); // State for markup amount

  const [createBooking] = useCreateBookingMutation();
  const [sendBooking] = useSendBookingMutation();

  const currentUser = useSelector(selectCurrentUser);
  const currencies = useSelector(getAllCurrencies);

  const { data: allVisas } = useGetAllVisasQuery();
  const { data: allLocations } = useGetAllLocationsQuery({ keyword: '' });

  const navigate = useNavigate();

  const handleRedirect = () => {
    const viewUrl = '/dashboard/bookings';
    navigate(viewUrl);
  };

  const submitHandler = async (values) => {
    const number_of_adults = parseInt(values.number_of_adults);
    const number_of_children = parseInt(values.number_of_children);
    const number_of_infants = parseInt(values.number_of_infants);

    const tour_end_date = addDaysToDate(
      values.tour_start_date,
      data.days.length - 1,
    );

    const bookingDetails = {
      bookingName: values.bookingName,
      itineraryName: data.itineraryName,
      total_amount: 0.0,
      itinerary_id: data._id,
      number_of_adults,
      number_of_children,
      number_of_infants,
      duration: data.days.length,
      tour_start_date: values.tour_start_date,
      tour_end_date,
      agent_id: currentUser.userId,
      total_adult_amount: 0.0,
      total_child_amount: 0.0,
      total_infant_amount: 0.0,
      markup_amount: markupAmount,
    };

    let totalAmount = 0.0;
    let adultAmount = 0.0;
    let childAmount = 0.0;
    let infantAmount = 0.0;
    let totalCount = parseInt(
      parseInt(number_of_adults) +
        parseInt(number_of_children) +
        parseInt(number_of_infants),
    );

    data.days.forEach((day) => {
      day.forEach((d) => {
        let totalPrice = getActivityPrice(
          d,
          d.transportType,
          number_of_adults,
          number_of_children,
        );
        totalAmount = totalAmount + totalPrice;
        if (d.transportType === 'PT') {
          const perPersonCost = totalPrice / totalCount;
          const adultPrice = perPersonCost * number_of_adults;
          const childPrice = perPersonCost * number_of_children;
          const infantPrice = perPersonCost * number_of_infants;

          adultAmount = adultAmount + adultPrice;
          childAmount = childAmount + childPrice;
          infantAmount = infantAmount + infantPrice;
        } else {
          const adultPrice = getActivityPrice(
            d,
            d.transportType,
            number_of_adults,
            0,
          );
          const childPrice = getActivityPrice(
            d,
            d.transportType,
            0,
            number_of_children,
          );
          const infantPrice = 0.0;

          adultAmount = adultAmount + adultPrice;
          childAmount = childAmount + childPrice;
          infantAmount = infantAmount + infantPrice;
        }
      });
    });

    totalAmount += markupAmount;

    bookingDetails.total_amount = totalAmount;
    bookingDetails.total_adult_amount = adultAmount;
    bookingDetails.total_child_amount = childAmount;
    bookingDetails.total_infant_amount = infantAmount;

    await createBooking(bookingDetails)
      .then(async (res) => {
        const location = allLocations.data.find(
          (x) => x.locationName === data.location,
        );
        await sendBooking({
          pdfContent: {
            ...res.data,
            itineraryInfo: data,
            currency: currencies[data.country][0].code,
            document_required: location.document_required,
            important_note: location.important_note,
            terms_n_conditions: location.terms_n_conditions,
            exclusion: location.exclusion,
            ...(data.visaStatus === true && {
              visa: allVisas.data.find((x) => x.country === data.country),
            }),
          },
          Id: currentUser.userId,
        })
          .unwrap()
          .then((res) => {
            toast.success(`Sent Mail Successfully`, TOAST_CONFIG);
            setTimeout(() => {
              handleRedirect();
            }, 2000);
          });
      })
      .catch((err) => {
        console.log('Booking Creation failed: ', err);
      });
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      number_of_adults: 0,
      number_of_children: 0,
      number_of_infants: 0,
    },
  });

  return (
    <>
      <ModuleHeader
        headerTitle={data.itineraryName}
        headerImg={data.images[0]}
      />
      <Grid container spacing={3} className="mb-3">
        <Grid item xs={12} md={8}>
          {data.description && (
            <>
              <p className="text-3xl">Description</p>
              <hr />
              <p>{`${data.description && data.description}`}</p>
            </>
          )}
          <Typography>
            Location: &nbsp;
            <LocationOnIcon></LocationOnIcon>
            {data.location} - {data.country}
          </Typography>
          <Typography>
            <p className="font-semibold">
              {' '}
              Visa: {data.visaStatus ? 'Included' : 'Not Included'}
            </p>
          </Typography>
          {data.custom === false && (
            <>
              <div className="grid grid-cols-3 gap-4">
                {/* Adults Table */}
                <div>
                  <h3 className="font-semibold">
                    No of Adults: {data.AdultCount}
                  </h3>
                  {/* <table className="text-sm text-left text-gray-500 rtl:text-right dark:text-gray-700">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Day
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Adult Cost
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.days.map((day, index) => (
                        <tr
                          className="bg-white border-b dark:border-gray-700"
                          key={index}
                        >
                          <td>Day {index + 1}</td>
                          {day.map((item, idx) => (
                            <td key={idx}>AdultCost: {item.AdultCost}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>

                {/* Children Table */}
                <div>
                  <h3 className="font-semibold">
                    No of Children: {data.ChildCount}
                  </h3>
                  {/* <table className="text-sm text-left text-gray-500 rtl:text-right dark:text-gray-700">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Day
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Children Cost
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.days.map((day, index) => (
                        <tr
                          className="bg-white border-b dark:border-gray-700"
                          key={index}
                        >
                          <td>Day {index + 1}</td>
                          {day.map((item, idx) => (
                            <td key={idx}>ChildrenCost: {item.ChildCost}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>

                {/* Infants Table */}
                <div>
                  <h3 className="font-semibold">
                    No of Infants: {data.InfantCount}
                  </h3>
                  {/* <table className="text-sm text-left text-gray-500 rtl:text-right dark:text-gray-700">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Day
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Infant Cost
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.days.map((day, index) => (
                        <tr
                          className="bg-white border-b dark:border-gray-700"
                          key={index}
                        >
                          <td>Day {index + 1}</td>
                          {day.map((item, idx) => (
                            <td key={idx}>InfantCost: {item.InfantCost}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
              </div>
            </>
          )}
        </Grid>
      </Grid>

      <div className="flex items-center justify-between py-2 align-middle">
        <p className="p-0 m-0 text-3xl font-semibold">Details</p>
        <div className="flex gap-2">
          {data.custom === false && (
            <Button variant="contained" onClick={() => setOpenDialog(true)}>
              Book This Itinerary
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => navigate(`../customize/${data._id.toString()}`)}
          >
            Customize
          </Button>
        </div>
      </div>
      <Divider />
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {data.days.map((day, index) => (
          <React.Fragment key={index}>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box>
                  <Typography variant="h5" fontWeight={700}>
                    Day {index + 1}
                  </Typography>
                </Box>
                {day.map((item, idx) => (
                  <ActivityTimeline activityData={item} day={index} key={idx} />
                ))}
              </TimelineContent>
            </TimelineItem>
          </React.Fragment>
        ))}
      </Timeline>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <div className="flex items-center justify-between p-4 border-b rounded-t sm:mb-5">
          <h3 className="text-lg font-semibold text-gray-900">
            Book This Itinerary
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="defaultModal"
            onClick={() => setOpenDialog(false)}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <DialogContent>
          <div className="font-bold text-right">
            {/* <div> Total Adult - {data.AdultCount}</div>
            <div> Total Children - {data.ChildCount}</div>
            <div> Total Infant - {data.InfantCount}</div> */}
            <div> Total Amount - {data.totalAmount + markupAmount}</div>
          </div>
          <form
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'column',
            }}
            onSubmit={handleSubmit(submitHandler)}
          >
            <div className="grid gap-4 mb-4 sm:grid-cols-2">
              <label htmlFor="Number of adults" className={'col-span-full'}>
                Booking Name:
                <input
                  type="text"
                  {...register('bookingName')}
                  className={INPUT_CLASS}
                />
              </label>
              <label htmlFor="Number of adults" className={LABEL_CLASS}>
                Enter Number of Adults:
                <input
                  type="number"
                  className={INPUT_CLASS}
                  {...register('number_of_adults')}
                />
              </label>
              <label htmlFor="Number of children" className={LABEL_CLASS}>
                Enter Number of Children:
                <input
                  type="number"
                  {...register('number_of_children')}
                  className={INPUT_CLASS}
                />
              </label>
              <label htmlFor="Number of infants" className={LABEL_CLASS}>
                Enter Number of Infants:
                <input
                  type="number"
                  {...register('number_of_infants')}
                  className={INPUT_CLASS}
                />
              </label>
              <label className={LABEL_CLASS}>
                Enter Starting Date of Tour:
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Controller
                    control={control}
                    name="tour_start_date"
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          format="MM-DD-YYYY"
                          inputRef={field.ref}
                          onChange={(date) => {
                            field.onChange(date.format('YYYY-MM-DD'));
                          }}
                          onBlur={(date) => {
                            field.onBlur(date.format('YYYY-MM-DD'));
                          }}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </label>
              <label htmlFor="Number of infants" className={LABEL_CLASS}>
                Add Markup Amount:
                <input
                  type="number"
                  {...register('markup_amount')}
                  className={INPUT_CLASS}
                  onChange={(e) => setMarkupAmount(parseFloat(e.target.value))}
                />
              </label>
            </div>
            <Button variant="contained" type="submit">
              Book
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Itinerary;
