import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import BaliImg from "../../assets/dummy/bali.jpg";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Paper,
} from '@mui/material';

const FeaturedItineraries = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    const viewUrl = '/dashboard/itineraries/create';
    navigate(viewUrl);
  };
  const handleRedirectViewPage = () => {
    const viewUrlLink = '/dashboard/itineraries';
    navigate(viewUrlLink);
  };

  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(https://ik.imagekit.io/freelance/bali.jpg?updatedAt=1720282973237)`,
          borderRadius: '12px',
          height: '23rem',
        }}
      >
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: 'none' }}
            src="https://ik.imagekit.io/freelance/bali.jpg?updatedAt=1720282973237"
            alt={'header'}
            height={'23rem'}
          />
        }
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
            borderRadius: '12px',
            height: '23rem',
          }}
        />
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
              }}
            >
              <Typography
                component="h1"
                variant="h3"
                color="inherit"
                gutterBottom
              >
                {'Enjoy your vacation make your own custom Itineray'}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handleRedirect}
                >
                  Create Itinerary
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handleRedirectViewPage}
                >
                  View All Itinerary
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default FeaturedItineraries;
