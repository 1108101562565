import React, { useState } from 'react';
import FeaturedItineraries from '../../components/featuredItineraries/FeaturedItineraries';
import Box from '@mui/material/Box';
import HotPackage from '../../components/HotPackages/HotPackage';
import { Button, Grid } from '@mui/material';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetAllAdminItinerariesQuery } from '../../apis/itineraryApis/itineraryApi';
import { useGetAllLocationWiseCountryQuery } from '../../apis/locationsApi/locationsApi';
import CustomSelect from '../../components/customSelect/CustomSelect';
import { Field, Formik, Form } from 'formik';
import HotDealList from '../../components/HotDealsList/HotDealList';

const Home = () => {
  const INITIAL_VALUES = {
    location: '',
    country: '',
  };

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Fetch filtered itineraries based on searchParams
  const { data: itinerariesData } = useGetAllAdminItinerariesQuery(
    {
      keyword: searchParams.get('keyword') || '',
      location: searchParams.get('location') || '',
      country: searchParams.get('country') || '',
      page: 0,
      limit: 100,
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: locationsResponse } = useGetAllLocationWiseCountryQuery();
  const locationsData = locationsResponse?.data || [];

  const locations = locationsData.map(location => ({
    name: location.locationName,
    value: location.locationName,
  }));

  const countries = locationsData.reduce((acc, location) => {
    location.countries.forEach(country => {
      if (!acc.some(item => item.value === country)) {
        acc.push({ name: country, value: country });
      }
    });
    return acc;
  }, []);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleSubmit = (values) => {
    navigate({
      pathname: `/itineraries`,
      search: `?${createSearchParams(values)}`,
    });
  };

  const validateForm = (values) => {
    setIsButtonEnabled(values.location !== '' && values.country !== '');
  };

  return (
    <>
      <FeaturedItineraries />
      <Box
        sx={{
          background: "#f2f2f2",
          padding: "2rem",
          borderRadius: "12px",
          alignItems: "center",
        }}
      >
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          {({ isValid, values, handleChange }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item sm={12} md={3}>
                  <Field
                    className="custom-select"
                    name="country"
                    fieldValue="value"
                    fieldName="name"
                    component={CustomSelect}
                    placeholder="Select Country"
                    options={countries}
                    isMulti={false}
                  />
                </Grid>
                <Grid item sm={12} md={3}>
                  <Field
                    className="custom-select"
                    name="location"
                    fieldValue="value"
                    fieldName="name"
                    component={CustomSelect}
                    placeholder="Select Location"
                    options={locations}
                    isMulti={false}
                  />
                </Grid>
                <Grid item sm={12} md={3}>
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    disabled={!isButtonEnabled}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>

      <Box sx={{ paddingY: 2 }}>
        <HotPackage />
      </Box>
      <Grid container spacing={2}>
        {itinerariesData &&
          itinerariesData.data.length > 0 &&
          itinerariesData.data.map((eachItem) => (
            <Grid item md={3} sm={12} key={eachItem._id}>
              <HotDealList data={eachItem} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default Home;
