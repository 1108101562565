import React from 'react';
import { Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';
import ModuleHeader from '../moduleHeader/ModuleHeader';

const Location = ({ data }) => {
  return (
    <>
      <ModuleHeader
        headerTitle={data.itineraryName}
        headerImg={data.images[0]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Country: {data.country}
              </Typography>
              <Typography variant="h5" gutterBottom>
                Location: {data.locationName}
              </Typography>
              <Typography variant="h5" gutterBottom>
                Status: {data.status === 0 ? 'Active' : 'Inactive'}
              </Typography>
              <Typography variant="h5" gutterBottom>
                Currency Name: {data.currencyName}
              </Typography>
              <Typography variant="h5" gutterBottom>
                Currency Code: {data.currencyCode}
              </Typography>
              <Typography variant="h5" gutterBottom>
                  Exclusion: 
              </Typography>
              <ul>
                {data.exclusion.map((item, index) => (
                  <React.Fragment key={index}>
                     {item.length > 0 && <li>{item}</li>}
                  </React.Fragment>
                ))}
              </ul>
              <Typography variant="h5" gutterBottom>
                  Document Required: 
              </Typography>
              <ul>
                {data.document_required.map((i, index) => (
                  <React.Fragment key={index}>
                     {i.length > 0 && <li>{i}</li>}
                  </React.Fragment>
                ))}
              </ul>
              <Typography variant="h5" gutterBottom>
                  Terms and Conditions: 
              </Typography>
              <ul>
                {data.terms_n_conditions.map((t, index) => (
                  <React.Fragment key={index}>
                     {t.length > 0 && <li>{t}</li>}
                  </React.Fragment>
                ))}
              </ul>
              <Typography variant="h5" gutterBottom>
                  Importent Notes: 
              </Typography>
              <ul>
                {data.important_note.map((n, index) => (
                  <React.Fragment key={index}>
                     {n.length > 0 && <li>{n}</li>}
                  </React.Fragment>
                ))}
              </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Location;
