import React from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetUserByIdQuery } from '../../../../apis/userApis/userApi';
import User from '../../../../components/user/User';

const ViewUser = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetUserByIdQuery(id);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }
  if (isError) {
    return <Typography>Error fetching User</Typography>;
  }
  return <>{data && <User data={data || []} />}</>;
};

export default ViewUser;
