import { apiSlice } from '../../app/api/baseApi';

export const locationApi = apiSlice.injectEndpoints({
  tagType: ['Location', 'Visa'],
  endpoints: (builder) => ({
    createLocation: builder.mutation({
      query: (body) => ({
        url: '/location',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Location'],
    }),
    //to get location by ID
    getLocationById: builder.query({
      query: (locationId) => `/location/${locationId}`,
      providesTags: (result, error, locationId) => [
        {
          type: 'Location',
          id: `${locationId}`,
        },
      ],
    }),
    //to get all location
    getAllLocations: builder.query({
      query: (keyword) => `location/${keyword.keyword || ''}`,
      providesTags: (result, error, locationId) => [
        {
          type: 'Location',
          id: `${locationId}`,
        },
      ],
    }),
    getAllCountries: builder.query({
      query: () => `countries`,
      providesTags: (result, error, locationId) => [
        {
          type: 'Location',
          id: `${locationId}`,
        },
      ],
    }),
    // get location wise country
    getAllLocationWiseCountry: builder.query({
      query: () => `country-location`,
      providesTags: (result, error, locationId) => [
        { type: 'country-location', id: `${locationId}` },
      ],
    }),
    // to delete location by id
    deleteLocation: builder.mutation({
      query: (locationId) => ({
        url: `/location/${locationId}`,
        method: 'DELETE',
      }),
    }),
    // to get an update Location by id
    updateLocation: builder.mutation({
      query: ({ locationId, body }) => ({
        url: `/location/${locationId}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: ['Location'],
    }),
    // to create visa
    createVisa: builder.mutation({
      query: (body) => ({
        url: '/visa',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Visa'],
    }),
    //to get all visa
    getAllVisas: builder.query({
      query: () => `visa`,
      providesTags: ['Visa'],
    }),
    // to get an update Visa By id
    updateVisa: builder.mutation({
      query: ({ visaId, body }) => ({
        url: `/visa/${visaId}`,
        method: 'PUT',
        body: { ...body },
      }),
      invalidatesTags: ['Visa'],
    }),
    //to get visa by ID
    getVisaById: builder.query({
      query: (visaId) => `/visa/${visaId}`,
      providesTags: (result, error, visaId) => [
        {
          type: 'Visa',
          id: `${visaId}`,
        },
      ],
    }),
  }),
});

export const {
  useCreateLocationMutation,
  useGetLocationByIdQuery,
  useGetAllLocationsQuery,
  useGetAllVisasQuery,
  useGetAllCountriesQuery,
  useGetAllLocationWiseCountryQuery,
  useDeleteLocationMutation,
  useUpdateLocationMutation,
  useCreateVisaMutation,
  useUpdateVisaMutation,
  useGetVisaByIdQuery,
} = locationApi;
